import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldTextInput, FieldRadioButton } from '../../components';
import css from './EditListingPricingForm.module.css';
import PriceByPerson from './PriceByPerson';
import PricePerUnit from './PricePerUnit';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => {
  const [openPriceSettings, setOpenPriceSettings] = useState(false);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          publicData,
          pricingModelTypeSelected,
          form,
        } = formRenderProps;
        const handleOpenPriceSettings = () => setOpenPriceSettings(true);
        const isDay = publicData.mainCategory !== 'sleep';

        const seatsLabel = intl.formatMessage({
          id: 'EditListingPricingForm.seatsLabel',
        });
        const seatsLabelDesc = intl.formatMessage({
          id: 'EditListingPricingForm.seatsLabelDesc',
        });
        const unitsLabelDesc = intl.formatMessage({
          id: 'EditListingPricingForm.unitsLabelDesc',
        });
        const unitsLabel = intl.formatMessage({
          id: 'EditListingPricingForm.unitsLabel',
        });

        const obligatoryText = (
          <span className={css.greenText}>
            <FormattedMessage id="EditListingPricingForm.obligatory" />
          </span>
        );
        const optionalText = (
          <span className={css.grayText}>
            <FormattedMessage id="EditListingPricingForm.optional" />
          </span>
        );
        const day = intl.formatMessage({
          id: 'EditListingPricingForm.day',
        });
        const night = intl.formatMessage({
          id: 'EditListingPricingForm.night',
        });

        const minStayOfItemLabel = intl.formatMessage(
          { id: 'EditListingPricingForm.minStayOfItemLabel' },
          { obligatoryText, unitType: isDay ? day : night }
        );
        const maxStayOrUse = intl.formatMessage(
          { id: 'EditListingPricingForm.maxStayOrUse' },
          { optionalText }
        );

        const requiredMessage = intl.formatMessage({
          id: 'EditListingPricingForm.required',
        });
        const validValueMessage = intl.formatMessage({
          id: 'EditListingPricingForm.validValue',
        });
        const validMaxValueMessage = intl.formatMessage(
          {
            id: 'EditListingPricingForm.maxValidation',
          },
          { maxLimit: config.guestsMaxLimit }
        );
        const setThePriceLabel = intl.formatMessage(
          {
            id: 'EditListingPricingForm.setThePriceLabel',
          },
          {
            btn: (
              <span className={css.setThePriceLabel} onClick={handleOpenPriceSettings}>
                <FormattedMessage id="EditListingPricingForm.setPriceBtn" />
              </span>
            ),
          }
        );
        const priceOptionsTitle = intl.formatMessage({
          id: 'EditListingPricingForm.priceOptionsTitle',
        });
        const priceOptionsDesc = intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceOptionsDesc',
          },
          {
            byPerson: (
              <span className={css.bold}>
                <FormattedMessage id="EditListingPricingForm.byPerson" />
              </span>
            ),
            perUnit: (
              <span className={css.bold}>
                <FormattedMessage id="EditListingPricingForm.perUnit" />
              </span>
            ),
          }
        );
        const byPersonMessage = intl.formatMessage({
          id: 'EditListingPricingForm.byPerson',
        });
        const perUnitMessage = intl.formatMessage({
          id: 'EditListingPricingForm.perUnit',
        });

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );

        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const maxStayOrUseInvalid =
          !publicData?.optionsPrice?.minStayOrUse && +values?.minStayOrUse > +values?.maxStayOrUse;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid ||
          disabled ||
          submitInProgress ||
          maxStayOrUseInvalid ||
          (!values?.priceWeekdayByPerson &&
            !values?.priceWeekendByPerson &&
            !values?.priceWeekdayPerUnit &&
            !values?.priceWeekendPerUnit) ||
          (values?.pricingModelType === 'perPerson' && !values?.priceWeekdayByPerson) ||
          (values?.pricingModelType === 'perUnit' && !values?.priceWeekdayPerUnit);

        const { updateListingError, showListingsError } = fetchErrors || {};

        const clearFieldValues = () => {
          // form.change('priceWeekdayByPerson', undefined);
          // form.change('priceWeekendByPerson', undefined);
          // form.change('priceWeekdayPerUnit', undefined);
          // form.change('priceWeekendPerUnit', undefined);

          form.change('discountKids', undefined);
          form.change('discountGroup', undefined);
          form.change('discountGroupPeople', undefined);
          form.change('discountDuration', undefined);
          form.change('discountDurationDays', undefined);
          form.change('discountMultiple', undefined);
          form.change('discountMultipleItems', undefined);
          form.change('discountMultiplePerUnit', undefined);
        };

        const formatInputValue = value => (!!value ? value.replace(/[^\d.]/g, '') : '');

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}

            <div className={css.itemsRow}>
              <div className={css.itemsCol}>
                <div className={css.itemsRowDesc}>
                  <span className={css.itemDesc}>{seatsLabelDesc}</span>
                </div>
                <FieldTextInput
                  format={formatInputValue}
                  parse={formatInputValue}
                  id="numberOfGuests"
                  name="numberOfGuests"
                  className={css.item}
                  type="text"
                  label={seatsLabel}
                  max={20}
                  validate={validators.composeValidators(
                    validators.required(requiredMessage),
                    validators.onlyNumbers(validValueMessage),
                    validators.positiveNumberValues(validValueMessage),
                    validators.maxValue(validMaxValueMessage, config.guestsMaxLimit)
                  )}
                />
              </div>
              <div className={css.itemsCol}>
                <div className={css.itemsRowDesc}>
                  <span className={css.itemDesc}>{unitsLabelDesc}</span>
                </div>
                <FieldTextInput
                  format={formatInputValue}
                  parse={formatInputValue}
                  id="units"
                  name="units"
                  className={css.item}
                  type="text"
                  label={unitsLabel}
                  validate={validators.composeValidators(
                    validators.required(requiredMessage),
                    validators.onlyNumbers(validValueMessage),
                    validators.positiveNumberValues(validValueMessage)
                  )}
                />

              </div>

            </div>
            <div
              className={classNames(
                css.itemsRow,
                { [css.openPriceSettings]: openPriceSettings },
                { [css.closePriceSettings]: !openPriceSettings }
              )}
            >
              <div className={classNames(css.item, css.itemWithLabel)}>
                <FieldTextInput
                  format={formatInputValue}
                  parse={formatInputValue}
                  id="minStayOrUse"
                  name="minStayOrUse"
                  className={css.itemSmall}
                  type="text"
                  label={minStayOfItemLabel}
                  validate={validators.composeValidators(
                    validators.required(requiredMessage),
                    validators.onlyNumbers(validValueMessage),
                    validators.positiveNumberValues(validValueMessage)
                  )}
                />
                <span className={css.daysLabel}>
                  <FormattedMessage id={`EditListingPricingForm.${isDay ? 'days' : 'nights'}`} />
                </span>
              </div>
              <div className={classNames(css.item, css.itemWithLabel)}>
                <FieldTextInput
                  format={formatInputValue}
                  parse={formatInputValue}
                  id="maxStayOrUse"
                  name="maxStayOrUse"
                  className={css.itemSmall}
                  type="text"
                  label={maxStayOrUse}
                  min={+values?.minStayOrUse || 1}
                />
                <span className={css.daysLabel}>
                  <FormattedMessage id={`EditListingPricingForm.${isDay ? 'days' : 'nights'}`} />
                </span>
              </div>
            </div>

            {maxStayOrUseInvalid && (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.minMaxValidation" />
              </p>
            )}

            {!openPriceSettings ? (
              <h4 className={css.settingsPricingTitle}>{setThePriceLabel}</h4>
            ) : null}
            {openPriceSettings ? (
              <>
                <h4 className={css.settingsPricingTitle}>{priceOptionsTitle}</h4>
                <p className={css.priceOptionsDesc}>{priceOptionsDesc}</p>
                <div className={css.pricingSwither}>
                  <FieldRadioButton
                    className={classNames(css.pricingSwitherItem, {
                      [css.pricingSwitherItemBlocked]: pricingModelTypeSelected,
                    })}
                    id="perPerson"
                    name="pricingModelType"
                    label={byPersonMessage}
                    value="perPerson"
                    onClick={clearFieldValues}
                  />
                  <FieldRadioButton
                    className={classNames(css.pricingSwitherItem, {
                      [css.pricingSwitherItemBlocked]: pricingModelTypeSelected,
                    })}
                    id="perUnit"
                    name="pricingModelType"
                    label={perUnitMessage}
                    value="perUnit"
                    onClick={clearFieldValues}
                  />
                </div>
                <div className={css.pricingModels}>
                  <PriceByPerson
                    intl={intl}
                    pricingModelType={values?.pricingModelType}
                    pricingModelTypeSelected={pricingModelTypeSelected}
                    priceValidators={priceValidators}
                    isDay={isDay}
                  />
                  <div className={css.pricingModelsSeparator} />
                  <PricePerUnit
                    intl={intl}
                    pricingModelType={values?.pricingModelType}
                    pricingModelTypeSelected={pricingModelTypeSelected}
                    priceValidators={priceValidators}
                    isDay={isDay}
                  />
                </div>
              </>
            ) : null}
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
