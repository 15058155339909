import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { getDistance } from '../../util/maps';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, IconReviewStar, AddFavorites } from '../../components';
// import Slider from "react-slick";
import { languagesHelper as language } from '../../helpers/languages';
import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    currentBrowserGeolocation,
    deleteFromFavorites,
    showDeleteIcon,
    currentUser,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  // const author = ensureUser(listing.author);
  // const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const optionLabel = (options, key, intl) => {
    const cat = options.find(c => c.key === key);
    return cat ? language.labelsTranslator(cat.key, intl) : key;
  };

  const { typesOptions } = config.custom;
  const typeSelected = publicData?.type;
  const categorySelected = publicData?.mainCategory;

  const avgStarRating = publicData?.avgStarRating;
  const totalReviews = publicData?.totalReviews;

  // const unitType = config.bookingUnitType;
  const unitType =
    publicData.mainCategory === 'sleep' ? config.bookingUnitTypeNight : config.bookingUnitTypeDay;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  // const sliderSettings = {
  //   dots: false,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: false,
  //   speed: 500,
  //   nextArrow: <IconSliderArrow direction="next" />,
  //   prevArrow: <IconSliderArrow />,

  // responsive: [
  //   {
  //     breakpoint: 1280,
  //     settings: {
  //       slidesToShow: listingsForSlider?.length < 3 ? 1 : 2,
  //       slidesToScroll: 1,
  //     }
  //   },
  //   {
  //     breakpoint: 600,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //     }
  //   },
  //   {
  //     breakpoint: 480,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1
  //     }
  //   }
  // ]
  // };


  const { CAT_RENT, CAT_ACTIVITIES } = config.custom;

  const categoryClasses = classNames(
    css.category,
    { [css.categoryRent]: categorySelected === CAT_RENT },
    { [css.categoryActivities]: categorySelected === CAT_ACTIVITIES }
  );

  const distanceFromTo =
    currentBrowserGeolocation &&
    currentListing?.attributes?.geolocation &&
    getDistance(currentListing?.attributes?.geolocation, currentBrowserGeolocation);

  return (
    <div
      className={classes}
      onMouseEnter={() => setActiveListing(currentListing.id)}
      onMouseLeave={() => setActiveListing(null)}
    >
      {!!currentUser && (
        <AddFavorites
          listing={currentListing}
          className={css.addToFavorites}
          deleteFromFavorites={deleteFromFavorites}
          showDeleteIcon={showDeleteIcon}
        />
      )}

      <NamedLink className={css.rooLink} name="ListingPage" params={{ id, slug }}>

        <div className={css.sliderWrapper}>
          <div className={css.sliderImage}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
            />
          </div>
        </div>
        <div className={css.info}>
          {avgStarRating && totalReviews && (
            <div className={css.ratingHolder}>
              <IconReviewStar className={css.ratingStar} isFilled />
              <span>{avgStarRating}</span>
              <span className={css.ratingTotal}>({totalReviews})</span>
            </div>
          )}
          <div className={css.titleHolder}>
            {!!categorySelected && (
              <div className={categoryClasses}>
                {/* {optionLabel(categoriesAllList, categorySelected)} */}
                {categorySelected}
              </div>
            )}
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
          </div>

          <div className={css.additionalInfo}>
            <div className={css.additionalInfoItem}>
              {!!typeSelected && (
                <>
                  <span className={css.additionalInfoLabel}>
                    <FormattedMessage id="ListingCard.type" />
                  </span>
                  {optionLabel(typesOptions, typeSelected, intl)}
                </>
              )}
            </div>

            {/* <div className={css.discountHolder}>
              <IconDiscount />
            </div> */}
          </div>

          <div className={css.bottomInfo}>
            <div className={css.bottomInfoItem}>
              {!!distanceFromTo && !isNaN(+distanceFromTo) ? (
                <div className={css.description}>
                  <FormattedMessage
                    id="ListingCard.fromSearchingLocation"
                    values={{ distance: distanceFromTo }}
                  />
                </div>
              ) : (
                <div className={css.description}></div>
              )}
              <div className={css.amenities}>
                {/* {!!listingAmenities?.length && listingAmenities.slice(0, 2).map(amenities => {
                  return <IconAmenities iconKey={amenities} key={amenities} />
                })} */}
              </div>
            </div>

            <div className={classNames(css.priceHolder, css.bottomInfoItem)}>
              <div className={css.price}>
                <div className={css.priceValue} title={priceTitle}>
                  {formattedPrice}
                </div>
                <div className={css.perUnit}>
                  <FormattedMessage id={unitTranslationKey} />
                </div>
              </div>
              <div className={css.bookBtn} name="ListingPage" params={{ id, slug }}>
                <FormattedMessage id="ListingCard.bookNow" />
              </div>
            </div>
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
