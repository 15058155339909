import React from "react";
import classNames from 'classnames';

import { FieldCurrencyInput } from '../../components';

import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import DiscountFields from './DiscountFields';

import css from './EditListingPricingForm.module.css';

const PricePerUnit = ({
  intl,
  pricingModelType,
  pricingModelTypeSelected,
  priceValidators,
  isDay,
}) => {
  const weekdayLabel = intl.formatMessage({
    id: 'EditListingPricingForm.weekdayLabel',
  });
  const weekendLabel = intl.formatMessage({
    id: 'EditListingPricingForm.weekendLabel',
  });
  const enterNumberPlaceholder = intl.formatMessage({
    id: 'EditListingPricingForm.enterNumber',
  });
  const isObligatory = intl.formatMessage({
    id: 'EditListingPricingForm.isObligatory',
  });
  const discountLabel = intl.formatMessage({
    id: 'EditListingPricingForm.discountLabel',
  }, { isObligatory: <span className={css.isObligatory}>{isObligatory}</span> });
  const pricingSubTitle = intl.formatMessage({
    id: 'EditListingPricingForm.pricingSubTitle',
  });
  const perDay = intl.formatMessage({
    id: 'EditListingPricingForm.perDay',
  });
  const perNight = intl.formatMessage({
    id: 'EditListingPricingForm.perNight',
  });

  return (
    <div className={classNames(
      css.pricingModelsItem,
      { [css.pricingModelsItemActive]: pricingModelType === "perUnit" },
      { [css.pricingModelsItemBlocked]: pricingModelType !== "perUnit" && pricingModelTypeSelected },
    )}>
      <h5 className={css.pricingTitle}>
        <FormattedMessage
          id="EditListingPricingForm.pricePerUnit"
          values={{unitType: isDay ? perDay : perNight}}
        />
      </h5>
      <div className={css.weekdaysHolder}>
        <FieldCurrencyInput
          id="priceWeekdayPerUnit"
          name="priceWeekdayPerUnit"
          className={css.weekdaysField}
          label={weekdayLabel}
          placeholder={enterNumberPlaceholder}
          currencyConfig={config.currencyConfig}
          validate={pricingModelType === "perUnit" && priceValidators}
        />
        <FieldCurrencyInput
          id="priceWeekendPerUnit"
          name="priceWeekendPerUnit"
          className={css.weekdaysField}
          label={weekendLabel}
          placeholder={enterNumberPlaceholder}
          currencyConfig={config.currencyConfig}
          validate={pricingModelType === "perUnit" && priceValidators}
        />
      </div>
      <h5 className={css.perUnitDiscountTitle}>{discountLabel}</h5>
      <p className={css.perUnitPricingSubTitle}>{pricingSubTitle}</p>
      <DiscountFields intl={intl} pricingModelType={pricingModelType} isDay={isDay} />
    </div>
  );
}

export default PricePerUnit;
