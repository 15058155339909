import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      {/* <p className={css.lastUpdated}>Last updated: October 30, 2017</p> */}
      <h2>
        Postanowienia ogólne
      </h2>
      <p>
        Niniejszy Regulamin określa zasady świadczenia usług w portalu internetowym <a href="www.wacampa.com" target='_blank'>www.wacampa.com</a>
      </p>
      <p>
        Użytkownik przed rozpoczęciem korzystania z Portalu zobowiązany jest do zapoznania
        się z postanowieniami Regulaminu. Rozpoczęcie korzystania z Usług niewymagających
        zarejestrowania jest równoznaczne z pełną akceptacją warunków Regulaminu.
        W przypadku Usług wymagających zarejestrowania, dokonanie rejestracji w
        Portalu jest równoznaczne z pełną akceptacją warunków Regulaminu.
      </p>
      <p>
        Operator nieodpłatnie udostępnia Użytkownikom Regulamin w systemie teleinformatycznym.
      </p>
      <p>
        Od chwili rozpoczęcia korzystania z Usług, każdy z Użytkowników zobowiązany
        jest do przestrzegania postanowień Regulaminu.
      </p>

      <h2>
        Definicje
      </h2>
      <p>
        <b>Klient</b> - osoba fizyczna posiadająca co najmniej ograniczoną zdolność do czynności prawnych,
        osoba prawna, jednostka organizacyjna nieposiadająca osobowości prawnej, której
        ustawa przyznaje zdolność prawną, która korzysta za pośrednictwem Portalu z Usług
        świadczonych przez Usługodawcę, na podstawie postanowień Regulaminu.
      </p>
      <p>
        <b>Operator</b> - Wacampa spółka z ograniczoną odpowiedzialnością z siedzibą w Trzebnicy,
        przy ulicy Piastowskiej 12, 55-100 Trzebnica, wpisana do rejestru przedsiębiorców
        Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Wrocławia Fabrycznej
        we Wrocławiu, IX Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS
        0000970355, NIP 9151821768, REGON 52195778900000.
      </p>
      <p>
        <b>Regulamin</b> - niniejszy regulamin.
      </p>
      <p>
        <b>Rezerwacja</b> - umowa zawarta pomiędzy Usługodawcą a Klientem na wynajem nieruchomości
        Usługodawcy lub na świadczenie innej usługi organizowanej przez Usługodawcę.
      </p>
      <p>
        <b>Usługodawca</b> - osoba fizyczna prowadząca działalność gospodarczą, osoba prawna lub
        jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje
        zdolność prawną, która oferuje swoje usługi za pośrednictwem Portalu.
      </p>
      <p>
        <b>Użytkownicy</b> - Usługodawcy i Klienci.
      </p>
      <p>
        <b>Usługi</b> - usługi świadczone przez Usługodawcę za pośrednictwem Portalu polegające na
        oferowaniu noclegów, wyjazdów, warsztatów, szkoleń oraz wynajmu sprzętu sportowego i turystycznego.
      </p>

      <h2>

        Warunki świadczenia usług
      </h2>
      <p>
        Użytkownicy powinni spełniać następujące wymagania techniczne w celu korzystania z Portalu:
      </p>
      <ul>
        <li>
          połączenie z siecią Internet
        </li>
        <li>
          zainstalowana najnowsza wersja przeglądarki internetowa Edge, Chrome, Safari, Mozilla Firefox
        </li>
        <li>
          posiadanie i podanie adresu e-mail umożliwiającego przesłanie informacji dotyczących realizacji usługi
        </li>
      </ul>

      <p>
        Operator zapewnia techniczną platformę, umożliwiającą nawiązanie kontaktu Usługodawcom z
        Klientami, dokonywanie Rezerwacji oraz dodanie swojej oferty Usługi za pośrednictwem
        Portalu, a także dokonywanie oceny Usługodawców.
      </p>
      <p>
        Operator umożliwia Usługodawcom dodanie oferty Usługi polegającej na:
      </p>
      <ul>
        <li>
          organizacji tematycznego wyjazdu lub aktywności,
        </li>
        <li>
          udostępnieniu Klientom oferty noclegu w nieruchomości Usługodawcy,
        </li>
        <li>
          wypożyczeniu sprzętu sportowego lub turystycznego,
        </li>
        <li>
          organizacji szkoleń lub warsztatów,
        </li>
        <li>
          inne, związane z turystyką lub aktywnym spędzaniem czasu.
        </li>
      </ul>

      <p>
        Operator dokłada wszelkich starań, aby informacje prezentowane w Portalu,
        dotyczące poszczególnych Usługodawców, były jak najbardziej aktualne,
        jednakże nie ponosi odpowiedzialności za udostępnione przez Usługodawcę
        informacje nieprawdziwe lub nieaktualne.
      </p>
      <p>
        Po dokonaniu Rezerwacji przez Klienta, na podany przez niego adres e-mail,
        zostanie wysłane potwierdzenie. Skuteczne dokonanie Rezerwacji uzależnione
        jest od opłacenia pełnej wysokości opłaty, wskazanej przez Usługodawcę w
        Portalu w formie przedpłaty.
      </p>
      <p>
        Operator i Usługodawca nie odpowiadają za brak realizacji usługi, wynikający z
        dostarczenia niepełnych lub nieprawdziwych danych, podanych przez Klienta
        podczas dokonywania Rezerwacji.
      </p>
      <p>
        Usługodawca zobowiązuje się nie podawać danych adresowych oraz danych dotyczących
        płatności Klientowi, w celu ominięcia Rezerwacji przez Portal.
      </p>
      <p>
        Usługodawca zobowiązuje się podawać ceny swoich usług w Portalu identyczne
        lub niższe niż ceny dostępne bezpośrednio lub zamieszczone na innych kanałach.
      </p>
      <p>
        Usługodawca oraz Operator na podstawie art. 38 pkt 12 ustawy dnia 30 maja
        2014 roku o prawach konsumenta (Dz.U. 2014r. poz. 827) wyłączają możliwość
        odstąpienia od umowy przez Klienta będącego konsumentem. Zgodnie z ww.
        przepisem prawo do odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa
        lub na odległość nie przysługuje konsumentowi w odniesieniu do umów o świadczenie
        usług w zakresie zakwaterowania.
      </p>
      <p>
        Usługodawca jest odpowiedzialny wobec Klientów za prawidłowe
        wykonanie Usługi.
      </p>
      <p>
        Klient może dokonać zapłaty za Rezerwację za pośrednictwem systemu płatności
        internetowych zintegrowanego w portalu przy dokonywaniu Rezerwacji lub, jeżeli
        Usługodawca przewidział taką możliwość, w trakcie świadczenia Usługi.
      </p>
      <p>
        Wszystkie ceny podane w Portalu zawierają podatek VAT.
      </p>

      <h2>

        Rejestracja
      </h2>
      <p>
        Treść umieszczoną w Portalu można przeglądać bez zakładania konta użytkownika.
      </p>
      <p>
        W celu korzystania z Portalu polegającego na dokonaniu Rezerwacji przez
        Klienta lub dodania Usługi przez Usługodawcę, wymagane jest założenie konta w
        Portalu oraz zaakceptowanie Regulaminu.
      </p>
      <p>
        Wysyłając formularz rejestracyjny Użytkownik oświadcza, że:
      </p>

      <ul>
        <li>
          podane w nim dane są kompletne i zgodne ze stanem faktycznym i nie naruszają
          jakichkolwiek praw osób trzecich
        </li>
        <li>
          jest uprawniony do zawarcia umowy o świadczenie usług drogą elektroniczną
        </li>
        <li>
          zapoznał się z Regulaminem i zobowiązuje się go przestrzegać
        </li>
      </ul>

      <p>
        Dokonując rejestracji użytkownik może udzielić zgody na przetwarzanie jego danych
        osobowych w celach marketingowych lub w celach handlowych przez Operatora.
      </p>

      <h2>
        Konto użytkownika
      </h2>
      <p>
        Operator po otrzymaniu prawidłowo wypełnionego formularza rejestracyjnego
        utworzy dla Użytkownika, w ramach Portalu, unikalne konto o nazwie wybranej
        przez Użytkownika. Umowa o świadczenie przez Operatora nieodpłatnej usługi,
        polegającej na obsłudze konta Użytkownika, zostaje zawarta z dniem utworzenia
        konta Użytkownika.
      </p>
      <p>
        Operator może odmówić utworzenia nowego konta, a utworzone już konto w
        każdej chwili usunąć, jeśli jego nazwa jest sprzeczna z prawem, dobrymi
        obyczajami, narusza dobra osobiste osób trzecich lub uzasadnione interesy Użytkowników.
      </p>
      <p>
        Użytkownik uzyskuje dostęp do konta za pomocą unikalnego identyfikatora i
        hasła dostępu. Użytkownik zobowiązany jest nie ujawniać jakiejkolwiek osobie
        trzeciej identyfikatora lub hasła i ponosi wyłączną odpowiedzialność za szkody
        wyrządzone na skutek ich ujawnienia.
      </p>
      <p>
        W celu korzystania z Portalu, w ramach konta Użytkownika, niezbędne jest
        podanie następujących danych: imię i nazwisko, adres email, numer telefonu.
        W przypadku, gdy Usługodawca chciałby otrzymać fakturę, konieczne jest
        podanie danych niezbędnych do wystawienia faktury w świetle obowiązujących
        przepisów (w szczególności NIP, nazwa firmy lub imię i nazwisko, adres firmy lub adres zamieszkania).
      </p>
      <p>
        W ramach konta Użytkownika, Użytkownik może również dobrowolnie udostępnić
        swoje zdjęcie profilowe oraz dodatkowy opis Użytkownika. Opis Użytkownika
        nie może zawierać treści o charakterze niezgodnym z prawem, dobrymi obyczajami lub naruszać dobra osobiste osób trzecich.
      </p>
      <p>
        Na podstawie informacji zamieszczonych na koncie Użytkownika, generowany
        jest profil Użytkownika.
      </p>
      <p>
        W ramach profilu Użytkownika udostępniane są Operatorowi informacje
        zamieszczone za pośrednictwem konta Użytkownika, w tym zwłaszcza imię,
        nazwisko, adres email, numer telefonu, zdjęcie Użytkownika, dodatkowy
        opis zamieszczony na koncie, dane potrzebne do wystawienia faktury lub
        rachunku takie jak NIP, adres, nazwa firmy.
      </p>
      <p>
        W ramach profilu Użytkownika, w sposób automatyczny udostępniane są
        Operatorowi informacje o tym, ile razy Użytkownik skorzystał z usług
        świadczonych przez Usługodawców za pośrednictwem Portalu, a także
        informacje o ocenach i opiniach, które Użytkownik wystawił poszczególnym
        usługom, jeśli takie oceny i opinie zostały przez Użytkownika wystawione.
      </p>
      <p>
        Po założeniu profilu użytkownika Operator w ramach portalu administracyjnego
        jest w stanie przeglądać wszystkie usługi, z których korzystał, włączając
        płatności, komunikację z Usługodawcami oraz historię zakupów i odwiedzanych
        lokalizacji.
      </p>
      <p>
        Profil Klienta dostępny jest do wglądu Operatora oraz Usługodawcy, do którego
        Klient skierował prośbę o rezerwację. Profil nie jest dostępny do wglądu przez
        innych Użytkowników Portalu.
      </p>
      <p>
        Profil Usługodawcy jest dostępny do wglądu dla wszystkich Użytkowników Portalu,
        w tym Użytkowników niezalogowanych, przeglądających ofertę danego Usługodawcy,
        przy czym dostępne dla tych Użytkowników są jedynie następujące dane: imię,
        pierwsza litera nazwiska, nazwa konta (nick), nazwa firmy i zdjęcie Usługodawcy.
      </p>

      <h2>
        Usunięcie konta użytkownika

      </h2>
      <p>
        Użytkownik niebędący Usługodawcą może w dowolnym momencie usunąć
        swoje konto z Portalu kontaktując się z Operatorem.
      </p>
      <p>
        W przypadku usunięcia konta Użytkownika, jego profil przestaje być
        dostępny dla innych Użytkowników. Wystawione przez Użytkownika oceny
        i opinie nie podlegają usunięciu.
      </p>
      <p>
        W celu usunięcia konta Usługodawcy, Usługodawca powinien skontaktować
        się z Operatorem, wysyłając maila na adres connect@wacampa.com .
        Usługodawca uzgadnia z Operatorem zasady usunięcia lub dalszego
        publikowania ofert Usługodawcy, w tym także kwestie wzajemnych rozliczeń.
      </p>
      <p>
        W przypadkach wskazanych w Regulaminie, konto Użytkownika może
        zostać usunięte przez Operatora.
      </p>
      <p>
        Z chwilą usunięcia konta umowa o świadczenie przez Operatora usługi
        polegającej na obsłudze konta Użytkownika, zostaje rozwiązana.
      </p>

      <h2>
        Prawa i obowiązki
      </h2>
      <p>
        Operator zobowiązuje się do stałego i nieprzerwanego utrzymywania
        Portalu w celu korzystania z niego przez Użytkowników
      </p>
      <p>
        Operator zastrzega sobie ponadto prawo do:
      </p>

      <ul>
        <li>
          przejściowego zaprzestania utrzymywania Portalu ze względu na czynności konserwacyjne
          lub związane z modyfikacją Portalu,
        </li>
        <li>
          wysyłania na adresy poczty elektronicznej Użytkowników komunikatów technicznych
          związanych z funkcjonowaniem Portalu i realizacją Usług,
        </li>
        <li>
          zablokowania dostępu do zasobów Użytkownikom, których konto zawiera treści erotyczne,
          pornograficzne, zawiera nielegalne oprogramowanie lub informację na temat jego pozyskania,
          oraz inne treści sprzeczne z prawem, dobrymi obyczajami lub uzasadnionymi interesami Użytkowników,
        </li>
        <li>
          zaprzestania świadczenia usług i usunięcia konta Użytkownika w Portalu w razie
          nieprzestrzegania przez niego Regulaminu lub w przypadku, gdy skuteczne świadczenie
          Usług jest niemożliwe z winy Użytkownika,
        </li>
        <li>
          weryfikowania w każdym czasie, czy Usługodawca spełnił wymogi do naliczenia przez
          Operatora prowizji w obniżonej wysokości, czy też wbrew zapewnieniom Usługodawcy,
          prowizja winna być naliczona w standardowej wysokości. W przypadku stwierdzenia ww.
          naruszenia Operator jest uprawniony do dokonania korekty wysokości prowizji za okres,
          w którym Usługodawca nie był uprawniony do otrzymywania zniżki oraz naliczania prowizji
          w standardowej wysokości w kolejnych okresach,
        </li>
        <li>
          usunięcia oferty Usługodawcy, w przypadku, gdy jest ona niezgodna z
          postanowieniami Regulaminu.
        </li>
      </ul>

      <p>
        Zakazane jest dostarczanie przez Użytkownika treści o charakterze
        bezprawnym oraz wykorzystywanie przez niego Usług Portalu w sposób
        sprzeczny z prawem, dobrymi obyczajami oraz naruszający dobra osobiste
        osób trzecich lub uzasadnione interesy Użytkowników.
      </p>
      <p>
        Usługodawca nie zezwala na kopiowanie, modyfikowanie, rozpowszechnianie lub
        wykorzystywanie w jakikolwiek inny sposób jakichkolwiek utworów udostępnionych
        w Portalu z wyjątkiem korzystania z nich w ramach korzystania z usług Portalu.
      </p>

      <h2>
        Opłaty
      </h2>
      <p>
        Operator nie pobiera opłat od Klientów za korzystanie z Portalu.
      </p>
      <p>
        Operator pobiera opłaty od Usługodawcy, za korzystanie z Portalu, w formie prowizji
        od dokonanych transakcji w wysokości 10% ich wartości. Operator jest uprawniony do
        zmiany wysokości opłat i zasad odnoszących się do Usługodawców, przy zachowaniu
        14-dniowego okresu wypowiedzenia.
      </p>
      <p>
        Płatność za Rezerwację jest dokonywana za pośrednictwem Portalu, z wykorzystaniem
        operatora płatności zintegrowanego z Portalem.
      </p>
      <p>
        Operatorem Płatności dla Portalu Wacampa jest Stripe.
      </p>
      <p>
        W celu płatności za Rezerwację należy podać dane swojej karty płatniczej lub
        zapłacić w inny, dostępny sposób, np. BLIKiem. Płatność za Rezerwację należy
        dokonać w czasie procesu rezerwacji albo jeżeli wysłane jest zapytanie do
        Gospodarza o możliwość Rezerwacji – w terminie wskazanym przez Gospodarza.
        W innym przypadku Rezerwacja nie zostanie potwierdzona, a usługi nie zostaną
        zarezerwowane. Wszelkie nieprawidłowości w procesie Płatności za Rezerwację
        muszą być zgłaszane do Operatora Płatności.
      </p>
      <p>
        Na poniżesz stronie można się zapoznać z prowizjami Operatora Płatności.
        Prowizje Stripe są wliczone już w prowizje
        Operatora Portalu. <a href="https://stripe.com/en-pl/pricing" target='_blank'>https://stripe.com/en-pl/pricing</a>
      </p>

      <h2>
        Rabaty
      </h2>
      <p>
        Operator przewiduje możliwość przyznawania kodów rabatowych do wykorzystania w Portalu.
      </p>
      <p>
        Przyznane kody rabatowe upoważniać będą do określonej zniżki, zgodnie z
        zamieszczanymi w Portalu zasadami lub regulaminem danej akcji rabatowej, w
        ramach której kody rabatowe będą przyznawane.
      </p>

      <h2>

        Odpowiedzialność
      </h2>
      <p>

        Operator nie ponosi odpowiedzialności za:
      </p>
      <ul>
        <li>
          jakiekolwiek szkody wyrządzone osobom trzecim, powstałe w wyniku korzystania
          przez Użytkowników z Usług w sposób sprzeczny z Regulaminem lub przepisami prawa
        </li>
        <li>
          bezpośrednio lub pośrednio związane z Portalem informacje oraz materiały pobrane
          i wysyłane za pośrednictwem sieci Internet przez Użytkowników
        </li>
        <li>
          utratę przez Użytkownika danych spowodowanych działaniem
          czynników zewnętrznych (np. awaria oprogramowania) lub też
          innymi okolicznościami niezależnymi od Operatora np. wskutek działanie osób trzecich
        </li>
        <li>
          szkody wynikłe na skutek braku ciągłości działania Portalu
        </li>
        <li>
          podanie przez Użytkowników nieprawdziwych lub niepełnych informacji
          przy rejestracji konta lub korzystaniu z Usług
        </li>
        <li>
          inne przypadki nieprzestrzegania przez Użytkownika warunków niniejszego
          Regulaminu oraz przepisów prawa
        </li>
        <li>
          działalność Usługodawców
        </li>
        <li>
          zobowiązania wynikające z zawartej między Usługodawcą i
          Klientem umowy, w tym za niewykonanie bądź nienależyte wykonanie tej
          umowy w tym także za wyrządzone przez nich szkody w związku z jej wykonywaniem.
        </li>
      </ul>

      <h2>
        Reklamacje
      </h2>
      <p>
        Użytkownicy mają prawo składać reklamacje dotyczące realizacji
        Usług bezpośrednio do Usługodawcy.
      </p>
      <p>
        Reklamacje dotyczące realizacji Usług, mogą dotyczyć zgodności
        wykonanej przez Usługodawcę Usługi z ofertą zamieszczoną przez
        Usługodawcę w portalu Wacampa
      </p>
      <p>
        Użytkownicy mają prawo składać reklamacje dotyczące działalności
        Portalu <a href="wacampa.com" target='_blank'>wacampa.com</a> bezpośrednio u Operatora portalu wysyłając
        reklamację na adres email: <a href="mailto:connect@wacampa.com">connect@wacampa.com</a>
      </p>
      <p>
        Reklamacja powinna zawierać:
      </p>

      <ul>
        <li>
          nazwę Użytkownika
        </li>
        <li>
          przedmiot reklamacji
        </li>
        <li>
          uzasadnienie reklamacji
        </li>
      </ul>

      <p>
        Reklamacje będą rozpatrywane w terminie 14 dni od daty otrzymania zgłoszenia.
      </p>

      <h2>
        Dane osobowe
      </h2>
      <p>
        Zgodnie Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z
        dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
        przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
        danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
        Użytkownik wyraża zgodę na przetwarzanie przez Operatora danych osobowych przekazanych w
        formularzu rejestracyjnym oraz danych zaktualizowanych podczas korzystania z Portalu w
        celu zrealizowania Usługi świadczonej przez Operatora.
      </p>
      <p>
        Użytkownicy akceptując Regulamin wyrażają zgodę na przetwarzanie ich danych
        osobowych w celach realizacji usług świadczonych przez Operatora i Usługodawców.
      </p>
      <p>
        Za przetwarzanie danych w ramach Portalu odpowiada Operator, będąc tak
        zwanym administratorem danych osobowych.
      </p>
      <p>
        Usługodawca zawiera z Operatorem umowę o publikację informacji o obiekcie,
        przedmiocie czy też usłudze na Portalu. Operator przetwarza dane osobowe
        Usługodawcy w celu:
      </p>

      <ul>
        <li>
          realizacji zawartej z Portalem umowy i
        </li>
        <li>
          umożliwić publikację informacji o usłudze w Portalu.
        </li>
        <li>
          umożliwienia Klientowi: dokonania rezerwacji świadczonych przez Usługodawcę
          usług, kontaktu, umożliwienia dokonania płatności za usługi oraz innych
          czynności związanych z zawartą z Operatorem umową.
        </li>
        <li>
          poprawy jakości świadczonych usług.
        </li>
        <li>
          dla celów dokonania rozliczeń, wystawiania faktur, prowadzenia księgowości i
          dokonywania sprawozdawczości wymaganej przepisami prawa.
        </li>
        <li>
          rozpatrywania reklamacji lub dochodzenia roszczeń.
        </li>
      </ul>

      <p>
        Wszystkie cele przetwarzania danych osobowych Usługodawcy znajdują swoją
        podstawę prawną w art. 6 ust. 1 lit. b, c oraz f RODO. Co więcej, jeżeli
        Usługodawca wyraził zgodę na przetwarzanie danych osobowych w celach marketingowych,
        Operator będzie przetwarzać dane osobowe Klienta na podstawie art. 6 ust. 1 lit. a RODO.
      </p>
      <p>
        Dane o Usługodawcy oraz jego działalności i usługach będą umieszczane na Portalu,
        gdzie będą publicznie dostępne.
      </p>
      <p>
        Dane osobowe Klienta są przetwarzane w celu:
      </p>

      <ul>
        <li>
          umożliwienia założenia konta w serwisie, dokonania rezerwacji
          wybranych przez niego usług, dokonania płatności za nie oraz kontaktu z Usługodawcą.
        </li>
        <li>
          poprawy jakości świadczonych przez niego usług oraz umożliwienia
          Klientowi dodania opinii w Portalu.
        </li>
        <li>
          dokonania rozliczeń, wystawiania faktur, prowadzenia księgowości i
          dokonywania sprawozdawczości wymaganej przepisami prawa.
        </li>
        <li>
          rozpatrywania reklamacji lub dochodzenia roszczeń.
        </li>
      </ul>

      <p>
        Wszystkie cele przetwarzania danych osobowych Klienta znajdują swoją podstawę
        prawną w art. 6 ust. 1 lit. b, c oraz f RODO. Co więcej, jeżeli Klient wyraził
        zgodę na przetwarzanie danych osobowych w celach marketingowych, Operator będzie
        przetwarzać dane osobowe Klienta na podstawie art. 6 ust. 1 lit. a RODO. Jeżeli
        Klient rezerwuje usługi turystyczne oferowane w Portalu przez Usługodawcę,
        Operator przekaże Usługodawcy, którego wybrał następujące informacje: - imię i
        nazwisko, - numer telefonu, - datę rezerwacji, - zakres usług, - liczbę osób, -
        życzenia specjalne odnośnie do świadczonych usług.
      </p>
      <p>
        Dane Klienta są przekazywane przy Rezerwacji do Usługodawcy, zgodnie z
        zakresem wskazanym powyżej. Jest to niezbędne dla dokonania procesu rezerwacji.
      </p>
      <p>
        Operator przetwarza dane osobowe Użytkowników również dla celów:
      </p>

      <ul>
        <li>
          monitorowania sposobu korzystania z Portalu,
        </li>
        <li>
          przewidywania zapotrzebowania na usługi, w szczególności w celu odpowiedniego
          przygotowania serwerów i witryn na przewidywane zapotrzebowanie,
        </li>
        <li>
          przeciwdziałania nadużyciom i zapobiegania oszustwom,
        </li>
        <li>
          ciągłego rozwijania Portalu,
        </li>
        <li>
          dostosowania oferty do potrzeb i zainteresowań poszczególnych użytkowników,
        </li>
        <li>
          informowania użytkowników o zmianach w Portalu, w tym o zmianach w Regulaminach.
        </li>
      </ul>

      <p>
        Podstawą przetwarzania przez Operatora danych osobowych w powyższych celach jest prawnie
        usprawiedliwiony interes, co znajduje oparcie w art. 6 ust. 1 lit. f RODO.
      </p>
      <p>
        Zgodnie z RODO, Użytkownikom przysługują następujące prawa w związku z przetwarzaniem przez
        Operatora ich danych osobowych:
      </p>
      <p>
        Prawo do otrzymania informacji odnośnie do przetwarzania danych osobowych
        (w szczególności informacji w jakim celu, jakie dane i przez kogo są przetwarzane oraz
        komu zostały udostępnione, ponadto przysługuje również prawo informacji, oraz jak długo
        dane Użytkownika będą przetwarzane).
      </p>
      <p>
        Regulamin stanowi realizację prawa do informacji.
      </p>
      <p>
        Użytkownicy mają również prawo do:
      </p>
      <ul>
        <li>
          wniesienia skargi do Operatora odnośnie do sposobu przetwarzania danych oraz prawo
          wniesienia skargi do Urzędu Ochrony Danych Osobowych, ul. Stawki 2,
          Warszawa, (<a href="https://uodo.gov.pl/" target='_blank'>https://uodo.gov.pl/</a> ).
        </li>
        <li>
          otrzymania kopi danych osobowych Użytkownika, które są przetwarzane przez
          administratora danych osobowych.
        </li>
        <li>
          żądania usunięcia danych osobowych Użytkownika (w wypadku podważenia prawa do
          przetwarzania danych Użytkownika) lub wniesienia sprzeciwu odnośnie ich przetwarzania.
        </li>
        <li>
          wniesienia wniosku o ograniczenie przetwarzania danych osobowych
          Użytkownika.
        </li>
        <li>
          żądać sprostowania lub poprawiania danych osobowych Użytkownika.
        </li>
        <li>
          do przenoszenia danych osobowych Użytkownika.
        </li>
      </ul>

      <p>
        Długość przetwarzania danych osobowych Użytkownika.
      </p>
      <p>
        Operator będzie przetwarzać dane osobowe przez okres niezbędny dla realizacji celów, o
        których mowa powyżej oraz do czasu wypełnienia obowiązków prawnych nałożonych na Operatora.
        Co do zasady, dane osobowe będą przetwarzane przez 6 lat od zakończenia obowiązywania
        stosunku prawnego, jaki łączy Operatora z Użytkownikiem. Okres ten jest uzasadniony
        obowiązującym w Polsce okresem przedawnienia roszczeń cywilnych i podatkowych.
        Bardziej szczegółowe informacje są dostępne poniżej:
      </p>

      <ul>
        <li>
          Świadczenie Usług - Dane osobowe Użytkownika będą przetwarzane przez cały okres
          obowiązywania umowy o świadczenie usług lub posiadania konta Użytkownika.
          Jeżeli umowa przestanie obowiązywać lub konto Użytkownika zostanie skasowane
          Operator będzie przechowywać dane przez 6 lat od tego momentu.
        </li>
        <li>
          Informacje technologiczne i statystyczne - Dane osobowe Użytkownika będą
          przetwarzane przez cały okres posiadania konta Użytkownika, a po jego usunięciu,
          będą przechowywane przez 6 lat od jego usunięcia.
        </li>
        <li>
          Marketing - Dane osobowe Użytkownika będą przetwarzane do czasu, w którym Użytkownik
          sprzeciwi się ich dalszemu wykorzystaniu lub cofnie zgodę na ich przetwarzanie.
        </li>
        <li>
          Reklamacje i skargi - Dane osobowe Użytkownika będą przetwarzane w związku ze
          skargami lub reklamacjami przez 6 lat od dnia ich złożenia, lub zakończenia procesu reklamacyjnego.
        </li>
      </ul>

      <p>
        Dodatkowo Operator może również w ograniczonym i niezbędnym zakresie ujawniać dane osobowe:
      </p>
      <ul>
        <li>
          Firmom, które świadczą na rzecz Operatora różne usługi, np.: obsługę serwerów,
          dostarczanie rozwiązań IT czy doradztwo biznesowe,
        </li>
        <li>
          Kancelariom prawnym i firmom audytorskim,
        </li>
        <li>
          Bankom lub operatorom płatności.
        </li>
      </ul>
      <p>
        Przetwarzanie Danych przez Operatora Płatności
      </p>
      <p>
        Stripe jako globalny Operator Płatności zapewnia bezpieczeństwo przetwarzanych
        danych osobowych. Na poniżesz stronie można się zapoznać z polityka prywatności
        Operatora Płatności. <a href="https://stripe.com/en-pl/legal/privacy-center" target='_blank'>https://stripe.com/en-pl/legal/privacy-center</a>
      </p>
      <p>
        Stripe weryfikuje dane użytkowników w celu zapewnienia bezpieczeństwa transakcji.
        Przekazywane im dane osobowe służą tylko do tego celu. Więcej informacji na
        ten temat znajduje się na
        stronie: <a href="https://stripe.com/en-pl/legal/connect-account" target="_blank">https://stripe.com/en-pl/legal/connect-account</a>
      </p>

      <h2>
        Własność intelektualna
      </h2>
      <p>
        W przypadku skorzystania przez Użytkownika z Usług związanych z publicznym
        udostępnieniem w Portalach swojego wizerunku, na podstawie art. 81 Ustawy
        o prawie autorskim i prawach pokrewnych, Użytkownik wyraża zgodę na jego
        nieodpłatne utrwalanie, zwielokrotnienie i rozpowszechnienie przez Usługodawcę.
        Usługodawca oświadcza, że Wizerunek będzie wykorzystywany w celu poprawnej
        realizacji Usług, lub wykorzystywany będzie w celu identyfikacji Użytkownika.
      </p>
      <p>
        Użytkownik oświadcza, że przysługują mu autorskie prawa majątkowe do wszystkich treści
        zamieszczanych w Portalu, stanowiących utwory w rozumieniu Ustawy z dnia 4
        lutego 1994 r. o prawie autorskim i prawach pokrewnych (dalej: „Utwory”).
        Użytkownik udziela Operatorowi nieodpłatnej, niewyłącznej oraz nieograniczonej
        terytorialnie licencji na czas realizacji usług, zezwalającej na korzystanie z
        Utworów na następujących polach eksploatacji, obejmujących w szczególności:
        wytwarzanie, zwielokrotnianie, publiczne odtworzenie i wyświetlanie, wprowadzenie
        do pamięci komputera i serwerów sieci komputerowych, umieszczanie w sieci Internet.
      </p>
      <p>
        Usługodawcy, akceptując Regulamin, wyrażają zgodę na użycie przez Operatora
        materiałów umieszczonych w ich ofertach w celach marketingowych dotyczących
        Operatora. Usługodawcy wyrażają zgodę na użycie w szczególności zdjęć, opisów
        Usług oraz materiałów filmowych Usługodawców i w tym zakresie udzielają Operatorowi
        nieodpłatnej, niewyłącznej oraz nieograniczonej terytorialnie licencji zezwalającej
        na korzystanie z tych materiałów na zasadach i polach eksploatacji obejmujących w
        szczególności: wytwarzanie, zwielokrotnianie, publiczne odtworzenie i wyświetlanie,
        wprowadzenie do pamięci komputera i serwerów sieci komputerowych, umieszczanie w sieci Internet.
      </p>
      <p>
        Usługodawcy, akceptując Regulamin, wyrażają zgodę na przekazanie przez Operatora
        materiałów umieszczonych w ich ofertach podmiotom trzecim (np. sieci afiliacyjne,
        partnerzy afiliacyjni) i w tym zakresie udzielają Operatorowi nieodpłatnej, niewyłącznej
        oraz nieograniczonej terytorialnie licencji zezwalającej na korzystanie z tych
        materiałów na zasadach i polach eksploatacji obejmujących w szczególności: wytwarzanie,
        zwielokrotnianie, publiczne odtworzenie i wyświetlanie, wprowadzenie do pamięci
        komputera i serwerów sieci komputerowych, umieszczanie w sieci Internet.
      </p>

      <h2>
        Rozwiązywanie sporów
      </h2>
      <p>
        Wszelkie spory pomiędzy Użytkownikami a Operatorem będą rozstrzygane polubownie w drodze negocjacji.
      </p>
      <p>
        W przypadku braku porozumienia w terminie 60 dni od powstania sporu, może
        zostać on poddany pod rozstrzygnięcie sądu powszechnego właściwego dla siedziby
        Operatora. Powyższe ograniczenie właściwości miejscowej sądu nie dotyczy sporów
        Operatora z konsumentami.
      </p>

      <h2>
        Postanowienia końcowe
      </h2>
      <p>
        Regulamin obowiązuje od 01.05.2023 r.
      </p>
      <p>
        Usługodawca ma prawo do zmiany Regulaminu, między innymi w przypadku zmiany 
        obowiązujących przepisów prawa, mających wpływ na funkcjonowanie portalu, 
        zmiany lub rozszerzenia zakresu działalności portalu oraz uszczegółowienia 
        lub ulepszenia istniejących funkcjonalności Portalu.
      </p>
      <p>
        Zmiany Regulaminu obowiązują po upływie 14 dni od dnia poinformowania Użytkowników 
        za pośrednictwem poczty elektronicznej. Użytkownik, który nie wyraża zgody na zmianę 
        Regulaminu, może bez ponoszenia negatywnych konsekwencji zrezygnować z korzystania z 
        Portalu, w tym usunąć swoje konto.
      </p>
      <p>
        W sprawach nieuregulowanych w Regulaminie zastosowanie mają przepisy ustawy o świadczeniu 
        usług drogą elektroniczną, ustawy o ochronie danych osobowych, kodeksu cywilnego i inne 
        bezwzględnie obowiązujące przepisy prawa polskiego.
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
