import React from 'react';
import { bool } from 'prop-types';
import SelectSingleFilterPlainWithIcons from './SelectSingleFilterPlainWithIcons';
import SelectSingleFilterPopupWithIcons from './SelectSingleFilterPopupWithIcons';

const SelectSingleFilterWithIcons = props => {
  const { showAsPopup, ...rest } = props;
  // return showAsPopup ? (
  //   <SelectSingleFilterPopupWithIcons {...rest} />
  // ) : (
  //   <SelectSingleFilterPlainWithIcons {...rest} />
  // );

  return (
    <SelectSingleFilterPlainWithIcons {...rest} />
  )
};

SelectSingleFilterWithIcons.defaultProps = {
  showAsPopup: false,
};

SelectSingleFilterWithIcons.propTypes = {
  showAsPopup: bool,
};

export default SelectSingleFilterWithIcons;
