import React from "react";

import NamedLink from "../NamedLink/NamedLink";

import css from './TopbarDesktop.module.css';

const Favourites = ({ favourites = [] }) => {
  return (
    <NamedLink
      className={css.favouritesContainer}
      name="FavouritesListingsPage"
    >
      <div className={css.favouritesContainer}>
        <svg className={css.favouritesLikeIcon} width="25" height="22" viewBox="0 0 25 22" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.9259 3.931L12.393 4.38219L12.8446 3.91558C13.0028 3.75217 13.1547 3.59313 13.3021 3.43887C14.7168 1.95818 15.7099 0.9187 17.7396 0.686479C19.8578 0.447756 21.9042 1.40324 23.1485 2.95742C24.3806 4.49621 24.8084 6.59411 23.746 8.69288L23.7459 8.69303C23.1183 9.93373 21.8045 11.4638 20.2788 13.0264L20.2787 13.0265C19.2289 14.1022 18.1265 15.147 17.1221 16.099C16.5239 16.6659 15.9605 17.1999 15.4635 17.6879C15.4635 17.6879 15.4635 17.688 15.4635 17.688L12.3728 20.7227L9.90303 18.3699C9.32125 17.8153 8.69501 17.2502 8.05074 16.6688C6.68831 15.4394 5.2452 14.1371 3.97119 12.7077C2.10532 10.6143 0.746846 8.39438 0.653623 6.00872C0.541489 2.78268 3.08967 0.674344 6.14883 0.712724C7.5273 0.73126 8.47506 1.07451 9.30776 1.61692C10.043 2.09586 10.6891 2.72607 11.4788 3.49627C11.6226 3.63656 11.7712 3.78149 11.9259 3.931Z" stroke="#2F4E1E" strokeWidth="1.3"/>
        </svg>
        {favourites.length ? <div className={css.circleContainer}>
          <div className={css.circleInnerContainer}>
            <svg className={css.circleIcon} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="7.5" cy="7.5" r="7" fill="#D16060" stroke="white"/>
            </svg>
            <span className={css.favouritesCount}>{favourites.length}</span>
          </div>
        </div> : null}
      </div>
    </NamedLink>
  )
};

export default Favourites;
