import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { PrimaryButton, InlineTextButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BookingDatesForm.module.css';

const MinusIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.875 7.00003H13.125"
        stroke="#2F4E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const PlusIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.875 7.00003H13.125"
        stroke="#2F4E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13.125L7 0.874999"
        stroke="#2F4E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const AddGuestsIcon = () => {
  return (
    <svg
      className={css.addGuestsIcon}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.86992 8.1525C6.79492 8.145 6.70492 8.145 6.62242 8.1525C4.83742 8.0925 3.41992 6.63 3.41992 4.83C3.41992 2.9925 4.90492 1.5 6.74992 1.5C8.58742 1.5 10.0799 2.9925 10.0799 4.83C10.0724 6.63 8.65492 8.0925 6.86992 8.1525Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3075 3C13.7625 3 14.9325 4.1775 14.9325 5.625C14.9325 7.0425 13.8075 8.1975 12.405 8.25C12.345 8.2425 12.2775 8.2425 12.21 8.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.12004 10.92C1.30504 12.135 1.30504 14.115 3.12004 15.3225C5.18254 16.7025 8.56504 16.7025 10.6275 15.3225C12.4425 14.1075 12.4425 12.1275 10.6275 10.92C8.57254 9.5475 5.19004 9.5475 3.12004 10.92Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7549 15C14.2949 14.8875 14.8049 14.67 15.2249 14.3475C16.3949 13.47 16.3949 12.0225 15.2249 11.145C14.8124 10.83 14.3099 10.62 13.7774 10.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const IconButton = ({ handleChange, children }) => {
  return (
    <div className={css.iconButton} onClick={handleChange}>
      {children}
    </div>
  );
};

const AddGuests = ({ intl, handleChangeGuests, guests, maxNumberOfGuests, maxUnits }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [people, setPeople] = useState(guests);
  const modalWrapper = useRef(null);

  const disabled = Math.ceil((people.kids + people.adults) / maxNumberOfGuests) > maxUnits;

  const bookingDatesAddGuests = intl.formatMessage({
    id: 'BookingDatesForm.bookingDatesAddGuests',
  });
  const bookingDatesAddGuestsAdults = intl.formatMessage({
    id: 'BookingDatesForm.bookingDatesAddGuestsAdults',
  });
  const bookingDatesAddGuestsKids = intl.formatMessage({
    id: 'BookingDatesForm.bookingDatesAddGuestsKids',
  });
  const bookingDatesApply = intl.formatMessage({
    id: 'BookingDatesForm.bookingDatesApply',
  });
  const bookingDatesAddGuestsClear = intl.formatMessage({
    id: 'BookingDatesForm.bookingDatesAddGuestsClear',
  });

  const handleOpenMenu = () => setShowMenu(prev => !prev);

  const handleChangePeopleCount = (operation, type) => () => {
    switch (operation) {
      case 'inc': {
        const copy = { ...people };
        copy[type] += 1;
        setPeople(copy);
        break;
      }
      case 'dec': {
        const copy = { ...people };
        if (copy[type] !== 0) {
          copy[type] -= 1;
          setPeople(copy);
        }
        break;
      }
    }
  };

  const apply = () => {
    if (disabled) {
      return;
    }
    handleChangeGuests(people);
    handleOpenMenu();
  };

  const clear = () => {
    const guests = {
      adults: 0,
      kids: 0,
    };
    handleChangeGuests(guests);
    setPeople(guests);
    handleOpenMenu();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalWrapper.current && !modalWrapper.current.contains(event.target) && showMenu) {
        setPeople(guests);
        setShowMenu(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalWrapper, showMenu]);

  useEffect(() => {
    if (JSON.stringify(guests) !== JSON.stringify(people)) {
      setPeople(guests);
    }
  }, [guests])

  const title = people.kids + people.adults || bookingDatesAddGuests;

  return (
    <div className={css.addGuestsContainer} ref={modalWrapper}>
      <div className={css.addGuestsBtn} onClick={handleOpenMenu}>
        <span className={css.addGuestsText}>{title}</span>
        <span>
          <AddGuestsIcon />
        </span>
      </div>
      {showMenu ? (
        <div className={css.addGuestsMenu}>
          <>
            <div className={css.personCountContainer}>
              <span className={css.addGuestsPersonText}>{bookingDatesAddGuestsAdults}</span>
              <div className={css.btnsContainer}>
                <IconButton handleChange={handleChangePeopleCount('dec', 'adults')}>
                  <MinusIcon />
                </IconButton>
                <div>
                  <span className={css.addGuestsCount}>{people.adults}</span>
                </div>
                <IconButton handleChange={handleChangePeopleCount('inc', 'adults')}>
                  <PlusIcon />
                </IconButton>
              </div>
            </div>
            <div className={css.addGuestsMenuDivider} />
            <div className={css.personCountContainer}>
              <span className={css.addGuestsPersonText}>{bookingDatesAddGuestsKids}</span>
              <div className={css.btnsContainer}>
                <IconButton handleChange={handleChangePeopleCount('dec', 'kids')}>
                  <MinusIcon />
                </IconButton>
                <div>
                  <span className={css.addGuestsCount}>{people.kids}</span>
                </div>
                <IconButton handleChange={handleChangePeopleCount('inc', 'kids')}>
                  <PlusIcon />
                </IconButton>
              </div>
            </div>
            <div className={css.addGuestsMenuDivider} />
          </>
          {disabled ? (
            <p className={classNames(css.error, css.guestsError)}>
              <FormattedMessage
                id="BookingDatesForm.guestsError"
                values={{
                  maxNumberOfGuests: maxNumberOfGuests * maxUnits,
                  plural: maxNumberOfGuests * maxUnits > 1 ? 's' : '',
                }}
              />
            </p>
          ) : null}
          <div className={css.btnsContainer}>
            <PrimaryButton type="button" onClick={apply}>
              {bookingDatesApply}
            </PrimaryButton>
            <InlineTextButton type="button" onClick={clear}>
              {bookingDatesAddGuestsClear}
            </InlineTextButton>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AddGuests;
