import React from "react";

import css from "./BookingPanel.module.css";

const InfoIcon = () => {
  return (
    <svg className={css.infoIcon} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.99983 0C1.79098 0 0 1.79098 0 3.99983C0 6.20868 1.79098 8 3.99983 8C6.20868 8 8 6.20868 8 3.99983C8 1.79098 6.20868 0 3.99983 0ZM4.83251 6.1992C4.62662 6.28047 4.46273 6.34209 4.33981 6.38476C4.21723 6.42743 4.07467 6.44876 3.91247 6.44876C3.66324 6.44876 3.46921 6.38781 3.33105 6.26624C3.19289 6.14468 3.12415 5.9906 3.12415 5.80334C3.12415 5.73054 3.12923 5.65604 3.13939 5.58019C3.14988 5.50434 3.16648 5.41901 3.18916 5.32317L3.44686 4.41295C3.46954 4.32559 3.48919 4.24262 3.50476 4.16542C3.52034 4.08753 3.52779 4.01608 3.52779 3.95107C3.52779 3.83526 3.50375 3.75399 3.456 3.70828C3.40758 3.66256 3.31649 3.64021 3.1807 3.64021C3.11433 3.64021 3.04593 3.65003 2.97583 3.67069C2.90641 3.69202 2.84614 3.71132 2.7967 3.73029L2.86476 3.4499C3.0334 3.38116 3.19492 3.32224 3.34899 3.27348C3.50307 3.22404 3.64868 3.19966 3.78582 3.19966C4.03335 3.19966 4.22434 3.25994 4.35877 3.37913C4.49253 3.49867 4.55992 3.65409 4.55992 3.84508C4.55992 3.8847 4.55517 3.95445 4.54603 4.05401C4.53689 4.1539 4.51962 4.24499 4.49456 4.32863L4.23822 5.23615C4.21723 5.30895 4.1986 5.39225 4.18167 5.48538C4.16508 5.5785 4.15695 5.64961 4.15695 5.69735C4.15695 5.8179 4.1837 5.90019 4.23788 5.94387C4.29139 5.98756 4.38519 6.00957 4.51793 6.00957C4.58057 6.00957 4.65067 5.99839 4.7299 5.97672C4.80847 5.95505 4.86535 5.93575 4.90125 5.91915L4.83251 6.1992ZM4.78713 2.51564C4.6676 2.62671 4.52368 2.68224 4.35539 2.68224C4.18743 2.68224 4.0425 2.62671 3.92195 2.51564C3.80207 2.40457 3.74146 2.26946 3.74146 2.11166C3.74146 1.9542 3.80241 1.81875 3.92195 1.70667C4.0425 1.59424 4.18743 1.53837 4.35539 1.53837C4.52368 1.53837 4.66794 1.59424 4.78713 1.70667C4.90667 1.81875 4.9666 1.9542 4.9666 2.11166C4.9666 2.2698 4.90667 2.40457 4.78713 2.51564Z" fill="#2F4E1E"/>
    </svg>
  );
}

const Discount = ({ intl, listing }) => {
  const {
    discountDuration,
    discountDurationDays,
    discountGroup,
    discountGroupPeople,
    discountKids,
    discountMultiple,
    discountMultipleItems,
  } = listing?.attributes?.publicData?.discountOptions ?? {};

  const title = intl.formatMessage(
    { id: 'BookingPanel.discountTitle' },
  );
  const discountDurationDesc = intl.formatMessage(
    { id: 'BookingPanel.discountDuration' },
    {
      discountDuration: <span>{discountDuration}%</span>,
      discountDurationDays: <span>{discountDurationDays}</span>,
    }
  );
  const discountGroupDesc = intl.formatMessage(
    { id: 'BookingPanel.discountGroup' },
    {
      discountGroup: <span>{discountGroup}%</span>,
      discountGroupPeople: <span>{discountGroupPeople}</span>
    }
  );
  const discountKidsDesc = intl.formatMessage(
    { id: 'BookingPanel.discountKids' },
    { discountKids: <span>{discountKids}%</span> }
  );
  const discountMultipleDesc = intl.formatMessage(
    { id: 'BookingPanel.discountMultiple' },
    {
      discountMultiple: <span>{discountMultiple}%</span>,
      discountMultipleItems: <span>{discountMultipleItems}</span>
    }
  );

  const totalDiscountCount =
    +discountKids +
    +discountDuration +
    +discountGroup +
    +discountMultiple;

  return (
    <div className={css.discountsContainer}>
      <div className={css.discountsInnerContainer}>
        <span className={css.discountTitle}>{title}</span>
        {/* <span className={css.discounts}>{totalDiscountCount}%</span> */}
        <InfoIcon />
        {totalDiscountCount > 0 ? <div className={css.discountsPopup}>
          <ul className={css.discountsList}>
            {discountKids ? <li className={css.discountsListItem}>{discountKidsDesc}</li> : null}
            {discountGroup ? <li className={css.discountsListItem}>{discountGroupDesc}</li>: null}
            {discountDuration ? <li className={css.discountsListItem}>{discountDurationDesc}</li> : null}
            {discountMultiple ? <li className={css.discountsListItem}>{discountMultipleDesc}</li> : null}
          </ul>
        </div> : null}
      </div>
    </div>
  )
}

export default Discount;
