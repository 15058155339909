import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;

  const formatPrice = price => new Money(price, config.currency);

  const numberOfGuests = publicData?.numberOfGuests;
  const units = publicData?.optionsPrice?.units;

  const minStayOrUse = publicData?.optionsPrice?.minStayOrUse;
  const maxStayOrUse = publicData?.optionsPrice?.maxStayOrUse;

  const pricingModelType = publicData?.optionsPrice?.pricingModelType ?? null;
  const priceWeekendByPerson = publicData?.optionsPrice?.priceWeekendByPerson;
  const priceWeekendPerUnit = publicData?.optionsPrice?.priceWeekendPerUnit;

  const discountKids = publicData?.discountOptions?.discountKids;
  const discountGroup = publicData?.discountOptions?.discountGroup;
  const discountGroupPeople = publicData?.discountOptions?.discountGroupPeople;

  const discountDuration = publicData?.discountOptions?.discountDuration;
  const discountDurationDays = publicData?.discountOptions?.discountDurationDays;
  const discountMultiple = publicData?.discountOptions?.discountMultiple;
  const discountMultipleItems = publicData?.discountOptions?.discountMultipleItems;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );
  const availability = currentListing.attributes?.availabilityPlan ?? null;

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{
        priceWeekdayPerUnit: pricingModelType === 'perUnit' && price,
        priceWeekdayByPerson: pricingModelType === 'perPerson' && price,
        numberOfGuests: numberOfGuests ? numberOfGuests.toString() : null,
        units,
        minStayOrUse,
        maxStayOrUse,
        pricingModelType: pricingModelType
          ? pricingModelType
          : publicData.mainCategory === 'rent'
          ? 'perUnit'
          : 'perPerson',
        priceWeekendPerUnit: priceWeekendPerUnit ? formatPrice(priceWeekendPerUnit) : null,
        priceWeekendByPerson: priceWeekendByPerson ? formatPrice(priceWeekendByPerson) : null,

        discountKids,
        discountGroup,
        discountGroupPeople,
        discountDuration,
        discountDurationDays,
        discountMultiple,
        discountMultipleItems,
      }}
      onSubmit={values => {
        const {
          priceWeekdayByPerson,
          priceWeekdayPerUnit,
          numberOfGuests,
          units,
          minStayOrUse,
          maxStayOrUse,
          pricingModelType,
          priceWeekendByPerson,
          priceWeekendPerUnit,

          discountKids,
          discountGroup,
          discountGroupPeople,
          discountDuration,
          discountDurationDays,
          discountMultiple,
          discountMultipleItems,
        } = values;

        const updateValues = {
          price:
            pricingModelType && pricingModelType === 'perUnit'
              ? priceWeekdayPerUnit
              : priceWeekdayByPerson,
          publicData: {
            numberOfGuests: numberOfGuests ? +numberOfGuests : null,
            totalSeats: units && numberOfGuests ? +numberOfGuests * +units : null,
            optionsPrice: {
              units,
              minStayOrUse,
              maxStayOrUse,
              pricingModelType,
              priceWeekendByPerson:
                pricingModelType === 'perPerson' ? priceWeekendByPerson?.amount : null,
              priceWeekendPerUnit:
                pricingModelType === 'perUnit' ? priceWeekendPerUnit?.amount : null,
            },
            discountOptions: {
              discountKids,
              discountGroup,
              discountGroupPeople,
              discountDuration,
              discountDurationDays,
              discountMultiple,
              discountMultipleItems,
            },
          },
        };

        if (availability) {
          const newAvailability = {
            ...availability,
            entries: availability.entries.map(entry => ({ ...entry, seats: units })),
          }

          updateValues.availabilityPlan = newAvailability;
        }

        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      publicData={publicData}
      pricingModelTypeSelected={publicData?.optionsPrice?.pricingModelType}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
