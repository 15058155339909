/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_DAY,
} from '../../util/types';
import { daysBetween, nightsBetween } from '../../util/dates';

import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemUnitsMaybe from './LineItemUnitsMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import LineItemKidsDiscountMaybe from './LineItemKidsDiscountMaybe';
import LineItemLongstayDiscountMaybe from './LineItemLongstayDiscountMaybe';
import LineItemGroupDiscountMaybe from './LineItemGroupDiscountMaybe';
import LineItemMultipleItemsMaybe from './LineItemMultipleItemsMaybe';
import LineItemAdditionalServicesMaybe from './LineItemAdditionalServicesMaybe';
import LineItenTotalPriceWithoutDiscountsMaybe from './LineItenTotalPriceWithoutDiscountsMaybe';

import css from './BookingBreakdown.module.css';

export const calculateDiscounts = ({
  listing,
  totalAmount,
  amountWithDiscount,
  totalAdditionServicesAmount,
  guests,
  resultUnits,
  bookingDays,
}) => {
  const totalGuests = (guests.kids ?? 0) + (guests.adults ?? 0);
  let resultAmount = totalAmount;
  let durationDiscount;
  let groupDiscount;
  let multipleDiscount;
  let kidsDiscount;
  const {
    discountKids,
    discountDuration,
    discountDurationDays,
    discountGroup,
    discountGroupPeople,
    discountMultiple,
    discountMultipleItems,
  } = listing?.attributes?.publicData?.discountOptions ?? {};

  if (discountDurationDays && +bookingDays >= +discountDurationDays) {
    durationDiscount = (resultAmount * discountDuration) / 100;
    resultAmount = resultAmount - durationDiscount;
  }
  if (discountGroupPeople && totalGuests >= +discountGroupPeople) {
    groupDiscount = (resultAmount * discountGroup) / 100;
    resultAmount = resultAmount - groupDiscount;
  }
  if (discountMultipleItems && resultUnits >= +discountMultipleItems) {
    multipleDiscount = (resultAmount * discountMultiple) / 100;
    resultAmount = resultAmount - durationDiscount;
  }
  if (discountKids && guests.kids > 0) {
    const totalDiscount = totalAmount - amountWithDiscount + totalAdditionServicesAmount;
    kidsDiscount =
      totalDiscount - (durationDiscount ?? 0) - (groupDiscount ?? 0) - (multipleDiscount ?? 0);
  }

  return { durationDiscount, multipleDiscount, groupDiscount, kidsDiscount };
};

export const BookingBreakdownComponent = ({
  rootClassName,
  className,
  userRole,
  unitType,
  transaction,
  booking,
  intl,
  dateType,
  listing,
  guests,
  additionServices,
  totalPriceLineItems,
}) => {
  const numberOfGuests = listing?.attributes?.publicData?.numberOfGuests ?? 0;
  const resultUnits = Math.ceil(((guests.adults ?? 0) + (guests.kids ?? 0)) / numberOfGuests);
  const { start, end } = booking.attributes;
  const bookingDays = unitType === LINE_ITEM_DAY ? daysBetween(start, end) : nightsBetween(start, end);
  const amountWithDiscount = transaction.attributes.payinTotal.amount || transaction.attributes?.protectedData?.payinTotal || 0;
  const amount = totalPriceLineItems?.[0].lineTotal.amount ?? 0;
  const totalAdditionServicesAmount = additionServices.reduce(
    (total, { pricePerDay, pricePerItem }) => {
      return total + (pricePerDay ? +pricePerDay * 100 * bookingDays : +pricePerItem * 100);
    },
    0
  );

  const params = {
    listing,
    totalAmount: amount,
    amountWithDiscount,
    totalAdditionServicesAmount,
    guests,
    resultUnits,
    bookingDays,
  };
  const { durationDiscount, groupDiscount, multipleDiscount, kidsDiscount } = calculateDiscounts(
    params
  );

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const hasCommissionLineItem = transaction.attributes.lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const classes = classNames(rootClassName || css.root, className);

  /**
   * BookingBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
   * quantity of the unit
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
   * This line item is not used by default in the BookingBreakdown.
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by FTW doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  return (
    <div className={classes}>
      <LineItemBookingPeriod booking={booking} unitType={unitType} dateType={dateType} />
      <LineItemUnitsMaybe transaction={transaction} unitType={unitType} />

      <LineItemBasePriceMaybe
        transaction={transaction}
        unitType={unitType}
        intl={intl}
        guests={guests}
        listing={listing}
        booking={booking}
      />
      <LineItemUnknownItemsMaybe transaction={transaction} isProvider={isProvider} intl={intl} />

      {kidsDiscount ? (
        <LineItemKidsDiscountMaybe
          listing={listing}
          kids={guests.kids}
          kidsDiscount={kidsDiscount}
          intl={intl}
        />
      ) : null}
      {totalPriceLineItems && durationDiscount ? (
        <LineItemLongstayDiscountMaybe
          listing={listing}
          intl={intl}
          quantity={bookingDays}
          durationDiscount={durationDiscount}
        />
      ) : null}
      {totalPriceLineItems && groupDiscount ? (
        <LineItemGroupDiscountMaybe
          listing={listing}
          guests={guests}
          intl={intl}
          groupDiscount={groupDiscount}
        />
      ) : null}
      {totalPriceLineItems && multipleDiscount ? (
        <LineItemMultipleItemsMaybe
          listing={listing}
          guests={guests}
          intl={intl}
          multipleDiscount={multipleDiscount}
        />
      ) : null}

      {totalPriceLineItems ? (
        <LineItenTotalPriceWithoutDiscountsMaybe
          transaction={transaction}
          totalPriceLineItems={totalPriceLineItems}
          intl={intl}
          totalAdditionServicesAmount={totalAdditionServicesAmount}
        />
      ) : null}

      <LineItemAdditionalServicesMaybe
        listing={listing}
        services={additionServices}
        bookingDays={bookingDays}
        intl={intl}
      />

      <LineItemSubTotalMaybe
        transaction={transaction}
        unitType={unitType}
        userRole={userRole}
        intl={intl}
      />
       <LineItemCustomerCommissionMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />
      <LineItemCustomerCommissionRefundMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />

      <LineItemProviderCommissionMaybe
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
      />
      <LineItemProviderCommissionRefundMaybe
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
      />
      <LineItemRefundMaybe transaction={transaction} intl={intl} />

      <LineItemTotalPrice transaction={transaction} isProvider={isProvider} intl={intl} />

      {hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="BookingBreakdown.commissionFeeNote" />
        </span>
      ) : null}
    </div>
  );
};

BookingBreakdownComponent.defaultProps = { rootClassName: null, className: null, dateType: null };

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: oneOf([propTypes.bookingUnitTypeDay, propTypes.bookingUnitTypeNight]).isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdown = injectIntl(BookingBreakdownComponent);

BookingBreakdown.displayName = 'BookingBreakdown';

export default BookingBreakdown;
