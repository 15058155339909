import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconTrash.module.css';

const IconTrash = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect
        width="15"
        height="18"
        x="4.5"
        y="5.1"
        stroke="#ED6F6F"
        strokeWidth="1.5"
        rx="1.5"
      ></rect>
      <rect
        width="19.5"
        height="3"
        x="2.25"
        y="2.1"
        stroke="#ED6F6F"
        strokeLinejoin="round"
        strokeWidth="1.5"
        rx="0.75"
      ></rect>
      <path
        stroke="#ED6F6F"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M9 .9h6M12 9v10.5M15.75 9v10.5M8.25 9v10.5"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconTrash.defaultProps = {
  className: null,
  rootClassName: null,
};

IconTrash.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconTrash;
