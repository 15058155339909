import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators, requiredFieldArrayCheckbox } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect, FieldCheckboxGroup, FieldRadioButton } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import config from '../../config';
import arrayMutators from 'final-form-arrays';
import { languagesHelper as language } from '../../helpers/languages';
import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        mainCategories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        mainCategorySelected
      } = formRenderProps;

      const sleepMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.sleep'
      });
      const rentMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.rent'
      });
      const activitiesMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.activities'
      });

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });



      const categoryMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.categotyLabel',
      });
      const categoryPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.categotyPlaceholder',
      });
      // const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      // const descriptionRequiredMessage = intl.formatMessage({
      //   id: 'EditListingDescriptionForm.descriptionRequired',
      // });


      const typeMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.typeLabel',
      });
      const typePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.typePlaceholder',
      });
      // const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      // const descriptionRequiredMessage = intl.formatMessage({
      //   id: 'EditListingDescriptionForm.descriptionRequired',
      // });


      const hostLanguageMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.hostLanguage'
      });

      const otherActivitiesDescriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.otherActivitiesDescription'
      });

      const requiredField = required(intl.formatMessage({ id: 'EditListingDescriptionForm.categoryRequired' }));


      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;


      const {
        categoriesForSleep,
        categoriesForRent,
        categoriesForActivities,
        typesOptions,
        CAT_SLEEP,
        CAT_RENT,
        CAT_ACTIVITIES
      } = config.custom

      const categoriesOptions = values?.mainCategory === CAT_RENT ? categoriesForRent :
        values?.mainCategory === CAT_ACTIVITIES ? categoriesForActivities : categoriesForSleep

      const hostLanguageOptions = config.custom.hostLanguageOptions;


      // const catSleepSelected = categoriesForSleep.find(cat => cat.key === values?.category)
      // const catRentSelected = categoriesForRent.find(cat => cat.key === values?.category)
      // const catActivitiesSelected = categoriesForActivities.find(cat => cat.key === values?.category)


      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress
      // || (values?.mainCategory === CAT_RENT && !catRentSelected) 
      // || (values?.mainCategory === CAT_ACTIVITIES && !catActivitiesSelected) 
      // || (values?.mainCategory === CAT_SLEEP && !catSleepSelected);


      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <div className={classNames(css.radioButtonRow, { [css.radioButtonRowBlocked]: mainCategorySelected })}>
            {mainCategories.map(cat => {
              return (
                <FieldRadioButton
                  key={cat.key}
                  id={cat.key}
                  name="mainCategory"
                  label={language.labelsTranslator(cat.key, intl)}
                  value={cat.key}
                  className={css.radioButtonItem}
                />
              )
            })}

          </div>

          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          // autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <FieldSelect
            className={css.category}
            name="category"
            id="category"
            label={categoryMessage}
            validate={requiredField}
          >
            <option disabled value="">
              {categoryPlaceholderMessage}
            </option>
            {categoriesOptions.map(c => (
              <option key={c.key} value={c.key}>
                {language.labelsTranslator(c.key, intl)}
              </option>
            ))}
          </FieldSelect>

          {values?.mainCategory === CAT_ACTIVITIES && values?.category === "otherCatA" && (
            <FieldTextInput
              id="activitiesOtherdesc"
              name="activitiesOtherdesc"
              className={css.description}
              type="textarea"
              label={otherActivitiesDescriptionMessage}
              placeholder={descriptionPlaceholderMessage}
            // validate={requiredField}
            />
          )}


          {values?.mainCategory === CAT_SLEEP && (
            <FieldSelect
              className={css.category}
              name="type"
              id="type"
              label={typeMessage}
              validate={requiredField}
            >
              <option disabled value="">
                {typePlaceholderMessage}
              </option>
              {typesOptions.map(c => (
                <option key={c.key} value={c.key}>
                  {language.labelsTranslator(c.key, intl)}
                </option>
              ))}
            </FieldSelect>
          )}


          {/* <CustomCategorySelectFieldMaybe
            id="category"
            name="category"
            categories={categories}
            intl={intl}
          /> */}


          <FieldCheckboxGroup
            label={hostLanguageMessage}
            className={css.features}
            id="hostLanguage"
            name="hostLanguage"
            options={hostLanguageOptions}
            twoColumns
            validate={requiredFieldArrayCheckbox(intl.formatMessage({ id: 'EditListingDescriptionForm.categoryRequired' }))}
            intl={intl}
            withTranslations
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
