import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './BookingBreakdown.module.css';

const LineItemGroupDiscountMaybe = ({
  listing,
  guests,
  intl,
  groupDiscount,
}) => {
  const {
    discountGroupPeople,
  } = listing?.attributes?.publicData?.discountOptions ?? {};
  const { currency } = listing.attributes.price;

  const totalGuests = guests.kids + guests.adults;

  if (discountGroupPeople && totalGuests < +discountGroupPeople) {
    return null;
  }

  const formattedPrice = formatMoney(intl, new Money(groupDiscount, currency));

  return (
    <div className={css.lineItemTotal}>
      <div className={css.totalLabel}>
        <FormattedMessage id="BookingBreakdown.groupDiscountLabel" />
      </div>
      <div className={css.totalPrice}>-{formattedPrice}</div>
    </div>
  )
}

LineItemGroupDiscountMaybe.propTypes = {
  listing: propTypes.listing.isRequired,
  guests: object.isRequired,
};

export default LineItemGroupDiscountMaybe;
