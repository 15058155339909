import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ContentWithShowMore.module.css';

const ContentWithShowMore = props => {
  const { className, rootClassName, buttonClassName, content, contentLimit, showDots } = props;
  const classes = classNames(rootClassName || css.root, className);

  const [showFullContent, setShowFullContent] = useState(false)

  const showAllContent = () => {
    setShowFullContent(true)
  }
  const hideContent = () => {
    setShowFullContent(false)
  }

  return (
    <>
      {showFullContent ? content : content.slice(0, contentLimit)}
      {content?.length > contentLimit && showDots && !showFullContent && <span className={css.dots}>...</span>}

      {content?.length > contentLimit && (
        <span>
          {!showFullContent ? (
            <span className={classNames(css.button, buttonClassName)} onClick={() => showAllContent()}>
              <FormattedMessage id="ContentWithShowMore.more" />
            </span>
          )
           : (
            <span className={classNames(css.button, css.buttonLess, buttonClassName)} onClick={() => hideContent()}>
              <FormattedMessage id="ContentWithShowMore.less" />
            </span>
          )
          }
        </span>
      )}

    </>

  );
};

const { string } = PropTypes;

ContentWithShowMore.defaultProps = {
  className: null,
  rootClassName: null,
};

ContentWithShowMore.propTypes = {
  className: string,
  rootClassName: string,
};

export default ContentWithShowMore;
