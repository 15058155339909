import React, { useEffect, useState } from 'react';
import { func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { FieldCurrencyInput, IconPlus, IconClose, IconTrash, ServicesIcons, IconServices } from '..';
import { Form as FinalForm, FormSpy, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './FieldServicesItems.module.css';

const { Money } = sdkTypes;

const fieldsArrayName = 'options'
const selectedName = 'selected'
const titleName = 'title'

const createName = (name, type) => `${name}.${type}`;

const FieldRow = props => {
  const { fields, name, intl, index, twoColums, checkServicesStatus } = props

  const deleteService = (e) => {

    fields.remove(index)

    if (index === 0 && fields?.length === 1) {
      checkServicesStatus()
    }
  }

  let title = fields.value[index].title
  // let selected = fields.value[index].selected
  let other = fields.value[index].other
  let icon = fields.value[index].icon
  let priceValue = fields.value[index].pricePerDay || fields.value[index].pricePerItem || 0


  let pricePerDayLabel = fields.value[index].pricePerDay;
  let pricePerItemLabel = fields.value[index].pricePerItem;

  const priceMoney = new Money(+priceValue * 100, config.currency)


  const cName = createName(name, selectedName);
  const tName = createName(name, titleName);

  return (
    <div className={classNames(css.services, { [css.servicesTwoColums]: twoColums })}>
      <div className={css.servicesTitle}>

        <span className={css.servicesTitleHolder}>
          <IconServices servicesType={icon} />
          <span>{title}</span>
        </span>

        <span className={css.servicesPriceHolder}>
          {formatMoney(intl, priceMoney)}
          {pricePerDayLabel && <FormattedMessage id="EditListingAdditionalServicesForm.perDay" />}
          {pricePerItemLabel && <FormattedMessage id="EditListingAdditionalServicesForm.perItem" />}
          <Field name={tName} type={'hidden'}
            component={props => <input {...props} type={"hidden"} />} />
        </span>
        {/* <FieldCheckbox
          className={css.checkbox}
          id={cName}
          name={cName}
          label={title}
          value={selectedName}
        /> */}

      </div>
      <div className={css.servicesAction}>
        {other ?
          <div onClick={deleteService} className={css.blockAddServiceIcon}>
            <span className={css.spanDelete}><IconTrash /></span>
          </div>
          : null
        }
      </div>
    </div>
  );
}

const IconRadioButton = props => {
  return (
    <svg className={props.className} width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <circle
        className={props.showAsRequired ? css.required : css.notChecked}
        cx="11"
        cy="11"
        r="10.5"
        fill="#fff"
        stroke="#E4E4E4">
      </circle>

      <g className={css.checked} transform="translate(0 0)" fill="none" fillRule="evenodd">
        <circle cx="11" cy="11" r="10.5" fill="#fff" stroke="#2F4E1E"></circle>
        <circle cx="11" cy="11" r="7" fill="#2F4E1E"></circle>
      </g>
    </svg>
  );
};

const FieldServicesItemsComponent = props => {
  const {
    rootClassName,
    className,
    inputRootClass,
    id,
    label,
    placeholder,
    pricePlaceholder,
    meta,
    form,
    intl,
    input,
    optional,
    twoColums,
    checkServicesStatus,
    ...rest
  } = props;

  const [addList, setAddList] = useState(false)
  const [value, setValue] = useState('')
  const [icon, setIcon] = useState('')

  const [pricePerDaySelected, setPricePerDaySelected] = useState(true)

  const [pricePerDay, setPricePerDay] = useState('')
  const [pricePerItem, setPricePerItem] = useState('')

  const [error, setError] = useState(false)
  const [initialValues, setInitialValues] = useState(null);
  // const [invalidValue, setInvalidValue] = useState(false)

  const inputHandle = e => {
    setError(false)
    setValue(e.target.value)
  }
  const iconHandle = e => {
    setError(false)
    setIcon(e.target.value)
  }

  const onChangePriceType = status => {
    setPricePerDaySelected(status)

    if (status) {
      setPricePerItem('')
    } else {
      setPricePerDay('')
    }
  }

  const pricePerDayHandle = e => {
    // e.target.value.replace(/[^\d]/g, '')
    e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

    if (e.target.value?.length > 7 || +e.target.value <= 0) {
      e.target.value = null
      setError(true)
    } else {
      setError(false)
      setPricePerDay(e.target.value)
    }

  }
  const pricePerItemHandle = e => {
    e.target.value.replace(/[^\d]/g, '')
    if (e.target.value?.length > 7 || +e.target.value <= 0) {
      setError(true)
      e.target.value = null
    } else {
      setError(false)
      setPricePerItem(e.target.value)
    }
  }

  const saveNewService = fields => {
    const title = value.trim();
    
    if (title) {
      let val = fields?.value?.map(el => el.title)
      if (!val.includes(title)) {
        fields.update(fields.value.length + 1, {
          selected: true,
          title: title,
          other: true,
          icon: icon || "other",
          pricePerDay: pricePerDay,
          pricePerItem: pricePerItem,
        })
        setError(false)
        setValue('');
        // setIcon('');
        setPricePerDay('');
        setPricePerItem('');
        setAddList(false);
      } else setError(true)
    }
  }

  const delNewService = () => {
    setError(false)
    setValue('')
    setAddList(false)
  }
  const addNewService = () => {
    if (!addList) setAddList(true)
  }
  const createInitialValues = value => {
    const values = value.map(({ selected, title, other, icon, pricePerDay, pricePerItem }) => {
      return (
        {
          selected: true,
          title: title,
          other: other,
          icon: icon || "other",
          pricePerDay: pricePerDay,
          pricePerItem: pricePerItem,
        }
      )
    }, []
    )

    return { [fieldsArrayName]: values }
  }

  const createStructure = values => {
    const optionValues = values[fieldsArrayName];
    if (!optionValues || !optionValues.length) return []

    const value = optionValues.map(({ selected, title, other, icon, pricePerDay, pricePerItem }) => {

      return (
        {
          selected: true,
          title: title,
          other: other,
          icon: icon || "other",
          pricePerDay: pricePerDay,
          pricePerItem: pricePerItem,
        }
      )
    }
    )

    if (value.length) {
      form.change(input.name, value);
    }
  }

  const handleChange = ({ values }) => {
    createStructure(values);
  }

  useEffect(() => {
    if (input && input.value && !initialValues) {
      setInitialValues(createInitialValues(input.value))
    }
  }, [input])

  const { value: defaultValue, ...inputWithoutValue } = input;

  const inputHiddenProps = {
    ...inputWithoutValue,
    ...rest,
  };

  const classes = classNames(rootClassName || css.root, className);

  const errorMessageAddService = error ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingAdditionalServicesForm.duplicateFailed" />
    </p>
  ) : null;

  return (
    <div className={classes}>

      <FinalForm
        onSubmit={() => {
        }}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ form, values }) => {
          return (
            <div className={css.wrapper}>
              <FormSpy onChange={values => handleChange(values)} />
              <FieldArray name={'options'}>
                {({ fields }) => (
                  <>

                    {/* <p onClick={addNewService} className={css.buttonAddService}>
                      <FormattedMessage id="EditListingPricingForm.addCharge" />
                    </p> */}

                    <ServicesIcons iconHandle={iconHandle} selectedIcon={icon} intl={intl} />
                    {label &&
                      <label>
                        {label}
                        {/* {optional && (
                          <span className={css.optionalLabel}>
                            <FormattedMessage id="EditListingPricingForm.extraChargeOptional" />
                          </span>
                        )} */}
                      </label>}

                    <div className={css.wrapperInputBlock}>
                      <input
                        className={css.inputNewService}
                        type="text"
                        value={value}
                        onChange={inputHandle}
                        placeholder={placeholder}
                      />
                      <div className={css.wrapperInputPrice}>

                        <div className={css.priceWrapper}>
                          <div className={classNames(css.priceHolder, { [css.priceHolderActive]: pricePerDaySelected })}>
                            <div onClick={() => onChangePriceType(true)}>
                              <IconRadioButton />
                            </div>
                            <label>
                              <FormattedMessage id="EditListingAdditionalServicesForm.pricePerDay" />
                              <input
                                className={css.inputNewService}
                                type="text"
                                value={!error ? pricePerDay : null}
                                onChange={pricePerDayHandle}
                                placeholder={pricePlaceholder}
                                // min={0}
                                // max={1000000}
                                // step={0.01}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </label>
                          </div>
                          <div className={classNames(css.priceHolder, { [css.priceHolderActive]: !pricePerDaySelected })}>
                            <div onClick={() => onChangePriceType(false)}>
                              <IconRadioButton />
                            </div>
                            <label>
                              <FormattedMessage id="EditListingAdditionalServicesForm.pricePerItem" />
                              <input
                                className={css.inputNewService}
                                type="number"
                                value={!error ? pricePerItem : null}
                                onChange={pricePerItemHandle}
                                placeholder={pricePlaceholder}
                                // min={0}
                                // max={1000000}
                                // step={0.01}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </label>
                          </div>
                        </div>


                        <div
                          onClick={() => saveNewService(fields)}
                          className={classNames(css.blockAddServiceIcon, { [css.blockAddServiceIconDisables]: (!pricePerDay && !pricePerItem) || error || !value })}
                        >
                          <span className={css.spanAdd}>
                            <FormattedMessage id="EditListingAdditionalServicesForm.addService" />
                          </span>
                        </div>
                      </div>
                    </div>
                    {errorMessageAddService}

                    <div className={css.servicesHolder}>
                      {fields.map((name, i) => (
                        <FieldRow
                          key={name}
                          fields={fields}
                          index={i}
                          name={name}
                          intl={intl}
                          twoColums={twoColums}
                          checkServicesStatus={checkServicesStatus}
                        />
                      ))}
                    </div>
                  </>
                )}
              </FieldArray>
            </div>
          );
        }}
      />
      <input {...inputHiddenProps} />
    </div>
  );
}

FieldServicesItemsComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  id: null,
  label: null,
};

FieldServicesItemsComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
  }).isRequired,
  meta: object.isRequired,
  form: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const FieldServicesItems = props => {
  return <Field component={FieldServicesItemsComponent} {...props} type="hidden" />;
}

export default compose(injectIntl)(FieldServicesItems);
