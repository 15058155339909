import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import {
  FieldRadioButton
} from '../../components';
import { languagesHelper as language } from '../../helpers/languages';
import css from './ServicesIcons.module.css';

const ServicesIcons = props => {
  const { rootClassName, className, iconHandle, selectedIcon, intl } = props;
  const classes = classNames(rootClassName || css.root, className, {[css.lastIconActive]: !selectedIcon || selectedIcon === "other"});

  const { servicesIcons } = config.custom

  return (
    <div className={classes}>
      <h4>
        <FormattedMessage id="EditListingAdditionalServicesForm.chooseIcon" />
      </h4>

      <div className={css.services}>
        {servicesIcons.map(s => {
          return (
            <FieldRadioButton
              key={s.key}
              id={s.key}
              name="servicesIcon"
              label={language.labelsTranslator(s.key, intl)}
              value={s.key}
              className={css.servicesItem}
              servicesIcon={s.key}
              servicesIconClass={css.servicesIcon}
              onClick={iconHandle}
            />)
        })}

      </div>
    </div>

  );
};

ServicesIcons.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ServicesIcons.propTypes = {
  rootClassName: string,
  className: string,
};

export default ServicesIcons;
