import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewStar.module.css';

const IconReviewStar = props => {
  const { className, rootClassName, isFilled } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="21"
      fill="none"
      viewBox="0 0 23 21"
    >
      <path
        // fill="#ccc"
        d="M11.5.438L8.23 7.064.92 8.121l5.29 5.16-1.25 7.281 6.54-3.435 6.54 3.436-1.25-7.281 5.29-5.154-7.31-1.064L11.5.437z"
      ></path>
    </svg>
  );
};

IconReviewStar.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconReviewStar.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconReviewStar;
