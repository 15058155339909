import React from 'react';
import { oneOf } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './BookingBreakdown.module.css';

const countDays = (startDate, endDate, unitType) => {
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const weekends = isNightly ? [5, 6] : [6, 0];
  let weekendCount = 0;
  let weekdayCount = 0;

  let start = new Date(startDate);
  let end = new Date(endDate);

  while (start < end) {
    if (weekends.includes(start.getDay())) {
      weekendCount++;
    } else {
      weekdayCount++;
    }
    start.setDate(start.getDate() + 1);
  }

  return [weekdayCount, weekendCount];
}

const LineItemBasePriceMaybe = ({ transaction, unitType, intl, guests, listing, booking }) => {
  const { start, end } = booking.attributes;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.baseUnitNight'
    : isDaily
    ? 'BookingBreakdown.baseUnitDay'
    : 'BookingBreakdown.baseUnitQuantity';

  const [weekdayCount, weekendCount] = countDays(start, end, unitType);
  const price = listing.attributes.price;
  const numberOfGuests = listing.attributes.publicData.numberOfGuests ?? 1;
  const { priceWeekendPerUnit, priceWeekendByPerson, pricingModelType } = listing.attributes.publicData.optionsPrice;

  const isPerPerson = pricingModelType === 'perPerson';

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  let quantity;
  let unitPrice;
  let total;
  let mixed = null;
  let unitPriceWeekend;
  let priceAsNum;

  if (weekdayCount === 0) {
    const amount = priceWeekendPerUnit ?? priceWeekendByPerson ?? price.amount;
    unitPrice = formatMoney(
      intl, new Money(amount, price.currency)
    );
    const totalPrice = amount * weekendCount;
    quantity = weekendCount;
    total = formatMoney(intl, new Money(totalPrice, price.currency));
    priceAsNum = totalPrice;
  } else if (weekendCount === 0) {
    unitPrice = formatMoney(intl, price);
    const totalPrice = price.amount * weekdayCount;
    quantity = weekdayCount;
    total = formatMoney(intl, new Money(totalPrice, price.currency));
    priceAsNum = totalPrice;
  } else {
    mixed = true;
    const weekendAmount = priceWeekendPerUnit ?? priceWeekendByPerson ?? price.amount;
    unitPrice = formatMoney(intl, price);
    unitPriceWeekend = formatMoney(intl, new Money(weekendAmount, price.currency));
    const totalPrice = weekendAmount * weekendCount + price.amount * weekdayCount;
    quantity = weekdayCount + weekendCount;
    total = formatMoney(intl, new Money(totalPrice, price.currency));
    priceAsNum = totalPrice;
  }

  const totalPeople = (guests.adults ?? 0) + (guests.kids ?? 0);
  const units = Math.ceil(totalPeople / numberOfGuests) || 1;

  return unitPurchase ? (
    <>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          {mixed ?
            <FormattedMessage
              id={translationKey + "Mixed"}
              values={{ weekdayCount, weekendCount, weekendAmount: unitPriceWeekend, weekdayAmount: unitPrice }}
            /> :
            <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
          }
        </span>
        <span className={css.itemValue}>{total}</span>
      </div>
      <div>
        {totalPeople ? (
          <FormattedMessage id="BookingBreakdown.totalPeople" values={{ count: totalPeople }} />
        ) : null}
      </div>
      <div className={css.lineItem}>
        <FormattedMessage id="BookingBreakdown.regularPrice" />
          <span className={css.itemValue}>{
            formatMoney(
              intl, new Money(isPerPerson ? (totalPeople * priceAsNum) : (units * priceAsNum), price.currency)
            )
          }</span>
      </div>
    </>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  // unitType: propTypes.bookingUnitType.isRequired,
  unitType: oneOf([propTypes.bookingUnitTypeDay, propTypes.bookingUnitTypeNight]).isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
