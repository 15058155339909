import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';

import css from './IconCategories.module.css';

const IconCategories = props => {
  const { rootClassName, className, categoryIcon } = props;

  const { CAT_SLEEP, CAT_RENT, CAT_ACTIVITIES } = config.custom
  const classes = classNames(rootClassName || css.root, className, {[css.rootRent]: categoryIcon === CAT_RENT});

  if (categoryIcon === CAT_SLEEP) {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="21"
        fill="none"
        viewBox="0 0 35 21"
      >
        <path
          fill="#4B4A4A"
          d="M34.73 16.98a.488.488 0 00-.683.099L32.612 19 27.21 9.368a.487.487 0 00-.236-.211L13.188 3.353l1.244-2.052a.488.488 0 00-.834-.505L12.522 2.57l-.866-1.74a.488.488 0 10-.873.435l1.14 2.292-.11.183-3.083 5.01-6.323 10.275L.953 17.08a.488.488 0 10-.782.584l1.884 2.522a.474.474 0 00.087.09l.032.023.018.012a.485.485 0 00.134.056l.027.005c.03.006.061.01.093.01H32.56a.49.49 0 00.38-.191l.004-.005 1.883-2.522a.488.488 0 00-.098-.683zm-16.278 2.426c-4.612-3.467-5.859-9.511-5.871-9.572a.487.487 0 00-.954-.017c-1.211 5.17-5.51 8.93-6.304 9.589H3.319l9.128-14.834 7.76 14.834h-1.755zm3.533 0l1.302-1.743a.488.488 0 00-.781-.584l-1.42 1.901-7.543-14.419 12.897 5.43 5.28 9.415h-9.735z"
        ></path>
      </svg>
    );
  } else if (categoryIcon === CAT_RENT) {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="21"
        fill="none"
        viewBox="0 0 31 21"
      >
        <path
          stroke="#4B4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M6.64 20a5.64 5.64 0 100-11.28 5.64 5.64 0 000 11.28zM24.8 20a5.64 5.64 0 100-11.28 5.64 5.64 0 000 11.28z"
        ></path>
        <path
          stroke="#4B4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M24.801 14.36l-1.75-8.275H11.124L6.5 14.36h5.78l-1.156-8.275M11.124 6.085l-.292-2.098"
        ></path>
        <path
          stroke="#4B4A4A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M23.422 7.845l-1.105-5.22L19.161 1M8.258 3.987h5.64M12.28 14.36l10.77-8.275"
        ></path>
      </svg>
    );
  } else if (categoryIcon === CAT_ACTIVITIES) {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="26"
        fill="none"
        viewBox="0 0 22 26"
      >
        <g fill="#4B4A4A" clipPath="url(#clip0_195_405)">
          <path d="M10.875 26C5.64 26 0 25.083 0 23.08c0-2.002 5.64-2.923 10.875-2.923s10.88.916 10.88 2.924c0 2.007-5.64 2.919-10.88 2.919zm0-4.927c-6.459 0-9.985 1.325-9.985 2.008 0 .682 3.526 2.002 9.985 2.002 6.46 0 9.99-1.324 9.99-2.002 0-.679-3.526-2.008-9.99-2.008z"></path>
          <path d="M12.166 23.177a1.201 1.201 0 01-.687-.228 1.256 1.256 0 01-.443-.588c-.183-.417-.89-2.434-1.167-3.208H6.891c-.79.07-1.58-.14-2.24-.593a3.585 3.585 0 01-1.388-1.905 17.964 17.964 0 00-.841-2.397 4.818 4.818 0 01-.445-1.375 1.706 1.706 0 01.334-1.324c.201-.21.445-.373.714-.475.27-.102.558-.142.844-.117 1.682 0 2.292.097 2.844 2.021.36 1.27.78 2.705.962 3.342l2.44-.087c.244-.032 1.335-.087 1.99 1.375.322.752.594 1.527.814 2.319.129.421.236.788.356 1.086.174.362.201.78.077 1.164-.124.384-.39.702-.74.884-.14.066-.292.101-.446.106zm-1.985-4.94h.312l.107.302c.356 1.026 1.077 3.066 1.242 3.45.102.235.262.322.445.248.182-.073.378-.417.195-.884-.129-.34-.249-.738-.378-1.16a17.06 17.06 0 00-.774-2.204c-.41-.917-.998-.848-1.064-.834h-.063l-3.147.11-.098-.335-1.064-3.666c-.392-1.375-.48-1.375-1.99-1.375-.32-.034-.64.065-.89.275a.757.757 0 00-.125.595c.07.387.196.761.374 1.11.357.811.655 1.65.89 2.506.566 1.994 2.645 1.866 2.734 1.861h3.294z"></path>
          <path d="M3.988 11.435c-.394 0-.78-.12-1.108-.347a2.042 2.042 0 01-.734-.922 2.11 2.11 0 01-.113-1.187c.077-.398.267-.764.547-1.05.28-.288.635-.483 1.022-.562a1.941 1.941 0 011.153.119c.364.156.675.42.894.758a2.097 2.097 0 01-.252 2.591c-.374.384-.88.6-1.409.6zm0-3.208c-.218 0-.431.066-.613.191a1.13 1.13 0 00-.406.51 1.168 1.168 0 00.239 1.239 1.074 1.074 0 001.203.246c.202-.086.374-.231.495-.418a1.16 1.16 0 00.186-.632 1.153 1.153 0 00-.33-.789 1.088 1.088 0 00-.774-.324v-.023zM6.117 12.42a.44.44 0 01-.33-.095.471.471 0 01-.076-.648.445.445 0 01.299-.173l1.847-.486 1.55-2.723a.446.446 0 01.27-.199.434.434 0 01.328.049.47.47 0 01.172.61L8.44 11.805l-2.226.582a.435.435 0 01-.097.032zM10.875 14.364H7.55l-1.46-.857a.47.47 0 01-.156-.614.447.447 0 01.268-.216.434.434 0 01.337.041l1.247.729h3.09c.117 0 .23.048.314.134a.465.465 0 010 .648.439.439 0 01-.315.135z"></path>
          <path d="M11.921 15.867a.435.435 0 01-.383-.22l-2.127-3.575a.47.47 0 01-.01-.321.456.456 0 01.196-.25.434.434 0 01.575.113l2.128 3.584a.47.47 0 01-.147.627.435.435 0 01-.232.042zM17.361 15.056a2.23 2.23 0 01-.988-.23l-.085-.05a2.8 2.8 0 01-1.006-1.787 2.454 2.454 0 01.178-1.02c.13-.322.328-.61.58-.846l.36-.376c1.384-1.416 2.582-2.644 1.46-7.91-1.241.29-2.458.678-3.642 1.16-.271.128-.565.297-.89.458-1.202.692-2.845 1.645-3.93-.046a2.15 2.15 0 01-.13-2.292C10.034.743 12.34-.137 14.61.023c3.463.238 5.979 2.37 7.083 5.995.859 2.837-.503 6.494-2.315 8.13a3.114 3.114 0 01-2.017.908zm-.565-1.036c.788.344 1.54-.115 2.048-.573 1.567-1.412 2.79-4.702 2.047-7.164a8.073 8.073 0 00-2.136-3.611c1.201 5.715-.214 7.168-1.714 8.708l-.352.367c-.16.142-.29.319-.379.517a1.575 1.575 0 00-.137.633c.068.44.29.839.623 1.123zM14.125.917c-1.78 0-3.562.7-4.087 1.654a1.206 1.206 0 00.103 1.334c.57.889 1.308.591 2.75-.248.317-.178.642-.37.949-.513a25.673 25.673 0 013.882-1.237 6.796 6.796 0 00-3.148-.972 4.094 4.094 0 00-.427-.018h-.022z"></path>
        </g>
        <defs>
          <clipPath id="clip0_195_405">
            <path fill="#fff" d="M0 0H22V26H0z"></path>
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return null
  }

};

IconCategories.defaultProps = {
  rootClassName: null,
  className: null,
};

IconCategories.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCategories;
