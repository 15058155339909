/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */


export const CAT_SLEEP = "sleep"
export const CAT_RENT = "rent"
export const CAT_ACTIVITIES = "activities"


export const mainCategoriesOptions = [
  {key: CAT_SLEEP, label: "Sleep", icon: CAT_SLEEP},
  {key: CAT_RENT, label: "Rent", icon: CAT_RENT},
  {key: CAT_ACTIVITIES, label:"Activities", icon: CAT_ACTIVITIES}
]

export const hostLanguageOptions = [
  {key: 'polish', label: "Polish"},
  {key: 'english', label: "English"},
  {key: 'ukrainian', label: "Ukrainian"},
  {key: 'german', label: "German"},
  {key: 'spanish', label: "Spanish"},
  {key: 'other', label: "Other"},
]

export const categoriesForSleep = [
  {key: 'placeForTentCatS', label: "Place for Tent"},
  {key: 'placeForCamperCaravanCatS', label: "Place for Camper/Caravan"},
  {key: 'tentCatS', label: "Tent"},
  {key: 'cabinCatS', label: "Cabin"},
  {key: 'yurtCatS', label: "Yurt"},
  {key: 'treehouseCatS', label: "Treehouse"},
  {key: 'roomCatS', label: "Room"},
]

export const categoriesForRent = [
  {key: 'bicycleRegularCatR', label: "Bicycle regular"},
  {key: 'electricBikeCatR', label: "Electric bike"},
  {key: 'mtbCatR', label: "MTB"},
  {key: 'mnbFullSuspensionFSCatR', label: "MTB Full suspension (FS)"},
  {key: 'mtbElectricFullSuspensionFSCatR', label: "MTB Electric Full Suspension (FS)"},
  {key: 'kayak1personCatR', label: "Kayak 1 person"},
  {key: 'KayakTwinCatR', label: "Kayak - twin"},
  {key: 'supCatR', label: "SUP"},
  {key: 'boatCatR', label: "Boat"},
  {key: 'pushbikeCatR', label: "Pushbike"},
  {key: 'electricPushbikeCatR', label: "Electric pushbike"},
  {key: 'motorbikeCatR', label: "Motorbike"},
  {key: 'carCatR', label: "Car"},
  {key: 'camperCatR', label: "Camper"},
  {key: 'tentCatR', label: "Tent"},
  {key: 'backpackCatR', label: "Backpack"},
  {key: 'campingEquipmentCatR', label: "Camping equipment"},
  {key: 'kiteboardCatR', label: "Kiteboard"},
  {key: 'windsurfingCatR', label: "Windsurfing"},
  {key: 'climbingGearCatR', label: "Climbing gear"},
  {key: 'hikingGearCatR', label: "Hiking gear"},
  {key: 'waterskiCatR', label: "Waterski"},
  {key: 'skisCatR', label: "Skis"},
  {key: 'snowboardCatR', label: "Snowboard"},
  {key: 'winterSportsCatR', label: "Winter sports"},
  {key: 'summerSportCatR', label: "Summer sport"},
  {key: 'waterSportCatR', label: "Water sport"},
]

export const categoriesForActivities = [
  {key: 'cityGuideCatA', label: "City guide"},
  {key: 'horseRidingCatA', label: "Horse riding"},
  {key: 'surfingSchoolCatA', label: "Surfing school"},
  {key: 'kitesurfingSchoolCatA', label: "Kitesurfing school"},
  {key: 'swimmingClassesCatA', label: "Swimming classes"},
  {key: 'outdoorCatA', label: "Outdoor"},
  {key: 'cityCatA', label: "City"},
  {key: 'summerSportCatA', label: "Summer Sport"},
  {key: 'winterSportCatA', label: "Winter Sport"},
  {key: 'funForKidsCatA', label: "Fun for Kids"},
  {key: 'educationalCatA', label: "Educational"},
  {key: 'familyCatA', label: "Family"},
  {key: 'sightseeingCatA', label: "Sightseeing"},
]

export const typesOptions = [
  {key: 'campsite', label: "Campsite"},
  {key: 'farm', label: "Farm"},
  {key: 'ecologicalFarm', label: "Ecological Farm"},
  {key: 'privateLand', label: "Private land"},
  {key: 'fieldMeadow', label: "Field/meadow"},
  {key: 'forrest', label: "Forrest"},
  {key: 'backyard', label: "Backyard"},
  {key: 'parking', label: "Parking"},
  {key: 'vineyard', label: "Vineyard"},
]

export const categoriesAllList = [
  {key: 'placeForTentCatS', label: "Place for Tent"},
  {key: 'placeForCamperCaravanCatS', label: "Place for Camper/Caravan"},
  {key: 'tentCatS', label: "Tent"},
  {key: 'cabinCatS', label: "Cabin"},
  {key: 'yurtCatS', label: "Yurt"},
  {key: 'treehouseCatS', label: "Treehouse"},
  {key: 'roomCatS', label: "Room"},
  {key: 'bicycleRegularCatR', label: "Bicycle regular"},
  {key: 'electricBikeCatR', label: "Electric bike"},
  {key: 'mtbCatR', label: "MTB"},
  {key: 'mnbFullSuspensionFSCatR', label: "MTB Full suspension (FS)"},
  {key: 'mtbElectricFullSuspensionFSCatR', label: "MTB Electric Full Suspension (FS)"},
  {key: 'kayak1personCatR', label: "Kayak 1 person"},
  {key: 'KayakTwinCatR', label: "Kayak - twin"},
  {key: 'supCatR', label: "SUP"},
  {key: 'boatCatR', label: "Boat"},
  {key: 'pushbikeCatR', label: "Pushbike"},
  {key: 'electricPushbikeCatR', label: "Electric pushbike"},
  {key: 'motorbikeCatR', label: "Motorbike"},
  {key: 'carCatR', label: "Car"},
  {key: 'camperCatR', label: "Camper"},
  {key: 'tentCatR', label: "Tent"},
  {key: 'backpackCatR', label: "Backpack"},
  {key: 'campingEquipmentCatR', label: "Camping equipment"},
  {key: 'kiteboardCatR', label: "Kiteboard"},
  {key: 'windsurfingCatR', label: "Windsurfing"},
  {key: 'climbingGearCatR', label: "Climbing gear"},
  {key: 'hikingGearCatR', label: "Hiking gear"},
  {key: 'waterskiCatR', label: "Waterski"},
  {key: 'skisCatR', label: "Skis"},
  {key: 'snowboardCatR', label: "Snowboard"},
  {key: 'winterSportsCatR', label: "Winter sports"},
  {key: 'summerSportCatR', label: "Summer sport"},
  {key: 'waterSportCatR', label: "Water sport"},
  {key: 'cityGuideCatA', label: "City guide"},
  {key: 'horseRidingCatA', label: "Horse riding"},
  {key: 'surfingSchoolCatA', label: "Surfing school"},
  {key: 'kitesurfingSchoolCatA', label: "Kitesurfing school"},
  {key: 'swimmingClassesCatA', label: "Swimming classes"},
  {key: 'outdoorCatA', label: "Outdoor"},
  {key: 'cityCatA', label: "City"},
  {key: 'summerSportCatA', label: "Summer Sport"},
  {key: 'winterSportCatA', label: "Winter Sport"},
  {key: 'funForKidsCatA', label: "Fun for Kids"},
  {key: 'educationalCatA', label: "Educational"},
  {key: 'familyCatA', label: "Family"},
  {key: 'sightseeingCatA', label: "Sightseeing"},
]

export const standartAmenitiesOptions = [
  {key: 'toilet', label: "Toilet"},
  {key: 'bathroom', label: "Bathroom"},
  {key: 'petsAllowed', label: "Pets allowed"},
  {key: 'campfireGrill', label: "Campfire/grill"},
  {key: 'shower', label: "Shower"},
  {key: 'tabWater', label: "Tab Water"},
  {key: 'trashBins', label: "Trash/Bins"},
  {key: 'table', label: "Table"},
  {key: 'electricity', label: "Electricity"},
  {key: 'wheelchairAccess', label: "Wheelchair access"},
]

export const extrasAmenitiesOptions = [
  {key: 'wiFi', label: "WiFi"},
  {key: 'shelter', label: "Shelter"},
  {key: 'kitchen', label: "Kitchen"},
  {key: 'cookingEquipment', label: "Cooking equipment"},
  {key: 'hotTub', label: "Hot tub"},
  {key: 'swimmingPool', label: "Swimming pool"},
  {key: 'sauna', label: "Sauna"},
  {key: 'outdoorGym', label: "Outdoor gym"},
  {key: 'sportsGear', label: "Sports gear"},
  {key: 'playground', label: "Playground"},
  {key: 'linen', label: "Linen"},
  {key: 'towels', label: "Towels"},
  {key: 'wachingMachine', label: "Waching machine"},
  {key: 'bar', label: "Bar"},
  {key: 'restaurant', label: "Restaurant"},
  {key: 'phoneCharge', label: "Phone charge"},
  {key: 'rvSanitation', label: "RV sanitation"},
  {key: 'beach', label: "Beach"},
]

export const terrainAmenitiesOptions = [
  {key: 'lake', label: "Lake"},
  {key: 'sea', label: "Sea"},
  {key: 'river', label: "River"},
  {key: 'island', label: "Island"},
  {key: 'mountain', label: "Mountain"},
  {key: 'field', label: "Field"},
  {key: 'village', label: "Village"},
  {key: 'town', label: "Town"},
  {key: 'city', label: "City"},
]

export const extraRules = [
  { title: 'Smoking allowed',
    titlePl: 'Wolno palić',
    selected: [],
  },
  { title: 'Standard cancellation policy',
    titlePl: 'Standardowe zasady anulowania rezerwacji',
    selected: [],
  },
  { title: 'Сurfew, quiet hours',
    titlePl: 'Obiekt zamykany na noc/ cisza nocna',
    selected: [],
  },
  { title: 'Pets allowed',
    titlePl: 'Akceptujemy zwierzęta',
    selected: [],
  },
]

export const sustainablePractices = [
  { title: 'Self grown food',
    titlePl: 'Jedzenie z własnych upraw',
    selected: [],
  },
  { title: 'Waste separation',
    titlePl: 'Segregacja śmieci',
    selected: [],
  },
  { title: 'Local products available',
    titlePl: 'Produkty od lokalnych producentów',
    selected: [],
  },
  { title: 'Solar panels',
    titlePl: 'Panele słoneczne',
    selected: [],
  },
  { title: 'Water saving measures',
    titlePl: 'Oszczędzanie wody',
    selected: [],
  },
]

export const additionalServices = [

]

export const servicesIcons = [
  {key: "equipment" , label: "Equipment"},
  {key: "facilities" , label: "Facilities"},
  {key: "services" , label: "Services"},
  {key: "cleaning" , label: "Cleaning"},
  {key: "other" , label: "Other"},
]

export const filters = [
  {
    id: 'mainCategory',
    labelId: 'mainCategoryFilter',
    label: 'Main category',
    type: 'SelectSingleFilterCategory',
    group: 'advanced',
    queryParamNames: ['pub_mainCategory'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: mainCategoriesOptions
    },
  },
  {
    id: 'dates',
    labelId: 'datesFilter',
    label: 'Select date',
    type: 'BookingDateRangeFilter',
    group: 'advanced',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'totalSeats',
    labelId: 'totalSeatsFilter',
    label: 'Add Guests',
    type: 'SelectRangeFilter',
    group: 'advanced',
    queryParamNames: ['pub_totalSeats'],
    config: {
      min: 1,
      max: 20,
      step: 1,
    },
  },
  {
    id: 'mainCategory',
    labelId: 'mainCategoryFilter',
    label: 'Main category',
    type: 'SelectSingleFilterWithIcons',
    group: 'mainCategoryFilter',
    queryParamNames: ['pub_mainCategory'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: mainCategoriesOptions
    },
  },
  {
    id: 'price',
    labelId: 'priceFilter',
    label: 'Price range',
    type: 'PriceFilter',
    group: 'primary',
    showOnMobile: true,
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 5000,
      step: 5,
    },
  },
  // {
  //   id: 'keyword',
  //   label: 'Keyword',
  //   type: 'KeywordFilter',
  //   group: 'primary',
  //   // Note: KeywordFilter is fixed filter,
  //   // you can't change "queryParamNames: ['keywords'],"
  //   queryParamNames: ['keywords'],
  //   // NOTE: If you are ordering search results by distance
  //   // the keyword search can't be used at the same time.
  //   // You can turn on/off ordering by distance from config.js file.
  //   config: {},
  // },
  {
    id: 'category',
    labelId: 'categoryFilter',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    showOnMobile: true,
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: categoriesAllList
    },
  },
  {
    id: 'type',
    labelId: 'typeFilter',
    label: 'Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    showOnMobile: true,
    queryParamNames: ['pub_type'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: typesOptions
    },
  },
  {
    id: 'amenitiesStandart',
    labelId: 'amenitiesStandartFilter',
    label: 'Standart',
    type: 'SelectMultipleFilter',
    group: 'primary',
    showOnMobile: true,
    queryParamNames: ['pub_standartAmenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: standartAmenitiesOptions
    },
  },
  {
    id: 'amenitiesExtras',
    labelId: 'amenitiesExtrasFilter',
    label: 'Extras',
    type: 'SelectMultipleFilter',
    group: 'primary',
    showOnMobile: true,
    queryParamNames: ['pub_extrasAmenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: extrasAmenitiesOptions
    },
  },
  {
    id: 'amenitiesTerrain',
    labelId: 'amenitiesTerrainFilter',
    label: 'Terrain',
    type: 'SelectMultipleFilter',
    group: 'primary',
    showOnMobile: true,
    queryParamNames: ['pub_terrainAmenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: terrainAmenitiesOptions
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
