import React, { Component } from 'react';
import { string, bool, arrayOf, array, func, oneOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { required, bookingDatesRequired, composeValidators } from '../../util/validators';
import { START_DATE, END_DATE, daysBetween, nightsBetween } from '../../util/dates';
import { propTypes } from '../../util/types';
import { Form, IconSpinner, PrimaryButton, FieldDateRangeInput } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import AddGuests from './AddGuests';
import RemainingSeats from './RemainingSeats';

import css from './BookingDatesForm.module.css';

const identity = v => v;

const isBefore = (date1, date2) => {
  return moment(date1).isSameOrBefore(date2);
}

const countAvailableSeats = (timeSlots, startDate = "", endDate = "", defaultSeats) => {

  if (!timeSlots) {
    return +defaultSeats;
  }

  const range = timeSlots.filter(({ attributes }) => {
    if (isBefore(startDate, attributes.end) && isBefore(attributes.start, endDate)) {
      return true
    }
  });

  if (range.length === 0) {
    return +defaultSeats;
  }

  const availableSeats = range.reduce((a, b) => {
    return a.attributes.seats < b.attributes.seats ? a : b;
  })

  return availableSeats.attributes.seats;
}

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      guests: {
        kids: 0,
        adults: 0,
      },
      guestsError: null,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const { startDate, endDate } = e.bookingDates || {};
    const { guests } = this.state;
    const totalGuests = guests.kids + guests.adults;

    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else if (totalGuests === 0) {
      this.setState({ guestsError: true });
    } else {
      const { guests } = this.state;
      const { additionServices } = this.props;
      this.props.onSubmit({ ...e, guests, additionServices });
      this.setState({ guestsError: null });
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { startDate, endDate } = formValues?.values?.bookingDates ?? {};
    const {
      isOwnListing,
      listingId,
      listing,
      onFetchTransactionLineItems,
      fetchLineItemsInProgress,
      additionServices,
      unitType,
      timeSlots,
    } = this.props;
    const { guests } = this.state;
    const {
      attributes: {
        publicData: { numberOfGuests, optionsPrice: { units } },
      },
    } = listing;
    const maxUnits = countAvailableSeats(timeSlots, startDate, endDate, units);
    const overcrowd = Math.ceil((guests.kids + guests.adults) / numberOfGuests) > maxUnits;
    let updateGuests = guests;

    if (overcrowd) {
      this.setState({
        guests: {
          kids: 0,
          adults: 0,
        }
      });

      updateGuests = {
        kids: 0,
        adults: 0,
      }
    }

    if (startDate && endDate && !fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        bookingData: { startDate, endDate, unitType },
        listingId,
        isOwnListing,
        guests: updateGuests,
        additionServices,
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      listing,
      additionServices,
      listingId,
      onFetchTransactionLineItems,
      totalPriceLineItems,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const { guests, guestsError } = this.state;

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
          } = fieldRenderProps;
          const { startDate, endDate } = values?.bookingDates ?? {};
          const { maxStayOrUse = null, minStayOrUse = 0, pricingModelType, units } =
            listing?.attributes?.publicData?.optionsPrice ?? {};
          const {
            attributes: {
              publicData: { numberOfGuests, mainCategory },
            },
          } = listing;
          const maxUnits = countAvailableSeats(timeSlots, startDate, endDate, +units);

          const bookingDatesTitle = intl.formatMessage({
            id: 'BookingDatesForm.bookingDatesTitle',
          });
          // const bookingDatesDetailsPrice = intl.formatMessage({
          //   id: 'BookingDatesForm.bookingDatesDetailsPrice',
          // });
          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingEndTitle',
          });
          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <RemainingSeats
                seats={maxUnits}
                intl={intl}
              />
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                lineItems={lineItems}
                listing={listing}
                guests={guests}
                additionServices={additionServices}
                totalPriceLineItems={totalPriceLineItems}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const handleChangeGuests = guests => {
            if (startDate && endDate && !fetchLineItemsInProgress) {
              onFetchTransactionLineItems({
                bookingData: { startDate, endDate, unitType },
                listingId,
                isOwnListing,
                guests,
                additionServices,
              });
            }
            this.setState({ guests, guestsError: null });
          };

          const availableBookingPeriod = {
            max: maxStayOrUse,
            min: minStayOrUse,
          };
          const isDaily = mainCategory !== 'sleep';
          const quantity = isDaily ? daysBetween(startDate, endDate) : nightsBetween(startDate, endDate);

          const validationBookingPeriodError =
            minStayOrUse > quantity && quantity !== 0 ? (
              <p className={css.sideBarError}>
                <FormattedMessage
                  id={`BookingDatesForm.${
                    isDaily
                      ? 'validationBookingPeriodErrorDay'
                      : 'validationBookingPeriodErrorNight'
                  }`}
                  values={{
                    minimumNights: minStayOrUse,
                    plural: minStayOrUse > 1 ? 's' : '',
                  }}
                />
              </p>
            ) : null;

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <div className={css.bookingDatesTitle}>{bookingDatesTitle}</div>
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              <FieldDateRangeInput
                className={css.bookingDates}
                name="bookingDates"
                unitType={unitType}
                startDateId={`${formId}.bookingStartDate`}
                startDateLabel={bookingStartLabel}
                startDatePlaceholderText={startDatePlaceholderText}
                endDateId={`${formId}.bookingEndDate`}
                endDateLabel={bookingEndLabel}
                endDatePlaceholderText={endDatePlaceholderText}
                focusedInput={this.state.focusedInput}
                onFocusedInputChange={this.onFocusedInputChange}
                format={identity}
                timeSlots={timeSlots}
                useMobileMargins
                validate={composeValidators(
                  required(requiredMessage),
                  bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
                )}
                disabled={fetchLineItemsInProgress}
                availableBookingPeriod={availableBookingPeriod}
                validationBookingPeriodError={validationBookingPeriodError}
              />

              <AddGuests
                intl={intl}
                guests={guests}
                handleChangeGuests={handleChangeGuests}
                maxNumberOfGuests={numberOfGuests}
                maxUnits={maxUnits}
                pricingModelType={pricingModelType}
              />
              {guestsError ? (
                <div className={classNames(css.error, css.guestsError)}>
                  <FormattedMessage id="BookingDatesForm.guestsNotSelectedError" />
                </div>
              ) : null}
              <div className={css.bookingDatesDivider} />

              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={!!validationBookingPeriodError}>
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>

              <div className={css.bookingDatesDetails}>
                {showEstimatedBreakdown ? bookingInfoMaybe : null}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  // unitType: propTypes.bookingUnitType.isRequired,
  unitType: oneOf([propTypes.bookingUnitTypeDay, propTypes.bookingUnitTypeNight]).isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
