import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSliderArrowBig.module.css';


const IconSliderArrowBig = props => {
  const { className, rootClassName, direction, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (direction === 'next') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="36"
        fill="none"
        viewBox="0 0 42 36"
        onClick={onClick}
      >
        <path
          fill="#2F4E1E"
          d="M0 18C0 8.059 8.059 0 18 0h6c9.941 0 18 8.059 18 18s-8.059 18-18 18h-6C8.059 36 0 27.941 0 18z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M13 17.5c0 .284.105.557.293.758.187.2.442.313.707.313h11.585L21.29 23.17a1.08 1.08 0 00-.217.348 1.137 1.137 0 00.217 1.169c.093.1.203.178.325.232a.943.943 0 00.766 0 .999.999 0 00.325-.232l6-6.428c.092-.1.166-.217.217-.347a1.136 1.136 0 00-.218-1.17l-6-6.427A.968.968 0 0022 10a.968.968 0 00-.708.314 1.112 1.112 0 00-.293.759c0 .284.105.557.293.758l4.294 4.598H14a.967.967 0 00-.707.314c-.188.2-.293.473-.293.757z"
          clipRule="evenodd"
          className={css.arrow}
        ></path>
      </svg>
    );
  } else {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="36"
        fill="none"
        viewBox="0 0 42 36"
        onClick={onClick}
      >
        <path
          fill="#2F4E1E"
          d="M42 18c0-9.941-8.059-18-18-18h-6C8.059 0 0 8.059 0 18s8.059 18 18 18h6c9.941 0 18-8.059 18-18z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M29 17.5c0 .284-.105.557-.293.758a.967.967 0 01-.707.313H16.415l4.294 4.598c.093.1.167.218.217.348a1.137 1.137 0 01-.217 1.169.999.999 0 01-.325.232.943.943 0 01-.766 0 1 1 0 01-.325-.232l-6-6.428a1.078 1.078 0 01-.217-.347 1.136 1.136 0 01.218-1.17l6-6.427A.968.968 0 0120 10c.265 0 .52.113.708.314.188.201.293.474.293.759 0 .284-.105.557-.293.758l-4.294 4.598H28c.265 0 .52.113.707.314.188.2.293.473.293.757z"
          clipRule="evenodd"
          className={css.arrow}
        ></path>
      </svg>
    );
  }
};

const { string } = PropTypes;

IconSliderArrowBig.defaultProps = {
  className: null,
  rootClassName: null,
};

IconSliderArrowBig.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconSliderArrowBig;
