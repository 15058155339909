import React, { useState } from 'react';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName, ContentWithShowMore } from '../../components';
import { propTypes } from '../../util/types';

import css from './Reviews.module.css';

const REVIEWS_LIMIT = 2;
const REVIEWS_CONTENT_LIMIT = 125;

const Review = props => {
  const { review, intl } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  return (
    <div className={css.review}>
      <div className={css.reviewInfo}>
        <Avatar className={css.avatar} user={review.author} />
        <div className={css.reviewAuthorInfo}>
          <UserDisplayName user={review.author} intl={intl} className={css.reviewAuthorName} />
          <div className={css.reviewDate}>
            {dateString}
          </div>
        </div>
        {/* <span className={css.separator}>•</span> */}
        {/* <span className={css.desktopSeparator}>•</span> */}
        {/* <span className={css.desktopReviewRatingWrapper}>
          <ReviewRating
            rating={review.attributes.rating}
            className={css.desktopReviewRating}
            reviewStarClassName={css.reviewRatingStar}
          />
        </span> */}
      </div>
      <div>
        {/* <ReviewRating
          rating={review.attributes.rating}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        /> */}
        <p className={css.reviewContent}>
          <ContentWithShowMore
            content={review.attributes.content}
            contentLimit={REVIEWS_CONTENT_LIMIT}
            showDots
          />
        </p>

      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl } = props;

  const [showAllReviews, setShowAllReviews] = useState(false)

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      {!showAllReviews && reviews?.length > REVIEWS_LIMIT ? (
        <ul className={classes}>
          {reviews.slice(0, REVIEWS_LIMIT).map(r => {
            return (
              <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
                <Review review={r} intl={intl} />
              </li>
            );
          })}

          {showAllReviews ? (
            <li className={css.showAllReviews} onClick={() => { setShowAllReviews(false) }}>
              <FormattedMessage id="ListingPage.showLessReviews" />
            </li>
          ) : (
            <li className={css.showAllReviews} onClick={() => { setShowAllReviews(true) }}>
              <FormattedMessage id="ListingPage.showMoreReviews" />
            </li>
          )}
        </ul>
      ) : (
        <ul className={classes}>
          {reviews.map(r => {
            return (
              <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
                <Review review={r} intl={intl} />
              </li>
            );

          })}
          {!!reviews?.length && reviews?.length > REVIEWS_LIMIT && (
            <li className={css.showAllReviews} onClick={() => { setShowAllReviews(false) }}>
              <FormattedMessage id="ListingPage.showLessReviews" />
            </li>
          )}
        </ul>
      )}
    </>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
