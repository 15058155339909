import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { DATE_TYPE_DATE } from '../../util/types';
import { BookingBreakdown } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = ({
  className,
  rootClassName,
  breakdownClassName,
  transaction,
  transactionRole,
  totalPriceLineItems,
}) => {
  const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  const listing = transaction.listing;
  const { guests = {}, additionServices = [] } = transaction?.attributes?.protectedData;

  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);

  const unitType =
    listing.attributes.publicData.mainCategory === 'sleep'
      ? config.bookingUnitTypeNight
      : config.bookingUnitTypeDay;

  return loaded ? (
    <div className={classes}>
      <BookingBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        // unitType={config.bookingUnitType}
        unitType={unitType}
        transaction={transaction}
        booking={transaction.booking}
        dateType={DATE_TYPE_DATE}
        listing={listing}
        guests={guests}
        additionServices={additionServices}
        totalPriceLineItems={totalPriceLineItems ? [totalPriceLineItems] : null}
      />
    </div>
  ) : null;
};

export default BreakdownMaybe;
