import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconServices.module.css';

const IconServices = props => {
  const { rootClassName, className, servicesType } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (servicesType === "equipment") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="34"
        fill="none"
        viewBox="0 0 45 34"
      >
        <circle
          cx="13.5"
          cy="16.5"
          r="13.1"
          stroke="#252525"
          strokeWidth="0.8"
        ></circle>
        <circle
          cx="13.5"
          cy="16.5"
          r="9.1"
          stroke="#252525"
          strokeWidth="0.8"
        ></circle>
        <path
          fill="#252525"
          d="M12.79 18.386l.849-.96.099.021a.725.725 0 00.087.014h.09a.839.839 0 00.62-.287 1.04 1.04 0 00.258-.69v-.105a.982.982 0 00-.016-.103l-.02-.11 1.105-1.248L18 21l-5.413-2.389.203-.225zm.982-.51l-.515.58 4.075 1.802-1.61-4.58-.566.63v.03c.003.05.003.1 0 .149 0 .183-.032.365-.095.534-.063.17-.155.324-.272.454-.116.13-.255.232-.407.303-.152.07-.316.106-.48.106a1.102 1.102 0 01-.126 0h-.021l.016-.008zm1.438-3.259l-.883.996-.107-.046a.717.717 0 00-.293-.057.803.803 0 00-.336.074.88.88 0 00-.286.212.993.993 0 00-.19.318 1.075 1.075 0 00-.054.547c.005.057.014.114.027.169l.043.124-1 1.128L10 12l5.145 2.276.1.1.059.134-.094.107z"
        ></path>
        <rect
          width="6.491"
          height="22.325"
          x="27.947"
          y="9.676"
          fill="#fff"
          rx="2"
          transform="rotate(31.067 27.947 9.676)"
        ></rect>
        <path
          fill="#252525"
          stroke="#252525"
          strokeWidth="0.2"
          d="M23.143 30.071l.085.052.052-.085 10.24-16.815.052-.085-.086-.052-5.6-3.413-.085-.052-.052.085-10.24 16.815-.051.085.085.052 5.6 3.413zm9.65-16.74L23.04 29.344l-4.802-2.927 9.755-16.01 4.801 2.924z"
        ></path>
        <path
          fill="#252525"
          stroke="#252525"
          strokeWidth="0.2"
          d="M21.691 31.776a1.54 1.54 0 00.968-.701l.825-1.356.053-.086-.086-.052-5.61-3.395-.087-.052-.052.086-.806 1.355a1.54 1.54 0 00.516 2.123l3.112 1.895s0 0 0 0a1.532 1.532 0 001.167.183zm0 0s0 0 0 0l-.023-.097.024.097h-.001zM17.238 28.6a.986.986 0 01.111-.739l.596-.959 4.773 2.916-.585.955a1.005 1.005 0 01-1.345.327l-3.111-1.895s0 0 0 0a.986.986 0 01-.44-.605z"
        ></path>
        <path
          fill="#252525"
          stroke="#000"
          strokeWidth="0.2"
          d="M38.727 11.109l.085.052.052-.086 1.083-1.778L40 9.21l-.085-.052-11.492-6.997-.085-.052-.052.085-1.083 1.779-.052.085.085.052 11.492 6.998zm.487-1.673l-.587.963-10.694-6.53.588-.953 10.693 6.52z"
        ></path>
        <path
          fill="#252525"
          d="M25.578 21.484a1.234 1.234 0 01-.946-.15 1.252 1.252 0 01-.417-1.716l2.166-3.558a1.273 1.273 0 011.717-.417 1.252 1.252 0 01.418 1.717l-2.167 3.558a1.234 1.234 0 01-.771.566zm1.663-5.626a.876.876 0 00-.543.398l-2.167 3.558a.883.883 0 001.508.918l2.166-3.558a.88.88 0 00.104-.666.89.89 0 00-.723-.665.877.877 0 00-.345.015z"
        ></path>
        <path
          fill="#fff"
          stroke="#252525"
          strokeWidth="0.8"
          d="M39.047 10.999l.238-.348-.36-.22-11.177-6.809-.341-.208-.208.342-.027.044s0 0 0 0 0 0 0 0a5.471 5.471 0 001.827 7.52h0l2.516 1.528s0 0 0 0a5.467 5.467 0 007.51-1.817l.022-.032z"
        ></path>
      </svg>
    );
  }
  else if (servicesType === "facilities") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="30"
        fill="none"
        viewBox="0 0 35 30"
      >
        <path
          stroke="#252525"
          d="M19.084 25.5l-4.667-5.834M13.25 29l2.917-3.5M30.167 29l-2.917-3.5M16.75 22.584h6.417L25.5 25.5M13.833 25.5h14.583M10.917 29V1M1 10.917h20.417C21.222 7.61 18.85 1 10.917 1 2.983 1 1 7.611 1 10.917zM5.667 10.917C5.473 7.61 6.251 1 10.917 1M16.167 10.917C16.36 7.61 15.583 1 10.917 1M1 29h33.833"
        ></path>
      </svg>
    )
  }
  else if (servicesType === "services") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="32"
        fill="none"
        viewBox="0 0 29 32"
      >
        <path
          fill="#272525"
          d="M18.086 10.212a.398.398 0 00-.452.308l-.328 1.713a.393.393 0 00.308.457l.661.125c.032.361.107.718.224 1.063l-.557.377a.383.383 0 00-.164.248.378.378 0 00.06.293l.993 1.44a.402.402 0 00.249.165.411.411 0 00.293-.06l.556-.382c.276.236.58.437.904.6l-.124.661a.387.387 0 00.308.452l1.714.328h.074a.388.388 0 00.378-.313l.129-.66c.361-.036.718-.11 1.063-.224l.377.556a.402.402 0 00.249.164.372.372 0 00.288-.06l1.445-.993a.387.387 0 00.1-.541l-.378-.556c.235-.278.436-.583.596-.91l.66.13a.392.392 0 00.458-.308l.322-1.714a.388.388 0 00-.167-.398.372.372 0 00-.14-.054l-.661-.129a4.594 4.594 0 00-.219-1.063l.557-.377a.388.388 0 00.104-.537l-.994-1.445a.377.377 0 00-.536-.1l-.556.378a4.415 4.415 0 00-.91-.596l.13-.66a.392.392 0 00-.313-.458l-1.709-.328a.402.402 0 00-.457.308l-.124.666c-.362.03-.718.104-1.063.218l-.377-.556a.393.393 0 00-.542-.104l-1.44.993a.382.382 0 00-.104.537l.382.556a4.43 4.43 0 00-.601.909l-.666-.12zm1.247.715c.184-.443.45-.848.785-1.192a.387.387 0 00.04-.497l-.333-.497.8-.546.332.497a.392.392 0 00.497.144 3.834 3.834 0 011.396-.293.387.387 0 00.372-.313l.11-.581.953.183-.114.577a.387.387 0 00.233.432c.444.184.849.45 1.193.785a.383.383 0 00.496.04l.497-.328.546.8-.496.332a.388.388 0 00-.14.467c.184.443.284.916.294 1.396a.382.382 0 00.313.372l.58.11-.183.953-.581-.11a.382.382 0 00-.427.23c-.184.443-.45.847-.785 1.191a.383.383 0 00-.04.497l.328.497-.854.536-.333-.496a.382.382 0 00-.467-.144 3.755 3.755 0 01-1.396.293.382.382 0 00-.372.313l-.11.58-.953-.183.109-.576a.387.387 0 00-.229-.432 3.739 3.739 0 01-1.192-.785.393.393 0 00-.496-.04l-.497.333-.522-.795.497-.328a.382.382 0 00.14-.496 3.853 3.853 0 01-.289-1.396.388.388 0 00-.313-.373l-.581-.109.184-.954.576.115a.392.392 0 00.432-.209z"
        ></path>
        <path
          fill="#272525"
          d="M22.374 15.15a2.791 2.791 0 101.049-5.484 2.791 2.791 0 00-1.05 5.483zm-1.456-3.115a1.987 1.987 0 011.987-1.635c.128.002.256.014.383.035a2.016 2.016 0 011.594 2.355 1.986 1.986 0 01-2.35 1.6 2.01 2.01 0 01-1.599-2.355h-.015zM7.76 7.242a.392.392 0 00.441.327l.666-.094c.148.332.334.646.556.934l-.407.536a.402.402 0 00-.075.288.368.368 0 00.154.254l1.396 1.048a.383.383 0 00.541-.08l.403-.536c.34.128.693.218 1.053.268l.1.666a.358.358 0 00.148.253c.068.05.15.078.233.08h.055l1.724-.249a.38.38 0 00.328-.437l-.095-.666a4.55 4.55 0 00.934-.556l.541.402a.388.388 0 00.542-.074L18.04 8.21a.387.387 0 00-.075-.541l-.541-.408c.134-.338.224-.692.268-1.053l.67-.094a.392.392 0 00.254-.149.402.402 0 00.075-.288l-.249-1.729a.393.393 0 00-.442-.328l-.665.095a4.803 4.803 0 00-.552-.934l.403-.536a.402.402 0 00.074-.288.367.367 0 00-.154-.254L15.707.65a.382.382 0 00-.542.08l-.402.536A4.893 4.893 0 0013.71.998l-.095-.666a.388.388 0 00-.442-.328L11.45.253a.388.388 0 00-.328.437l.094.665a4.552 4.552 0 00-.934.557l-.536-.403a.363.363 0 00-.283-.074.407.407 0 00-.259.149L8.161 2.98a.387.387 0 00.095.546l.541.407a4.47 4.47 0 00-.268 1.053l-.666.095a.407.407 0 00-.258.149.427.427 0 00-.074.288l.228 1.724zm2.304-4.526a.397.397 0 00.496 0 3.76 3.76 0 011.227-.73.387.387 0 00.249-.417L11.95.983l.959-.14.084.587a.397.397 0 00.363.333c.477.03.943.15 1.376.352a.387.387 0 00.497-.119l.352-.472.775.582-.353.496a.388.388 0 000 .497c.318.36.566.776.73 1.227a.383.383 0 00.418.253l.581-.084.14.958-.582.085a.383.383 0 00-.333.357 3.83 3.83 0 01-.352 1.381.382.382 0 00.119.497l.496.358-.58.774-.473-.357a.397.397 0 00-.496 0 3.79 3.79 0 01-1.222.73.388.388 0 00-.254.417l.085.586-.959.134-.084-.58a.388.388 0 00-.358-.333 3.804 3.804 0 01-1.38-.353.397.397 0 00-.497.12l-.353.496-.775-.581.353-.497a.383.383 0 000-.497 3.835 3.835 0 01-.725-1.227.392.392 0 00-.423-.253l-.58.084-.14-.958.586-.085a.382.382 0 00.328-.357c.03-.48.151-.948.358-1.381a.392.392 0 00-.12-.472l-.496-.358.58-.774.468.307z"
        ></path>
        <path
          fill="#272525"
          d="M13.113 8.379a2.777 2.777 0 10-.017-5.553 2.777 2.777 0 00.017 5.553zM15.1 5.304a1.986 1.986 0 11-2.275-1.703c.096-.008.192-.008.288 0a2.012 2.012 0 011.992 1.703H15.1z"
        ></path>
        <g clipPath="url(#clip0_0_1)">
          <path
            fill="#272525"
            d="M.098 21.859a.526.526 0 01.424-.215c1.438 0 2.407-.702 3.535-1.48.242-.177.485-.355.757-.525A7.128 7.128 0 019 18.226h5.874c.418 0 .818.162 1.113.45.296.29.461.68.461 1.089 0 .408-.165.8-.46 1.088-.296.289-.696.451-1.114.451h-3.671c-.207.04-.396.139-.544.285a1 1 0 00-.21 1.134c.085.187.226.345.406.453l1.362.355c.47.104.946.23 1.393.363h.098l3.649.932c.1.012.202.012.303 0l5.76-3.648 1.045-.74h.068a.67.67 0 01.159-.088c.108-.058.223-.105.34-.141l.273-.06c.228-.04.461-.04.689 0a2.214 2.214 0 011.426.693c.37.398.574.916.572 1.454a2.086 2.086 0 01-.65 1.48c-.15.139-.319.258-.5.354l-9.553 6.77-.144.075h-.076a2.543 2.543 0 01-1.317.096h-.121c-6.056-1.48-10.386-2.575-12.535-3.093h-.06l-.19-.067A11.07 11.07 0 00.53 27.52a.529.529 0 01-.37-.15.505.505 0 01-.153-.36v-.289a.449.449 0 010-.096V22.28a.507.507 0 01.091-.422zm.954 4.706c.702.083 1.398.214 2.082.392h.06l.182.066c2.157.518 6.487 1.584 12.52 3.086.253.05.515.03.757-.06l9.575-6.732.069-.045c.1-.05.192-.114.272-.192a1.111 1.111 0 00.348-.8c.001-.284-.109-.558-.307-.767a1.161 1.161 0 00-.76-.357h-.06a1.278 1.278 0 00-.341 0h-.137l-.166.067-.098.059-1.03.74-5.859 3.7a.51.51 0 01-.159.066 2.46 2.46 0 01-.757.052h-.09c-.265-.06-1.514-.378-3.725-.947h-.106a41.644 41.644 0 00-1.377-.356l-1.446-.377a.339.339 0 01-.121-.044 2.07 2.07 0 01-.878-.907 2.008 2.008 0 01-.177-1.237c.074-.42.28-.807.59-1.107.31-.3.708-.498 1.138-.567h3.785a.531.531 0 00.37-.15.51.51 0 00.152-.36.512.512 0 00-.152-.364.536.536 0 00-.37-.155H8.94a6.022 6.022 0 00-3.588 1.236c-.242.163-.484.34-.757.51A7.37 7.37 0 01.976 22.71v3.914l.076-.06z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_0_1">
            <path
              fill="#fff"
              d="M0 0H28V13H0z"
              transform="matrix(-1 0 0 1 28 18.189)"
            ></path>
          </clipPath>
        </defs>
      </svg>
    )
  }
  else if (servicesType === "cleaning") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="37"
        fill="none"
        viewBox="0 0 40 37"
      >
        <path
          fill="#000"
          d="M8.446 11.571l-.78-9.339a2.18 2.18 0 011.3-2.045c.295-.126.61-.19.93-.187.318.004.633.076.921.213a2.138 2.138 0 011.227 2.043l-.913 9.309h2.875c1.127-.091 2.118.096 2.896.67.777.576 1.334 1.513 1.595 2.917a.17.17 0 010 .032l1.125 10.514a.43.43 0 01-.221.43.422.422 0 01-.162.048H.425a.423.423 0 01-.423-.426.394.394 0 010-.07l1.317-11.076a.624.624 0 010-.061 3.493 3.493 0 011.017-1.958c.6-.534 1.343-.88 2.136-.997a.354.354 0 01.095 0l3.869-.01.01-.007zM2.938 13.22a2.65 2.65 0 00-.763 1.49L.921 25.303h17.796l-1.072-10.01c-.212-1.158-.65-1.917-1.25-2.343-.6-.425-1.391-.581-2.33-.504h-.034l-9.432.027a3.251 3.251 0 00-1.667.77l.006-.022zm13.926 10.964l-1.185-7.68v-.034c-.044-.596-.199-1.044-.479-1.325-.28-.28-.684-.406-1.256-.385H5.45a1.72 1.72 0 00-1.011.383 1.935 1.935 0 00-.57 1.095l-1.102 7.991-.847-.12 1.104-8.005v-.03c.098-.61.396-1.17.847-1.589.432-.362.97-.571 1.532-.596h8.521c.818-.028 1.438.194 1.88.639.44.445.67 1.064.732 1.872l1.18 7.648-.847.136h-.004zM9.307 11.482l.963-.02.91-9.277a1.298 1.298 0 00-.725-1.192 1.409 1.409 0 00-1.144 0 1.332 1.332 0 00-.782 1.205l.778 9.293v-.009z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M31.276 1.16l-8.305 12.404 3.14 2.157 8.362-12.44c.547-2.158-1.658-3.482-3.212-2.118l.015-.002zM19.173 15.324a2.5 2.5 0 01.276-.263l.034-.027a1.642 1.642 0 011.865-.198l2.108 1.46.033.023 1.94 1.343c.808.585 1.102 1.348.885 2.288l-2.488 13.78c-9.08-.2-16.297-5.486-17.97-15.232 5.048.566 9.746.2 13.317-3.174z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          stroke="#252525"
          strokeWidth="0.1"
          d="M24.035 34.049l.043.001.008-.043 2.51-13.994c.129-.533.107-1.02-.062-1.46-.17-.438-.485-.824-.936-1.157h0-.001l-4.082-2.816h0l-.005-.003-.036-.02s0 0 0 0a1.93 1.93 0 00-2.183.235l-.038.03h0v.001c-.104.087-.2.18-.291.282-1.005.947-2.333 1.935-4.378 2.578-2.047.644-4.815.944-8.7.509h0l-.333-.038-.067-.007.012.066.06.336c.814 4.742 3 8.68 6.32 11.385h0c3.17 2.582 7.296 4.003 11.954 4.107 0 0 0 0 0 0l.205.008zM26 19.87h0v.002l-2.425 13.545c-4.617-.154-8.536-1.59-11.51-4.091-2.968-2.498-4.998-6.06-5.838-10.479 4.13.427 9.265.366 13.15-3.303h.003l.017-.003.012-.014c.07-.08.147-.156.23-.226l.034-.026h0l.003-.002a1.348 1.348 0 011.525-.168l4.038 2.788s0 0 0 0c.354.257.59.545.716.87.125.325.14.69.045 1.107zm.15-3.75l.04.029.029-.042 8.507-12.64h0l.024-.035.005-.007.002-.008.013-.044h0v-.002c.281-1.12-.09-2.173-.955-2.73h-.001a2.25 2.25 0 00-2.747.29V.928l-.007.009-8.48 12.663-.027.041.04.028 3.556 2.45zm8.043-12.957L26.031 15.3l-2.634-1.819L31.5 1.384a1.655 1.655 0 011.998-.205c.49.31.928.992.695 1.985z"
        ></path>
      </svg>
    )
  }
  else {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="37"
        height="27"
        fill="none"
        viewBox="0 0 37 27"
      >
        <path
          stroke="#252525"
          d="M13 1.707H30.385V19.092000000000002H13z"
          transform="rotate(45 13 1.707)"
        ></path>
        <path
          stroke="#252525"
          d="M16.94 2.94l1.641-1.634 11.49 12.529-12.037 11.984-1.095-1.09"
        ></path>
        <path
          stroke="#252525"
          d="M22.192 2.94l1.642-1.634 11.49 12.529-12.037 11.984-1.095-1.09"
        ></path>
      </svg>
    )
  }
};

IconServices.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconServices.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconServices;
