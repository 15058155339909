import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconDiscount.module.css';

const IconDiscount = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.322 18.761l5.474 5.474c2.248 2.247 5.897 2.247 8.156 0l5.305-5.305c2.247-2.247 2.247-5.896 0-8.156l-5.486-5.462a5.74 5.74 0 00-4.35-1.68l-6.041.29a4.669 4.669 0 00-4.46 4.435L3.63 14.4a5.805 5.805 0 001.692 4.362z"
      ></path>
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M11.763 14.774a3.02 3.02 0 100-6.042 3.02 3.02 0 000 6.042z"
      ></path>
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M15.992 20.815l4.834-4.833"
      ></path>
    </svg>
  );
};

IconDiscount.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconDiscount.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconDiscount;
