import React from 'react';
import { number } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './BookingBreakdown.module.css';

const LineItemKidsDiscountMaybe = ({ listing, kids, kidsDiscount, intl }) => {
  const discountKids = listing.attributes.publicData.discountOptions?.discountKids ?? null;
  const { currency } = listing.attributes.price;

  const formattedPrice = formatMoney(intl, new Money(kidsDiscount, currency));

  return (
    kids > 0 && discountKids ? <div className={css.lineItemTotal}>
      <div className={css.totalLabel}>
        <FormattedMessage id="BookingBreakdown.kidsDiscountLabel" />
      </div>
      <div className={css.totalPrice}>-{formattedPrice}</div>
    </div> : null
  )
}

LineItemKidsDiscountMaybe.propTypes = {
  listing: propTypes.listing.isRequired,
  kids: number.isRequired,
};

export default LineItemKidsDiscountMaybe;
