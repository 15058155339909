import React, { Component } from 'react';
import { arrayOf, func, node, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import { OutsideClickHandler } from '../../components';
import { SelectRangeFilterForm } from '../../forms';

import css from './SelectRangeFilterPlain.module.css';

const RADIX = 10;

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : queryParamNames;
};

// Parse value, which should look like "0,1000"
const parse = priceRange => {
  const [minRange, maxRange] = !!priceRange
    ? priceRange.split(',').map(v => Number.parseInt(v, RADIX))
    : [];
  // Note: we compare to null, because 0 as minPrice is falsy in comparisons.
  return !!priceRange && minRange != null && maxRange != null ? { minRange, maxRange } : null;
};

// Format value, which should look like { minPrice, maxPrice }
const format = (range, queryParamName) => {
  const { minRange, maxRange } = range || {};
  // Note: we compare to null, because 0 as minPrice is falsy in comparisons.
  const value = minRange != null && maxRange != null ? `${minRange},${maxRange}` : null;
  return { [queryParamName]: value };
};


class SelectRangeFilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };

    this.handleChange = this.handleChange.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);

    this.state = { isOpen: false };
    this.filter = null;
    this.filterContent = null;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  handleChange(values) {
    const { onSubmit, queryParamNames } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    onSubmit(format(values, queryParamName));
  }

  // handleClear() {
  //   const { onSubmit, queryParamNames } = this.props;
  //   const queryParamName = getQueryParamName(queryParamNames);
  //   onSubmit(format(null, queryParamName), true);
  // }

  toggleIsOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }



  handleSubmit(values) {
    const { onSubmit } = this.props;
    this.setState({ isOpen: false });
    onSubmit(values);
  }

  handleClear() {
    const { onSubmit, onClear } = this.props;
    this.setState({ isOpen: false });

    if (onClear) {
      onClear();
    }

    onSubmit(null);
  }

  handleCancel() {
    const { onSubmit, onCancel, initialValues } = this.props;
    this.setState({ isOpen: false });

    if (onCancel) {
      onCancel();
    }

    onSubmit(initialValues);
  }

  handleBlur() {
    this.setState({ isOpen: false });
  }

  handleKeyDown(e) {
    // Gather all escape presses to close menu
    if (e.keyCode === KEY_CODE_ESCAPE) {
      this.toggleOpen(false);
    }
  }

  toggleOpen(enforcedState) {
    if (enforcedState) {
      this.setState({ isOpen: enforcedState });
    } else {
      this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }


  render() {
    const {
      rootClassName,
      className,
      id,
      label,
      queryParamNames,
      initialValues,
      min,
      max,
      step,
      intl,
      unitsLabel,
      useSplitFormat,
      popupClassName,
      labelMaxWidth,
      isSelected,
      children,
      keepDirtyOnReinitialize,
      contentPlacementOffset,
      showLabelIcon
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const queryParamName = getQueryParamName(queryParamNames);

    const initialSelectRange = initialValues ? parse(initialValues[queryParamName]) : {};

    const { minRange, maxRange } = initialSelectRange || {};

    const hasValue = value => value != null;
    const hasInitialValues = initialValues && hasValue(minRange) && hasValue(maxRange);

    // const initialValue =
    //   initialValues &&
    //     initialValues[queryParamName] &&
    //     typeof initialValues[queryParamName] === 'string'
    //     ? initialValues[queryParamName].split(',')
    //     : initialValues[queryParamName];

    const labelClass = hasInitialValues ? css.menuLabelSelected : css.menuLabel;


    // const handles = [
    //   parseInt((initialValue && initialValue[0]) || 0),
    //   parseInt((initialValue && initialValue[1]) || 1000),
    // ];


    const popupClasses = classNames(css.popup, { [css.isOpen]: this.state.isOpen });
    const popupSizeClasses = popupClassName || css.popupSize;
    const labelStyles = isSelected ? css.labelSelected : css.label;
    const labelMaxWidthMaybe = labelMaxWidth ? { maxWidth: `${labelMaxWidth}px` } : {};
    const labelMaxWidthStyles = labelMaxWidth ? css.labelEllipsis : null;
    const contentStyle = this.positionStyleForContent();

    return (
      <OutsideClickHandler onOutsideClick={this.handleBlur}>

        <div
          className={classes}
          onKeyDown={this.handleKeyDown}
          ref={node => {
            this.filter = node;
          }}
        >
          <div className={labelClass}>
            <button type="button" className={css.labelButton} onClick={this.toggleIsOpen}>
              {'labelText'}
            </button>
          </div>

          <div
            id={id}
            className={popupClasses}
            ref={node => {
              this.filterContent = node;
            }}
            style={contentStyle}
          >
            {this.state.isOpen ? (
              <SelectRangeFilterForm
                id={id}
                initialValues={hasInitialValues ? initialSelectRange : { minRange: min, maxRange: max }}
                onChange={this.handleChange}
                intl={intl}
                contentRef={node => {
                  this.filterContent = node;
                }}
                min={min}
                max={max}
                step={step}
                liveEdit
                isOpen={this.state.isOpen}
                label={label}
                unitsLabel={unitsLabel}
                useSplitFormat={useSplitFormat}
              />
            ) : null}

            <button type="button" className={css.clearButton} onClick={this.handleClear}>
              <FormattedMessage id={'SelectRangeFilter.plainClear'} />
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

SelectRangeFilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  step: number,
  currencyConfig: config.currencyConfig,
};

SelectRangeFilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  queryParamNames: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    price: string,
  }),
  min: number.isRequired,
  max: number.isRequired,
  step: number,
  currencyConfig: propTypes.currencyConfig,

  // form injectIntl
  intl: intlShape.isRequired,
};

const SelectRangeFilterPlain = injectIntl(SelectRangeFilterPlainComponent);

export default SelectRangeFilterPlain;
