import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldCheckbox,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconGuests,
  IconMinus,
  IconPlus,
  BookingDateRangeFilter,
  FieldRadioButton,
  OutsideClickHandler,
  IconSearch,
  IconCategories
} from '../../components';
import config from '../../config';
import { languagesHelper as language } from '../../helpers/languages';

import css from './AdvancedSearch.module.css';

const identity = v => v;

const IconFilterCategories = () => (
  <svg
    className={css.icon}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#2F4E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M14.667 4.33h-4M4 4.33H1.333M6.666 6.662a2.333 2.333 0 10.002-4.665 2.333 2.333 0 00-.002 4.665zM14.667 11.66H12M5.333 11.66h-4M9.333 13.991a2.333 2.333 0 10.002-4.665 2.333 2.333 0 00-.002 4.665z"
    ></path>
  </svg>
);

export const AdvancedSearchForm = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        onSubmit,
        getValues,
        guestsFilterOpen,
        applyGuestsValues,
        adultsCounter,
        kidsCounter,
        updateAdultsCounter,
        updateKidsCounter,
        updatesDatesFilter,
        datesFilter,
        clearGuestsCounter,
        totalGuests,
        topbarLayout,
        setMainCategory,
        mainCategory
      } = formRenderProps;


      const addressPlaceholderMessage = intl.formatMessage({
        id: 'AdvancedSearch.addressPlaceholder',
      });

      const addressPlaceholderMessageCurrent = intl.formatMessage({
        id: 'AdvancedSearch.yourCurrentLocation',
      });

      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;


      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const mainCategoriesOptions = config.custom.mainCategoriesOptions

      const {
        CAT_SLEEP,
        CAT_RENT,
        CAT_ACTIVITIES
      } = config.custom

      const currentLocationSelected = !!values?.location?.selectedPlace?.origin;
      const numberOfGuests = adultsCounter + kidsCounter;

      return (
        <Form className={css.rootForm} onSubmit={(e) => { e.preventDefault(), onSubmit }}>
          {errorMessage}
          {errorMessageShowListing}

          <LocationAutocompleteInputField
            className={classNames(css.advancedSearchLocation, { [css.advancedSearchLocationCurrent]: currentLocationSelected })}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            name="location"
            placeholder={currentLocationSelected ? addressPlaceholderMessageCurrent : addressPlaceholderMessage}
            useDefaultPredictions={true}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              // autocompleteSearchRequired(addressRequiredMessage),
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
            currentLocationSelected={currentLocationSelected}
          />

          <div className={css.searchFooter}>

            {topbarLayout ? (
              <Menu>
                <MenuLabel
                  className={classNames(css.categoriesLabelTopbar, { [css.categoriesLabelTopbarActive]: !!values?.mainCategory || mainCategory })}
                  isOpenClassName={css.categoriesLabelTopbarOpen}
                >
                  {!!values?.mainCategory || !!mainCategory ? (
                    <>
                      {values?.mainCategory || mainCategory}
                      <IconFilterCategories />
                    </>
                  ) : (
                    <>
                      <FormattedMessage id="AdvancedSearch.categoryMobile" />
                      <IconFilterCategories />
                    </>
                  )}
                </MenuLabel>
                <MenuContent className={css.categoriesItemsHolder}>
                  <MenuItem key="CAT_SLEEP">
                    <div className={css.categoriesItems} onClick={() => { setMainCategory(CAT_SLEEP) }}>
                      <IconCategories categoryIcon={CAT_SLEEP} />
                      <FormattedMessage id="AdvancedSearch.sleep" />
                    </div>
                  </MenuItem>
                  <MenuItem key="CAT_RENT">
                    <div className={css.categoriesItems} onClick={() => { setMainCategory(CAT_RENT) }}>
                      <IconCategories categoryIcon={CAT_RENT} />
                      <FormattedMessage id="AdvancedSearch.rent" />
                    </div>
                  </MenuItem>
                  <MenuItem key="CAT_ACTIVITIES">
                    <div className={css.categoriesItems} onClick={() => { setMainCategory(CAT_ACTIVITIES) }}>
                      <IconCategories categoryIcon={CAT_ACTIVITIES} />
                      <FormattedMessage id="AdvancedSearch.activities" />
                    </div>
                  </MenuItem>
                </MenuContent>
              </Menu>
            ) : (
              <div className={css.categories}>
                <label className={css.categoriesLabel}>
                  <FormattedMessage id="AdvancedSearch.category" />
                </label>
                <div className={css.categoriesItems}>
                  {mainCategoriesOptions.map(cat => {
                    return (
                      <FieldRadioButton
                        id={cat.key}
                        name="mainCategory"
                        label={language.labelsTranslator(cat.key, intl)}
                        value={cat.key}
                        className={css.categoriesItem}
                        categoryIcon={cat.icon}
                        key={cat.key}
                      />
                    )
                  })}
                </div>
              </div>
            )}


            <div className={css.guestsAndDates}>
              <div className={css.dates}>
                <BookingDateRangeFilter
                  id='dates'
                  name='dates'
                  label="Select date"
                  queryParamNames={["dates"]}
                  initialValues={datesFilter}
                  onSubmit={(values) => updatesDatesFilter(values)}
                  showAsPopup
                  showLabelIcon
                />
              </div>

              <OutsideClickHandler onOutsideClick={() => applyGuestsValues(false)} className={css.guests}>
                <div className={classNames({ [css.guestsOpen]: guestsFilterOpen }, { [css.guestsClosed]: !guestsFilterOpen })}>
                  <div
                    onClick={() => applyGuestsValues(!guestsFilterOpen)}
                    className={
                      classNames(
                        css.guestsMenuLabel,
                        { [css.guestsMenuLabelActive]: !!totalGuests }
                      )}
                  >
                    {!!totalGuests ? (totalGuests) : <FormattedMessage id="AdvancedSearch.addGuests" />}
                    <IconGuests />
                  </div>

                  <div className={css.guestsMenuContent}>

                    <div className={css.guestsItem}>
                      <span>
                        <FormattedMessage id="AdvancedSearch.adults" />
                      </span>
                      <div className={css.guestsItemCounter}>
                        <div
                          className={classNames({ [css.guestsItemCounterBlocked]: adultsCounter <= 0 })}
                          onClick={() => updateAdultsCounter()}
                        >
                          <IconMinus />
                        </div>
                        <span className={css.guestsItemCounterValue}>
                          {adultsCounter}
                        </span>
                        <div
                          onClick={() => updateAdultsCounter(true)}
                          className={classNames({ [css.guestsItemCounterBlocked]: numberOfGuests >= 20 })}
                        >
                          <IconPlus />
                        </div>
                      </div>
                    </div>
                    <div className={css.guestsItem}>
                      <span>
                        <FormattedMessage id="AdvancedSearch.kids" />
                      </span>
                      <div className={css.guestsItemCounter}>
                        <div
                          className={classNames({ [css.guestsItemCounterBlocked]: kidsCounter <= 0 })}
                          onClick={() => updateKidsCounter()}
                        >
                          <IconMinus />
                        </div>
                        <span className={css.guestsItemCounterValue}>
                          {kidsCounter}
                        </span>
                        <div
                          onClick={() => updateKidsCounter(true)}
                          className={classNames({ [css.guestsItemCounterBlocked]: numberOfGuests >= 20 })}
                        >
                          <IconPlus />
                        </div>
                      </div>
                    </div>
                    <div className={css.guestsBtnsHolder}>
                      <div
                        className={css.applyBtn}
                        onClick={() => applyGuestsValues(false)}
                      >
                        <FormattedMessage id="AdvancedSearch.apply" />
                      </div>
                      <div
                        className={css.clearBtn}
                        onClick={() => clearGuestsCounter()}
                      >
                        <FormattedMessage id="AdvancedSearch.clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>

            <Button
              className={css.submitButton}
              type="button"
              inProgress={submitInProgress}
              // disabled={submitDisabled}
              ready={submitReady}
              onClick={() => getValues(values)}
            >
              {topbarLayout ? (
                <IconSearch />
              ) : (
                <FormattedMessage id="AdvancedSearch.search" />
              )}
            </Button>
          </div>

        </Form>
      );
    }}
  />
);

export default compose(injectIntl)(AdvancedSearchForm);
