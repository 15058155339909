import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './BookingBreakdown.module.css';

const LineItenTotalPriceWithoutDiscountsMaybe = ({
  transaction,
  totalPriceLineItems,
  intl,
  totalAdditionServicesAmount,
}) => {
  const { amount, currency } = transaction.attributes.payinTotal;
  const payinTotal = transaction.attributes?.protectedData?.payinTotal ?? 0;
  const priceWithoutDiscount = totalPriceLineItems[0].lineTotal.amount;

  const totalDiscount = priceWithoutDiscount - (amount || payinTotal || 0) + totalAdditionServicesAmount;

  if (totalDiscount === 0) {
    return null;
  }

  const formattedPrice = formatMoney(intl, new Money(totalDiscount, currency));

  return (
    <>
      {/* <hr className={css.totalDivider} /> */}
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>
          <FormattedMessage id="BookingBreakdown.priceWithoutDiscount" />
        </div>
        <div className={css.totalPrice}>-{formattedPrice}</div>
      </div>
    </>
  );
};

export default LineItenTotalPriceWithoutDiscountsMaybe;
