import React from 'react';
import { bool, func } from 'prop-types';
import classNames from 'classnames';

import css from './SearchMapInfoCard.module.css';


const LikeIcon = ({ isFavourite }) => {
  const iconClasses = classNames(css.likeIcon, { [css.likeIconFavourite]: isFavourite });

  return (
    <svg
      className={iconClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#fff" rx="6"></rect>
      <path
        stroke="#2F4E1E"
        strokeWidth="1.3"
        d="M14.469 10.786l.467.453.452-.468.292-.305c.921-.964 1.506-1.576 2.732-1.717 1.283-.145 2.53.436 3.289 1.385.746.934 1.002 2.197.36 3.467h0c-.388.769-1.214 1.737-2.2 2.749h0a72.604 72.604 0 01-2.027 1.976c-.387.368-.754.716-1.08 1.038 0 0 0 0 0 0l-1.839 1.808-1.434-1.37s0 0 0 0c-.385-.367-.795-.737-1.214-1.116-.875-.791-1.791-1.619-2.606-2.535-1.195-1.343-2.029-2.73-2.086-4.193-.067-1.935 1.45-3.214 3.318-3.19.847.01 1.417.22 1.918.547.455.297.854.687 1.364 1.186l.294.285z"
      ></path>
    </svg>
  )
}

const AddToFavourites = ({ handleAddToFavourites, isFavourite }) => {
  return (
    <div className={css.likeIconButton} onClick={handleAddToFavourites}>
      <LikeIcon isFavourite={isFavourite} />
    </div>
  )
}


AddToFavourites.defaultProps = {
  isFavourite: false,
};

AddToFavourites.propTypes = {
  isFavourite: bool.isRequired,
  handleAddToFavourites: func.isRequired
};

export default AddToFavourites;
