import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './BookingBreakdown.module.css';

const LineItemMultipleItemsMaybe = ({
  listing,
  guests,
  intl,
  multipleDiscount,
}) => {
  const {
    discountMultipleItems
  } = listing?.attributes?.publicData?.discountOptions ?? {};
  const numberOfGuests = listing?.attributes?.publicData?.numberOfGuests ?? null;
  const { currency } = listing.attributes.price;
  const resultUnits = Math.ceil((guests.adults + guests.kids) / numberOfGuests);

  if (discountMultipleItems && resultUnits < +discountMultipleItems) {
    return null;
  }

  const formattedPrice = formatMoney(intl, new Money(multipleDiscount, currency));

  return (
    <div className={css.lineItemTotal}>
      <div className={css.totalLabel}>
        <FormattedMessage id="BookingBreakdown.discountMultipleItemsLabel" />
      </div>
      <div className={css.totalPrice}>-{formattedPrice}</div>
    </div>
  )
}

LineItemMultipleItemsMaybe.propTypes = {
  listing: propTypes.listing.isRequired,
};

export default LineItemMultipleItemsMaybe;
