import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { getDistance } from '../../util/maps';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, ReviewRating, IconReviewStar } from '../../components';

import css from './ListingCardVertical.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardVerticalComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    // listingAmenities,
    parentListingGeolocation,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  // const author = ensureUser(listing.author);
  // const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  // const unitType = config.bookingUnitType;
  const unitType =
    publicData.mainCategory === 'sleep' ? config.bookingUnitTypeNight : config.bookingUnitTypeDay;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
      ? 'ListingCard.perDay'
      : 'ListingCard.perUnit';

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const renderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const distanceFromTo =
    parentListingGeolocation &&
    currentListing?.attributes?.geolocation &&
    getDistance(currentListing?.attributes?.geolocation, parentListingGeolocation);

  const avgStarRating = publicData?.avgStarRating;
  const totalReviews = publicData?.totalReviews;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>

        <div className={css.ratingHolder}>
          {avgStarRating && totalReviews && (
            <div className={css.ratingHolder}>
              <IconReviewStar className={css.ratingStar} isFilled />
              <span>{avgStarRating}</span>
              <span className={css.ratingTotal}>({totalReviews})</span>
            </div>
          )}
        </div>

        {/* {!!distanceFromTo ? (
          <div className={css.description}>
            <FormattedMessage
              id="ListingCard.fromSearchingLocation"
              values={{ distance: distanceFromTo }}
            />
          </div>
        ) : (
          <div className={css.description}></div>
        )} */}

        <div className={css.priceHolder}>
          <div className={css.amenities}>
            {/* {!!listingAmenities?.length && listingAmenities.slice(0, 2).map(amenities => {
              return <IconAmenities iconKey={amenities} key={amenities} />
            })} */}
          </div>

          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div>
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardVerticalComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardVerticalComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardVerticalComponent);
