import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import image1 from './images/image1.jpg';
import image2 from './images/image2.jpg';
import image3 from './images/image3.jpg';
import image4 from './images/image4.jpg';

import css from './SectionBecomeHost.module.css';

const SectionBecomeHost = props => {

  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>

      <div className={css.topImages}>
        <img className={css.topImagesLeft} src={image1} alt="Become a host image 1" />
        <img className={css.topImagesRight} src={image2} alt="Become a host image 2" />
      </div>

      <div className={css.centralContent}>
        <h2 className={css.centralContentTitle}>
          <FormattedMessage id="SectionBecomeHost.title" />
        </h2>
        <h3 className={css.centralContentSubTitle}>
          <FormattedMessage id="SectionBecomeHost.subtitle" />
        </h3>

        <ul className={css.centralContentList}>
          <li className={css.centralContentListItem}>
            <FormattedMessage id="SectionBecomeHost.listItem1" />
          </li>
          <li className={css.centralContentListItem}>
            <FormattedMessage id="SectionBecomeHost.listItem2" />
          </li>
          <li className={css.centralContentListItem}>
            <FormattedMessage id="SectionBecomeHost.listItem3" />
          </li>
        </ul>

        <NamedLink name="BecomeAHostPage" className={css.centralContentListBtn}>
          <FormattedMessage id="SectionBecomeHost.btn" />
        </NamedLink>
      </div>

      <div className={css.bottomImages}>
        <img className={css.bottomImagesLeft} src={image3} alt="Become a host image 3" />
        <img className={css.bottomImagesRight} src={image4} alt="Become a host image 4" />
      </div>

    </div>
  );
};

SectionBecomeHost.defaultProps = { rootClassName: null, className: null };

SectionBecomeHost.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionBecomeHost;
