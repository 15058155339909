import React, { useState } from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';

import css from './AdvancedSearch.module.css';
import AdvancedSearchForm from './AdvancedSearchForm';
import classNames from 'classnames';

const AdvancedSearch = props => {
  const { intl, topbarLayout, currentPage } = props;

  if (
    currentPage === 'LandingPage' ||
    currentPage === 'SearchPage' ||
    currentPage === 'BecomeAHostPage'
  ) {
    return null;
  }

  const [guestsFilterOpen, setGuestsFilterOpen] = useState(false);
  const [adultsCounter, setAdultsCounter] = useState(0);
  const [kidsCounter, setKidsCounter] = useState(0);
  const [totalGuests, setTotalGuests] = useState(0);
  const [datesFilter, setDatesFilter] = useState({});

  const [mainCategory, setMainCategory] = useState(null);

  const updateAdultsCounter = increment => {
    if (!!increment) {
      setAdultsCounter(adultsCounter + 1);
    } else {
      setAdultsCounter(adultsCounter - 1);
    }
  };

  const updateKidsCounter = increment => {
    if (!!increment) {
      setKidsCounter(kidsCounter + 1);
    } else {
      setKidsCounter(kidsCounter - 1);
    }
  };

  const updatesDatesFilter = dates => {
    setDatesFilter(dates);
  };

  const onSubmit = () => {
    return;
  };

  const applyGuestsValues = status => {
    setTotalGuests(+adultsCounter + +kidsCounter);
    setGuestsFilterOpen(status);
  };

  const clearGuestsCounter = () => {
    setAdultsCounter(0);
    setKidsCounter(0);
    setTotalGuests(0);
  };

  const getValues = values => {
    const { history } = props;

    const guestsTotalCounter =
      !!adultsCounter || !!kidsCounter
        ? `${adultsCounter + kidsCounter},${(config.guestsMaxLimit ).toString()}`
        : null;

    const searchParams = {
      address: !!values?.location?.selectedPlace?.address
        ? values?.location?.selectedPlace?.address
        : null,
      bounds: !!values?.location?.selectedPlace?.bounds
        ? values?.location?.selectedPlace?.bounds
        : null,
      origin: !!values?.location?.selectedPlace?.origin
        ? values?.location?.selectedPlace?.origin
        : null,
      pub_mainCategory: !!mainCategory
        ? mainCategory
        : !!values?.mainCategory
        ? values?.mainCategory
        : null,
      dates: !!datesFilter?.dates ? datesFilter.dates : null,
      // pub_numberOfGuests: !!guestsTotalCounter ? guestsTotalCounter : null,
      pub_totalSeats: !!guestsTotalCounter ? guestsTotalCounter : null,
    };
    !!history &&
      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      );
  };

  return (
    <div className={classNames(css.root, { [css.rootTopbar]: !!topbarLayout })}>
      <AdvancedSearchForm
        topbarLayout={topbarLayout}
        onSubmit={onSubmit}
        getValues={getValues}
        intl={intl}
        mainCategory={mainCategory}
        setMainCategory={setMainCategory}
        adultsCounter={adultsCounter}
        kidsCounter={kidsCounter}
        guestsFilterOpen={guestsFilterOpen}
        updateAdultsCounter={updateAdultsCounter}
        updateKidsCounter={updateKidsCounter}
        applyGuestsValues={applyGuestsValues}
        clearGuestsCounter={clearGuestsCounter}
        totalGuests={totalGuests}
        updatesDatesFilter={updatesDatesFilter}
        datesFilter={datesFilter}
      />
    </div>
  );
};

AdvancedSearch.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AdvancedSearch);
