import { storableError } from '../../util/errors';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

const RESULT_PAGE_SIZE = 6;

const formatSdkListingsResponse = (listings, relationships) => {
  return listings.map(listing => {
    const imagesIds = listing.relationships.images.data.map(img => img.id.uuid);

    const images = relationships.filter(
      relations => relations.type === "image" && imagesIds.includes(relations.id.uuid)
    );

    return { ...listing, images: images };
  });
}

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/FavouriteListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/FavouriteListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/FavouriteListingsPage/FETCH_LISTINGS_ERROR';

export const UPDATE_LISTINGS_REQUEST = 'app/FavouriteListingsPage/UPDATE_LISTINGS_REQUEST';
export const UPDATE_LISTINGS_SUCCESS = 'app/FavouriteListingsPage/UPDATE_LISTINGS_SUCCESS';
export const UPDATE_LISTINGS_ERROR = 'app/FavouriteListingsPage/UPDATE_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  listings: [],
  queryListingsError: null,
  updateListingError: null,
};


export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: formatSdkListingsResponse(payload.data.data, payload.data.included),
        pagination: payload.data.meta
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryListingsError: payload };

    case UPDATE_LISTINGS_REQUEST:
      return state;
    case UPDATE_LISTINGS_SUCCESS:
      return {
        ...state,
        updateListingError: null,
      };
    case UPDATE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, updateListingError: payload };

    default:
      return state;
  }
};

// ================ Action creators ================ //

export const queryListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});

export const queryListingsSuccess = payload => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload,
});
export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const updateListingsRequest = () => ({
  type: UPDATE_LISTINGS_REQUEST,
});
export const updateListingsSuccess = () => ({
  type: UPDATE_LISTINGS_SUCCESS,
});
export const updateListingsError = e => ({
  type: UPDATE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const getFavouriteListings = (favourites, page) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  try {

    const params = {
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'limit.images': 10,
      ids: favourites
    }

    sdk.listings.query(params)
      .then(res => {
        dispatch(queryListingsSuccess(res));
      });

  } catch(e) {
    dispatch(queryListingsError(storableError(e)));
  }
}

export const updateFavouritesList = (favourites, page) => (dispatch) => {
  dispatch(updateListingsRequest());

  try {
    const updatedData = {
      publicData: {
        favourites,
      }
    };

    dispatch(updateProfile(updatedData));
    dispatch(updateListingsSuccess());
    dispatch(getFavouriteListings(favourites, page));
  } catch(e) {
    dispatch(updateListingsError(storableError(e)));
  }
}
