import React, { Component } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { SelectRangeFilterForm } from '../../forms';
import { languagesHelper as language } from '../../helpers/languages';
import { Menu, MenuContent, MenuItem, MenuLabel } from '..';
import css from './SelectRangeFilterPopup.module.css';

const RADIX = 10;

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : queryParamNames;
};

// Parse value, which should look like "0,1000"
const parse = priceRange => {
  const [minRange, maxRange] = !!priceRange
    ? priceRange.split(',').map(v => Number.parseInt(v, RADIX))
    : [];
  // Note: we compare to null, because 0 as minPrice is falsy in comparisons.
  return !!priceRange && minRange != null && maxRange != null ? { minRange, maxRange } : null;
};

// Format value, which should look like { minPrice, maxPrice }
const format = (range, queryParamName) => {
  const { minRange, maxRange } = range || {};
  // Note: we compare to null, because 0 as minPrice is falsy in comparisons.
  const value = minRange != null && maxRange != null ? `${minRange},${maxRange}` : null;
  return { [queryParamName]: value };
};


const IconGuests = () => (
  <svg
    className={css.icon}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      stroke="#2F4E1E"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.87 8.152a1.363 1.363 0 00-.248 0A3.315 3.315 0 013.42 4.83 3.327 3.327 0 016.75 1.5a3.327 3.327 0 01.12 6.652zM12.307 3a2.623 2.623 0 012.625 2.625 2.629 2.629 0 01-2.527 2.625.847.847 0 00-.195 0M3.12 10.92c-1.815 1.215-1.815 3.195 0 4.402 2.063 1.38 5.445 1.38 7.508 0 1.815-1.214 1.815-3.194 0-4.402-2.055-1.373-5.438-1.373-7.508 0zM13.755 15c.54-.113 1.05-.33 1.47-.652 1.17-.878 1.17-2.325 0-3.203-.413-.315-.915-.525-1.448-.645"
    ></path>
  </svg>
);

class SelectRangeFilterPopupComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  selectOption(queryParamName, option) {
    this.setState({ isOpen: false });
    this.props.onSelect({ [queryParamName]: option });
  }


  handleChange(values) {
    const { onSubmit, queryParamNames } = this.props;

    // to include max value from public data
    const valuesUpdated = {
      minRange: 20,
      maxRange: 21
    }

    const queryParamName = getQueryParamName(queryParamNames);
    onSubmit(format(values.minRange === 21 ? valuesUpdated : values, queryParamName));
  }

  handleClear() {
    const { onSubmit, queryParamNames } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    onSubmit(format(null, queryParamName), true);
  }

  toggleIsOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }


  render() {
    const {
      rootClassName,
      className,
      label,
      labelId,
      options,
      queryParamNames,
      initialValues,
      contentPlacementOffset,
      id,
      min,
      max,
      step,
      intl,
      unitsLabel,
      useSplitFormat,
    } = this.props;

    const initialValue =
      initialValues && initialValues[queryParamNames] ? initialValues[queryParamNames] : null;

    const classes = classNames(rootClassName || css.root, className);

    const queryParamName = getQueryParamName(queryParamNames);

    const initialSelectRange = initialValues ? parse(initialValues[queryParamName]) : {};

    const { minRange, maxRange } = initialSelectRange || {};

    const hasValue = value => value != null;
    const hasInitialValues = initialValues && hasValue(minRange) && hasValue(maxRange);

    // resolve menu label text and class
    const menuLabel = initialValue ? `${minRange === 21 ? 20 : minRange}` : language.labelsTranslator(labelId, intl);
    const menuLabelClass = initialValue ? css.menuLabelSelected : css.menuLabel;

    // to include max value from public data

    return (
      <Menu
        className={classes}
        useArrow={false}
        contentPlacementOffset={contentPlacementOffset}
        onToggleActive={this.onToggleActive}
        isOpen={this.state.isOpen}
      >
        <MenuLabel className={menuLabelClass}>
          {minRange === 0 ? <FormattedMessage id='SelectRangeForm.notSelected' />: menuLabel}
          <IconGuests />
        </MenuLabel>
        <MenuContent className={css.menuContent}>
          <MenuItem key="SelectRangeFilterPopup">
            <SelectRangeFilterForm
              id={id}
              initialValues={hasInitialValues ? initialSelectRange : { minRange: min, maxRange: max }}
              onChange={this.handleChange}
              intl={intl}
              contentRef={node => {
                this.filterContent = node;
              }}
              min={min}
              max={max}
              step={step}
              liveEdit
              isOpen={this.state.isOpen}
              label={language.labelsTranslator(labelId, intl)}
              unitsLabel={unitsLabel}
              useSplitFormat={useSplitFormat}
            />
          </MenuItem>

          <MenuItem key={'clearLink'}>
            <button
              className={css.clearMenuItem}
              onClick={() => this.selectOption(queryParamName, null)}
            >
              <FormattedMessage id='SelectRangeForm.clear' />
            </button>
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  }
}

SelectRangeFilterPopupComponent.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectRangeFilterPopupComponent.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string),
  label: node,
  onSelect: func,
  options: arrayOf(
    shape({
      key: string,
      label: string,
    })
  ),
  initialValues: object,
  contentPlacementOffset: number,
};

const SelectRangeFilterPopup = injectIntl(SelectRangeFilterPopupComponent);

export default SelectRangeFilterPopup;
