import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCheckmarkAlt.module.css';

const IconCheckmarkAlt = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      fill="none"
      viewBox="0 0 10 8"
    >
      <path
        fill="#65BB47"
        d="M0 4.298L3.5 8l6.336-6.631L8.568 0 3.5 5.25 1.256 2.923 0 4.298z"
      ></path>
    </svg>
  );
};

IconCheckmarkAlt.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCheckmarkAlt.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCheckmarkAlt;
