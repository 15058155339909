import React, { Component } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { languagesHelper as language } from '../../helpers/languages';
import { Menu, MenuContent, MenuItem, MenuLabel } from '..';
import css from './SelectSingleFilterPopup.module.css';

const optionLabel = (options, key) => {
  const option = options.find(o => o.key === key);
  return option ? option.label : key;
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectSingleFilterPopupComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  selectOption(queryParamName, option) {
    this.setState({ isOpen: false });
    this.props.onSelect({ [queryParamName]: option });
  }

  render() {
    const {
      idWithoutPrefix,
      rootClassName,
      className,
      label,
      labelId,
      options,
      queryParamNames,
      initialValues,
      contentPlacementOffset,
      urlQueryParams,
      intl
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamNames] ? initialValues[queryParamNames] : null;

    // resolve menu label text and class
    // const menuLabel = initialValue ? optionLabel(options, initialValue) : label;
    // const menuLabel = label;
    const menuLabel = language.labelsTranslator(labelId, intl);
    const menuLabelClass = initialValue ? css.menuLabelSelected : css.menuLabel;

    const classes = classNames(rootClassName || css.root, className);

    const {
      categoriesForSleep,
      categoriesForRent,
      categoriesForActivities,
      CAT_SLEEP,
      CAT_RENT,
      CAT_ACTIVITIES
    } = config.custom

    let optionForFilter;

    if (urlQueryParams?.pub_mainCategory === CAT_SLEEP && idWithoutPrefix === "category") {
      optionForFilter = categoriesForSleep
    } else if (urlQueryParams?.pub_mainCategory === CAT_RENT && idWithoutPrefix === "category") {
      optionForFilter = categoriesForRent
    } else if (urlQueryParams?.pub_mainCategory === CAT_ACTIVITIES && idWithoutPrefix === "category") {
      optionForFilter = categoriesForActivities
    } else {
      optionForFilter = options
    }

    return (
      <Menu
        className={classes}
        useArrow={false}
        contentPlacementOffset={contentPlacementOffset}
        onToggleActive={this.onToggleActive}
        isOpen={this.state.isOpen}
      >
        <MenuLabel className={menuLabelClass}>{menuLabel}</MenuLabel>
        <MenuContent className={classNames(css.menuContent, { [css.menuContentTwoColum]: options?.length > 10 })}>
          {optionForFilter.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            // menu item border class
            const menuItemBorderClass = selected ? css.menuItemBorderSelected : css.menuItemBorder;

            return (
              <MenuItem key={option.key}>
                <button
                  className={css.menuItem}
                  onClick={() => this.selectOption(queryParamName, option.key)}
                >
                  <span className={menuItemBorderClass} />
                  {/* {option.label} */}
                  {language.labelsTranslator(option.key, intl)}
                </button>
              </MenuItem>
            );
          })}
          <MenuItem key={'clearLink'}>
            <button
              className={css.clearMenuItem}
              onClick={() => this.selectOption(queryParamName, null)}
            >
              <FormattedMessage id={'SelectSingleFilter.popupClear'} />
            </button>
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  }
}

SelectSingleFilterPopupComponent.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectSingleFilterPopupComponent.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  contentPlacementOffset: number,
};

const SelectSingleFilterPopup = injectIntl(SelectSingleFilterPopupComponent);

export default SelectSingleFilterPopup;
