import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';

import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  const {
    mainCategoriesOptions,
    categoriesForSleep,
    categoriesForRent,
    categoriesForActivities,
    CAT_SLEEP,
    CAT_RENT,
    CAT_ACTIVITIES
  } = config.custom

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{ 
          mainCategory: publicData?.mainCategory || mainCategoriesOptions?.[0]?.key,
          title, 
          description, 
          category: publicData?.category, 
          type: publicData?.type, 
          hostLanguage: publicData?.hostLanguage,
          activitiesOtherdesc: publicData?.activitiesOtherdesc,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, description, mainCategory, category, type, hostLanguage, activitiesOtherdesc } = values;

          const catSleepSelected = categoriesForSleep.find(cat => cat.key === category)
          const catRentSelected = categoriesForRent.find(cat => cat.key === category)
          const catActivitiesSelected = categoriesForActivities.find(cat => cat.key === category)

          let categoryValue = null

          if(mainCategory === CAT_RENT && catRentSelected) {
            categoryValue = category
          } else if(mainCategory === CAT_ACTIVITIES && catActivitiesSelected) {
            categoryValue = category
          } else if(mainCategory === CAT_SLEEP && catSleepSelected) {
            categoryValue = category
          }

          const updateValues = {
            title: title.trim(),
            description,
            publicData: { 
              mainCategory, 
              category: categoryValue,
              type: mainCategory === CAT_SLEEP ? type : null,
              hostLanguage,
              activitiesOtherdesc: mainCategory === CAT_ACTIVITIES ? activitiesOtherdesc : null,
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        mainCategories={mainCategoriesOptions}
        mainCategorySelected={!!publicData?.mainCategory}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
