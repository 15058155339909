import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { IconCategories, IconAmenities } from '../../components';

import css from './FieldCheckbox.module.css';

const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg className={className} width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect
          className={checkedClassName || css.checked}
          width="21"
          height="21"
          x="0.5"
          y="0.5"
          stroke="#E4E4E4"
          rx="3.5"
        ></rect>
        <rect
          className={boxClassName || css.box}
          width="21"
          height="21"
          x="0.5"
          y="0.5"
          stroke="#E4E4E4"
          rx="3.5"
        ></rect>
        <path
          fill="#fff"
          d="M0 4.298L3.5 8l6.336-6.631L8.568 0 3.5 5.25 1.256 2.923 0 4.298z"
          transform="translate(6,6)"
        ></path>
      </g>
    </svg>
  );
};



IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    categoryIcon,
    showAmenitiesIcon,
    amenitiesIcon,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
      checkedClassName: css.checkedSuccess,
      boxClassName: css.boxSuccess,
    }
    : {};

  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const input = props.input;
          return (
            <input
              id={id}
              className={css.input}
              {...input}
              onChange={event => handleOnChange(input, event)}
            />
          );
        }}
      </Field>
      <label htmlFor={id} className={classNames(css.label, { [css.labelWithImage]: categoryIcon })}>
        <span className={css.checkboxWrapper}>
          {!!categoryIcon ? <IconCategories categoryIcon={categoryIcon} /> : <IconCheckbox className={svgClassName} {...successColorVariantMaybe} />}
          {showAmenitiesIcon && <IconAmenities iconKey={amenitiesIcon} className={css.amenitiesIcon} />}
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );

};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxComponent;
