import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 30;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const ADD_LISTINGS_TO_FAVOURITES_REQUEST =
  'app/ListingPage/ADD_LISTINGS_TO_FAVOURITES_REQUEST';
export const ADD_LISTINGS_TO_FAVOURITES_SUCCESS =
  'app/ListingPage/ADD_LISTINGS_TO_FAVOURITES_SUCCESS';
export const ADD_LISTINGS_TO_FAVOURITES_ERROR = 'app/ListingPage/ADD_LISTINGS_TO_FAVOURITES_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,

  favourite: false,
  favouriteRequestError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case ADD_LISTINGS_TO_FAVOURITES_REQUEST:
      return { ...state, favouriteRequestError: null };
    case ADD_LISTINGS_TO_FAVOURITES_SUCCESS:
      return { ...state, favourite: true };
    case ADD_LISTINGS_TO_FAVOURITES_ERROR:
      return { ...state, favouriteRequestError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const addListingsToFavouriteRequest = () => ({ type: ADD_LISTINGS_TO_FAVOURITES_REQUEST });
export const addListingsToFavouriteSuccess = () => ({ type: ADD_LISTINGS_TO_FAVOURITES_SUCCESS });
export const addListingsToFavouriteError = e => ({
  type: ADD_LISTINGS_TO_FAVOURITES_ERROR,
  error: true,
  payload: e,
});

const filterListingBySeats = async ({ params, apiResponse, sdk }) => {
  const { page, per_page, start, end, pub_totalSeats, ...rest } = params;
  let totalPages = 1;
  const totalSeats = +pub_totalSeats.split(',')[0];
  let included = [];

  let totalListings = [];
  for (let i = 1; i <= totalPages; i++) {
    const localParams = {
      ...rest,
      start,
      end,
      page: i,
    }

    const nextResponse = await sdk.listings.query(localParams);

    totalPages = nextResponse.data.meta.totalPages;
    totalListings = totalListings.concat(nextResponse.data.data);
    included = included.concat(nextResponse.data.included);
  }

  const result = [];

  for (let i = 0; i < totalListings.length; i++) {

    const guests = totalListings[i].attributes.publicData.numberOfGuests;
    const timeSlotParams = {
      listingId: totalListings[i].id.uuid,
      start,
      end,
    }

    const timeSlot = await sdk.timeslots.query(timeSlotParams);

    const exist = timeSlot.data.data.find(({ attributes }) => attributes.seats * guests >= totalSeats);

    if (exist) {
      result.push(totalListings[i]);
    }

  }

  apiResponse.data.data = result.slice((page - 1) * per_page, per_page * page);
  apiResponse.data.meta.totalItems = result.length;
  apiResponse.data.included = included;
  apiResponse.data.meta.totalPages = Math.ceil(result.length / per_page);
  return apiResponse;
}

export const searchListings = searchParams => async (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    // const isNightlyBooking = config.bookingUnitType === 'line-item/night';
    const isNightlyBooking = false;
    const endDate =
      hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

    return hasValues
      ? {
          start: formatDateStringToUTC(startDate),
          end: formatDateStringToUTC(endDate),
          // Availability can be full or partial. Default value is full.
          availability: 'full',
        }
      : {};
  };

  const { perPage, price, dates, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    per_page: perPage,
  };

  try {
    let response = await sdk.listings.query(params);

    if (params.start && params.end && params.pub_totalSeats) {
      response = await filterListingBySeats({
        params,
        apiResponse: response,
        sdk,
      });
    }

    dispatch(addMarketplaceEntities(response));
    dispatch(searchListingsSuccess(response));

    return response;
  } catch (e) {
    dispatch(searchListingsError(storableError(e)));
    throw e;
  }

  // return sdk.listings
  //   .query(params)
  //   .then(response => {
  //     dispatch(addMarketplaceEntities(response));
  //     dispatch(searchListingsSuccess(response));
  //     return response;
  //   })
  //   .catch(e => {
  //     dispatch(searchListingsError(storableError(e)));
  //     throw e;
  //   });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 10,
  });
};

export const addToFavourites = favourites => dispatch => {
  dispatch(addListingsToFavouriteRequest());
  try {
    const updatedData = {
      publicData: {
        favourites,
      },
    };

    dispatch(updateProfile(updatedData));
    dispatch(addListingsToFavouriteSuccess());
  } catch (e) {
    dispatch(addListingsToFavouriteError(storableError(e)));
  }
};
