import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import Slider from "react-slick";
import classNames from 'classnames';

import slide1 from './images/slide1.jpg';
import slide2 from './images/slide2.jpg';
import slide3 from './images/slide3.jpg';

import { IconSliderArrow } from '../../components';

import css from './SectionHero.module.css';

const SectionHero = props => {

  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    fade: true,
    nextArrow: <IconSliderArrow direction="next" />,
    prevArrow: <IconSliderArrow />
  };

  const sliderItems = [
    { image: slide1, title: "SectionHero.title1", subTitle: null },
    { image: slide2, title: "SectionHero.title2", subTitle: "SectionHero.subtitle2" },
    { image: slide3, title: "SectionHero.title3", subTitle: null },
  ]

  return (
    <div className={classes}>
      <Slider {...sliderSettings}>
        {sliderItems.map((s, i) => (
          <div className={classNames(css.heroItem, {[css.heroItemSecond] : i === 1})} key={s.title} >
            <h3 className={css.heroItemTitle}>
              <FormattedMessage id={s.title} />
            </h3>
            {s.subTitle && (
              <p className={css.heroItemSubTitle}>
                <FormattedMessage id={s.subTitle} values={{lineBreak: <br/>}}/>
              </p>
            )}
            <img className={css.heroItemImg} src={s.image} alt="Wacampa hero image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
