import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconFavorite.module.css';

const IconFavorite = props => {
  const { isFavorite, iconClass } = props

  const classes = classNames(css.root, iconClass, { [css.rootActive]: isFavorite });

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 42 42"
    >
      <rect
        width="40.5"
        height="40.5"
        x="0.75"
        y="0.75"
        stroke="#DBEAD3"
        strokeWidth="1.5"
        rx="11.25"
      ></rect>
      <path
        stroke="#2F4E1E"
        strokeWidth="2"
        d="M20.413 14.966l.72.696.695-.72.433-.451c1.368-1.432 2.227-2.331 4.032-2.538 1.893-.214 3.73.642 4.85 2.042 1.1 1.376 1.476 3.236.53 5.107h0c-.573 1.135-1.795 2.569-3.258 4.07h0a107.439 107.439 0 01-3.005 2.931c-.576.546-1.12 1.063-1.607 1.542 0 0 0 0 0 0L21.1 30.304l-2.103-2.008s0 0 0 0a90.213 90.213 0 00-1.804-1.66c-1.298-1.173-2.655-2.4-3.863-3.758-1.77-1.99-3.002-4.04-3.087-6.2-.098-2.847 2.131-4.732 4.888-4.698 1.25.017 2.09.326 2.828.807.672.439 1.26 1.014 2.019 1.755l.435.424z"
      ></path>
    </svg>
  );
};

IconFavorite.defaultProps = {
  rootClassName: null,
  className: null,
  fillColor: null,
};

IconFavorite.propTypes = {
  rootClassName: string,
  className: string,
  fillColor: string,
};

export default IconFavorite;
