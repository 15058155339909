import React from 'react';
import { bool } from 'prop-types';
import SelectSingleFilterPlain from './SelectSingleFilterPlain';
import SelectSingleFilterPopup from './SelectSingleFilterPopup';

const SelectSingleFilter = props => {
  const { showAsPopup, idWithoutPrefix, categorySleepSelected, ...rest } = props;

  if ((categorySleepSelected && idWithoutPrefix === "type") || idWithoutPrefix === "category") {
    return showAsPopup ? (
      <SelectSingleFilterPopup idWithoutPrefix={idWithoutPrefix} {...rest} />
    ) : (
      <SelectSingleFilterPlain idWithoutPrefix={idWithoutPrefix} {...rest} />
    );
  } else {
    return null
  }

};

SelectSingleFilter.defaultProps = {
  showAsPopup: false,
};

SelectSingleFilter.propTypes = {
  showAsPopup: bool,
};

export default SelectSingleFilter;
