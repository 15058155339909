import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';

import css from './IconMapMarker.module.css';

const IconMapMarker = props => {
  const { rootClassName, className, markerCategory, showMarkerLabel } = props;
  const classes = classNames(rootClassName || css.root, className);

  const { CAT_SLEEP, CAT_RENT, CAT_ACTIVITIES } = config.custom;

  if (markerCategory === CAT_SLEEP) {
    return (
      <div className={classes}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="32"
          fill="none"
          viewBox="0 0 24 32"
        >
          <circle cx="11.29" cy="13.42" r="8.162" fill="#fff"></circle>
          <path
            fill="#2F4E1E"
            stroke="#fff"
            d="M13.01 31.077l.026-.02a22.725 22.725 0 006.47-6.222 23.137 23.137 0 003.614-8.35h0l.001-.006c.767-3.774.401-7.554-1.384-10.544a10.895 10.895 0 00-1.947-2.412h0C17.61 1.504 15.075.52 12.49.5h0c-2.676-.02-5.342.988-7.657 2.865A11.388 11.388 0 002.327 6.19h0C.665 8.848.174 12.092.704 15.43v.001c.533 3.286 2.038 6.68 4.434 9.735v.001a26.813 26.813 0 006.556 5.954 1.148 1.148 0 001.316-.044zM12.002 7.264a4.92 4.92 0 012.764.847 5.06 5.06 0 011.844 2.276c.38.93.481 1.953.29 2.94a5.11 5.11 0 01-1.364 2.606 4.958 4.958 0 01-2.551 1.391 4.9 4.9 0 01-2.878-.286 5.007 5.007 0 01-2.24-1.868 5.147 5.147 0 01-.844-2.827c0-1.348.527-2.64 1.461-3.592a4.94 4.94 0 013.518-1.487v0z"
          ></path>
        </svg>
        {showMarkerLabel && (
          <span>
            <FormattedMessage id="IconMapMarker.sleep" />
          </span>
        )}
      </div>
    )
  }
  else if (markerCategory === CAT_RENT) {
    return (
      <div className={classes}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="32"
          fill="none"
          viewBox="0 0 24 32"
        >
          <circle cx="11.29" cy="13.42" r="8.162" fill="#fff"></circle>
          <path
            fill="#D3883E"
            stroke="#fff"
            d="M13.01 31.077l.026-.02a22.725 22.725 0 006.47-6.222 23.137 23.137 0 003.614-8.35h0l.001-.006c.767-3.774.401-7.554-1.384-10.544a10.895 10.895 0 00-1.947-2.412h0C17.61 1.504 15.075.52 12.49.5h0c-2.676-.02-5.342.988-7.657 2.865A11.388 11.388 0 002.327 6.19h0C.665 8.848.174 12.092.704 15.43v.001c.533 3.286 2.038 6.68 4.434 9.735v.001a26.813 26.813 0 006.556 5.954 1.148 1.148 0 001.316-.044zM12.002 7.264a4.92 4.92 0 012.764.847 5.06 5.06 0 011.844 2.276c.38.93.481 1.953.29 2.94a5.11 5.11 0 01-1.364 2.606 4.958 4.958 0 01-2.551 1.391 4.9 4.9 0 01-2.878-.286 5.007 5.007 0 01-2.24-1.868 5.147 5.147 0 01-.844-2.827c0-1.348.527-2.64 1.461-3.592a4.94 4.94 0 013.518-1.487v0z"
          ></path>
        </svg>
        {showMarkerLabel && (
          <span>
            <FormattedMessage id="IconMapMarker.rent" />
          </span>
        )}
      </div>
    )
  }
  else if (markerCategory === CAT_ACTIVITIES) {
    return (
      <div className={classes}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="32"
          fill="none"
          viewBox="0 0 24 32"
        >
          <circle cx="11.29" cy="13.42" r="8.162" fill="#fff"></circle>
          <path
            fill="#E8CA52"
            stroke="#fff"
            d="M13.01 31.077l.026-.02a22.725 22.725 0 006.47-6.222 23.137 23.137 0 003.614-8.35h0l.001-.006c.767-3.774.401-7.554-1.384-10.544a10.895 10.895 0 00-1.947-2.412h0C17.61 1.504 15.075.52 12.49.5h0c-2.676-.02-5.342.988-7.657 2.865A11.388 11.388 0 002.327 6.19h0C.665 8.848.174 12.092.704 15.43v.001c.533 3.286 2.038 6.68 4.434 9.735v.001a26.813 26.813 0 006.556 5.954 1.148 1.148 0 001.316-.044zM12.002 7.264a4.92 4.92 0 012.764.847 5.06 5.06 0 011.844 2.276c.38.93.481 1.953.29 2.94a5.11 5.11 0 01-1.364 2.606 4.958 4.958 0 01-2.551 1.391 4.9 4.9 0 01-2.878-.286 5.007 5.007 0 01-2.24-1.868 5.147 5.147 0 01-.844-2.827c0-1.348.527-2.64 1.461-3.592a4.94 4.94 0 013.518-1.487v0z"
          ></path>
        </svg>
        {showMarkerLabel && (
          <span>
            <FormattedMessage id="IconMapMarker.activities" />
          </span>
        )}
      </div>
    )
  }
  else {
    return (
      <div className={classes}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="32"
          fill="none"
          viewBox="0 0 24 32"
        >
          <circle cx="11.29" cy="13.42" r="8.162" fill="#fff"></circle>
          <path
            fill="#2F4E1E"
            stroke="#fff"
            d="M13.01 31.077l.026-.02a22.725 22.725 0 006.47-6.222 23.137 23.137 0 003.614-8.35h0l.001-.006c.767-3.774.401-7.554-1.384-10.544a10.895 10.895 0 00-1.947-2.412h0C17.61 1.504 15.075.52 12.49.5h0c-2.676-.02-5.342.988-7.657 2.865A11.388 11.388 0 002.327 6.19h0C.665 8.848.174 12.092.704 15.43v.001c.533 3.286 2.038 6.68 4.434 9.735v.001a26.813 26.813 0 006.556 5.954 1.148 1.148 0 001.316-.044zM12.002 7.264a4.92 4.92 0 012.764.847 5.06 5.06 0 011.844 2.276c.38.93.481 1.953.29 2.94a5.11 5.11 0 01-1.364 2.606 4.958 4.958 0 01-2.551 1.391 4.9 4.9 0 01-2.878-.286 5.007 5.007 0 01-2.24-1.868 5.147 5.147 0 01-.844-2.827c0-1.348.527-2.64 1.461-3.592a4.94 4.94 0 013.518-1.487v0z"
          ></path>
        </svg>
      </div>
    )
  }

};

IconMapMarker.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconMapMarker.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconMapMarker;
