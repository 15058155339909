
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { IconFavorite } from '../../components';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { setFavoriteListingIdToUserProfile } from '../../ducks/user.duck';

import css from './AddFavorites.module.css';

const AddFavoritesComponent = props => {
  const {
    listing,
    currentUser,
    onUpdateProfile,
    favoritesListingsInProgress,
    className,
    showDeleteIcon,
    deleteFromFavorites,
    buttonWithoutText
  } = props;

  const listingId = listing?.id?.uuid;
  const favoriteListings = currentUser?.attributes?.profile?.publicData?.favourites || [];
  const isFavorite = favoriteListings.includes(listingId);

  const setListingIdToProfile = () => {
    if (favoritesListingsInProgress) {
      return;
    }
    onUpdateProfile(listingId);
  }

  if (showDeleteIcon) {
    return (
      <div
        className={classNames(css.root, className, { [css.simpleIcon]: buttonWithoutText })}
        onClick={() => deleteFromFavorites(listingId)}
      >
        <IconFavorite isFavorite={isFavorite} />
        <FormattedMessage id={"ListingCard.deleteFromFavorites"} />
      </div>
    );
  } else {
    return (
      <div
        className={className}
        onClick={setListingIdToProfile}
      >
        <IconFavorite isFavorite={isFavorite} iconClass={classNames(css.icon, { [css.iconActive]: isFavorite })} />

      </div>
    );
  }

};

const mapStateToProps = state => {
  const {
    currentUser,
    favoritesListingsInProgress
  } = state.user;

  return {
    currentUser,
    favoritesListingsInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(setFavoriteListingIdToUserProfile(data)),
});

const AddFavorites = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AddFavoritesComponent);

export default AddFavorites;
