import React from 'react';

const RemainingSeats = ({ seats, intl }) => {
  const remainingSeats = intl.formatMessage({
    id: 'BookingDatesForm.remainingSeats',
  }, { seats: seats });

  return (
    <div>
      <span>{remainingSeats}</span>
    </div>
  )
}

export default RemainingSeats;
