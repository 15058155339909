import React, { useEffect, useState } from 'react';
import { func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { FieldCheckbox, IconPlus, IconClose } from '..';
import { Form as FinalForm, FormSpy, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { languagesHelper as language } from '../../helpers/languages';
import css from './FieldCheckboxItems.module.css';

const fieldsArrayName = 'options'
const selectedName = 'selected'
const titleName = 'title'

const locale = language.get();

const createName = (name, type) => `${name}.${type}`;

const FieldRow = props => {
  const { fields, name, intl, index, twoColums } = props

  const deleteService = () => fields.remove(index)

  let title = fields.value[index].title
  let titlePl = fields.value[index].titlePl
  let selected = fields.value[index].selected
  let other = fields.value[index].other

  const cName = createName(name, selectedName);
  const tName = createName(name, titleName);

  return (
    <div className={classNames(css.services, {[css.servicesTwoColums] : twoColums})}>
      <div className={css.servicesTitle}>
        <FieldCheckbox
          className={css.checkbox}
          id={cName}
          name={cName}
          label={locale === "pl" ? titlePl : title}
          value={selectedName}
        />
        <Field name={tName} type={'hidden'}
          component={props => <input {...props} type={"hidden"} />} />
      </div>
      <div className={css.servicesAction}>
        {other ?
          <div onClick={deleteService} className={css.blockAddServiceIcon}>
            {/* <img src={trash} alt="" className={css.iconBlockAdd} /> */}
            <span className={css.spanDelete}><IconClose/></span>
          </div>
          : null
        }
      </div>
    </div>
  );
}

const FieldCheckboxItemsComponent = props => {
  const {
    rootClassName,
    className,
    inputRootClass,
    id,
    label,
    placeholder,
    meta,
    form,
    intl,
    input,
    optional,
    twoColums,
    ...rest
  } = props;

  const [addList, setAddList] = useState(false)
  const [value, setValue] = useState('')
  const [error, setError] = useState(false)
  const [initialValues, setInitialValues] = useState(null);

  const inputHandle = e => {
    setError(false)
    setValue(e.target.value)
  }
  const saveNewService = fields => {
    const title = value.trim();
    
    if (title) {
      let val = fields?.value?.map(el => el.title)
      if (!val.includes(title)) {
        fields.update(fields.value.length + 1, {
          title: title,
          titlePl: title,
          selected: ['selected'],
          other: true
        })
        setError(false)
        setValue('');
        setAddList(false);
      } else setError(true)
    }
  }

  const delNewService = () => {
    setError(false)
    setValue('')
    setAddList(false)
  }
  const addNewService = () => {
    if (!addList) setAddList(true)
  }
  const createInitialValues = value => {
    const values = value.map(({ selected, title, titlePl, other }) => {
      return (
        {
          selected: selected ? selected : [],
          title: title,
          titlePl: titlePl,
          other: other
        }
      )
    }, []
    )

    return { [fieldsArrayName]: values }
  }

  const createStructure = values => {
    const optionValues = values[fieldsArrayName];
    if (!optionValues || !optionValues.length) return []

    const value = optionValues.map(({ selected, title, titlePl, other }) => {

      return (
        {
          selected: selected,
          title: title,
          titlePl: titlePl,
          other: other
        }
      )
    }
    )

    if (value.length) {
      form.change(input.name, value);
    }
  }

  const handleChange = ({ values }) => createStructure(values);

  useEffect(() => {
    if (input && input.value && !initialValues) {
      setInitialValues(createInitialValues(input.value))
    }
  }, [input])

  const { value: defaultValue, ...inputWithoutValue } = input;

  const inputHiddenProps = {
    ...inputWithoutValue,
    ...rest,
  };

  const classes = classNames(rootClassName || css.root, className);

  const errorMessageAddService = error ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingFeaturesForm.duplicateFailed" />
    </p>
  ) : null;

  return (
    <div className={classes}>

      <FinalForm
        onSubmit={() => {
        }}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ form, values }) => {
          return (
            <div className={css.wrapper}>
              <FormSpy onChange={values => handleChange(values)} />
              <FieldArray name={'options'}>
                {({ fields }) => (
                  <>
                    <div className={css.servicesHolder}>
                      {fields.map((name, i) => (
                        <FieldRow
                          key={name}
                          fields={fields}
                          index={i}
                          name={name}
                          intl={intl}
                          twoColums={twoColums}
                        />
                      ))}
                    </div>

                    {/* <p onClick={addNewService} className={css.buttonAddService}>
                      <FormattedMessage id="EditListingPricingForm.addCharge" />
                    </p> */}

                    {label &&
                      <label>
                        {label}
                        {/* {optional && (
                          <span className={css.optionalLabel}>
                            <FormattedMessage id="EditListingPricingForm.extraChargeOptional" />
                          </span>
                        )} */}
                      </label>}
                    <div className={css.wrapperInputBlock}>
                      <input
                        className={css.inputNewService}
                        type="text"
                        value={value}
                        onChange={inputHandle}
                        placeholder={placeholder || null}
                      />
                      <div onClick={() => saveNewService(fields)} className={css.blockAddServiceIcon}>
                        <span className={css.spanAdd}><IconPlus /></span>
                      </div>
                    </div>
                    {errorMessageAddService}
                  </>
                )}
              </FieldArray>
            </div>
          );
        }}
      />
      <input {...inputHiddenProps} />
    </div>
  );
}

FieldCheckboxItemsComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  id: null,
  label: null,
};

FieldCheckboxItemsComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
  }).isRequired,
  meta: object.isRequired,
  form: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const FieldCheckboxItems = props => {
  return <Field component={FieldCheckboxItemsComponent} {...props} type="hidden" />;
}

export default compose(injectIntl)(FieldCheckboxItems);
