import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';

import config from '../../config';
// import IconLogo from './IconLogo';
import LogoImage from './sleepRentDo-logo.svg';
import LogoImageWhite from './sleepRentDo-logo-white.png';
// import css from './Logo.module.css';

const Logo = props => {
  const { className, format, ...rest } = props;
  // const mobileClasses = classNames(css.logoMobile, className);

  if (format === 'white') {
    return <img className={className} src={LogoImageWhite} alt={config.siteTitle} {...rest} />;
  }

  // return <IconLogo className={mobileClasses} {...rest} />;
  return <img className={className} src={LogoImage} alt={config.siteTitle} {...rest} />;
};

const { string } = PropTypes;

Logo.defaultProps = {
  className: null,
};

Logo.propTypes = {
  className: string,
};

export default Logo;
