import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink, ExternalLink } from '../../components';

import image1 from './images/image1.jpg';
import image2 from './images/image2.jpg';
import image3 from './images/image3.jpg';
import image4 from './images/image4.jpg';

import css from './SectionOurSuggestion.module.css';

const SectionOurSuggestion = props => {

  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>

      <h2 className={css.title}>
        <FormattedMessage id="SectionOurSuggestion.title" />
      </h2>

      <div className={css.suggestions}>



        <NamedLink
          className={css.suggestionsItem}
          name="SearchPage"
          to={{ search: 'pub_mainCategory=rent' }}
        >
          <h3 className={css.suggestionsTitle}>
            <FormattedMessage id="SectionOurSuggestion.rent" />
          </h3>
          <img className={css.suggestionsImg} src={image1} alt="Rent image" />
        </NamedLink>

        <div className={css.suggestionsItem}>
          <NamedLink
            className={css.suggestionsItemSmall}
            name="SearchPage"
            to={{ search: 'pub_mainCategory=sleep' }}
          >
            <h3 className={css.suggestionsTitle}>
              <FormattedMessage id="SectionOurSuggestion.sleep" />
            </h3>
            <img className={css.suggestionsImg} src={image2} alt="Sleep image" />

          </NamedLink>

          <ExternalLink className={css.suggestionsItemSmall} href="https://www.facebook.com/profile.php?id=100088453146086">
            <h3 className={css.suggestionsTitle}>
              <FormattedMessage id="SectionOurSuggestion.facebook" />
            </h3>
            <img className={css.suggestionsImg} src={image3} alt="Facebook image" />
          </ExternalLink>
        </div>

        <NamedLink
          className={css.suggestionsItem}
          name="SearchPage"
          to={{ search: 'pub_mainCategory=activities' }}
        >
          <h3 className={css.suggestionsTitle}>
            <FormattedMessage id="SectionOurSuggestion.activities" />
          </h3>
          <img className={css.suggestionsImg} src={image4} alt="Activities image" />
        </NamedLink>

      </div>
    </div>
  );
};

SectionOurSuggestion.defaultProps = { rootClassName: null, className: null };

SectionOurSuggestion.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionOurSuggestion;
