import React from 'react';
import classNames from 'classnames';
import { Menu, MenuLabel, MenuContent, MenuItem, IconGlobe } from '../../components';
import { languagesHelper as language } from '../../helpers/languages';
import config from '../../config';

import css from './MultiLanguageSelect.module.css';

const MultiLanguageSelect = () => {

  let currentLocale = language.get();

  const changeLanguage = (locale) => {
    if (currentLocale === locale) {
      return;
    }
    language.set(locale);
  };

  return (
    <Menu className={classNames(css.root)}>
      <MenuLabel className={css.languageButton}>
        <div className={css.languageOption}>
          {/* <IconGlobe className={css.flagImg} /> */}
          <span>{currentLocale}</span>
          <span className={css.arrowDown}></span>
        </div>
      </MenuLabel>
      <MenuContent className={css.languagesOptions} useDefaultStyles={false}>
        <MenuItem key="EN">
          <div className={css.languageOption} onClick={() => changeLanguage('en')}>
            <span>EN</span>
          </div>
        </MenuItem>
        <MenuItem key="PL">
          <div className={css.languageOption} onClick={() => changeLanguage('pl')}>
            <span>PL</span>
          </div>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

export default MultiLanguageSelect;
