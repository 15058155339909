import React from 'react';
import { bool } from 'prop-types';
import SelectSingleFilterPlainWithIcons from './SelectSingleFilterPlainWithIcons';
import SelectSingleFilterPopupCategory from './SelectSingleFilterPopupCategory';

const SelectSingleFilterCategory = props => {
  const { showAsPopup, ...rest } = props;
  // return showAsPopup ? (
  //   <SelectSingleFilterPopupWithIcons {...rest} />
  // ) : (
  //   <SelectSingleFilterPlainWithIcons {...rest} />
  // );

  return (
    <SelectSingleFilterPopupCategory {...rest} />
  )
};

SelectSingleFilterCategory.defaultProps = {
  showAsPopup: false,
};

SelectSingleFilterCategory.propTypes = {
  showAsPopup: bool,
};

export default SelectSingleFilterCategory;
