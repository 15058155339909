import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSliderArrow.module.css';


const IconSliderArrow = props => {
  const { className, rootClassName, direction, onClick } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (direction === 'next') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="70"
        height="60"
        fill="none"
        viewBox="0 0 70 60"
        onClick={onClick}
      >
        <path
          fill="#fff"
          d="M0 20C0 8.954 8.954 0 20 0h50v60H20C8.954 60 0 51.046 0 40V20z"
        ></path>
        <path
          fill="#fff"
          d="M0 20C0 8.954 8.954 0 20 0h50v60H20C8.954 60 0 51.046 0 40V20z"
        ></path>
        <path
          fill="#2F4E1E"
          fillRule="evenodd"
          d="M21 30a2 2 0 002 2h23.17l-8.588 8.582a2.002 2.002 0 102.832 2.832l11.998-11.998a2 2 0 000-2.832L40.415 16.586a2.002 2.002 0 00-2.832 2.832L46.17 28H23A2 2 0 0021 30z"
          clipRule="evenodd"
          className={css.arrow}
        ></path>
      </svg>
    );
  } else {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="70"
        height="60"
        fill="none"
        viewBox="0 0 70 60"
        onClick={onClick}
      >
        <path
          fill="#fff"
          d="M70 20C70 8.954 61.046 0 50 0H0v60h50c11.046 0 20-8.954 20-20V20z"
        ></path>
        <path
          fill="#fff"
          d="M70 20C70 8.954 61.046 0 50 0H0v60h50c11.046 0 20-8.954 20-20V20z"
        ></path>
        <path
          fill="#2F4E1E"
          fillRule="evenodd"
          d="M49 30a2 2 0 01-2 2H23.83l8.588 8.582a2.002 2.002 0 11-2.832 2.832L17.588 31.416a2 2 0 010-2.832l11.998-11.998a2.002 2.002 0 012.832 2.832L23.83 28H47A2 2 0 0149 30z"
          clipRule="evenodd"
          className={css.arrow}
        ></path>
      </svg>
    );
  }

};

const { string } = PropTypes;

IconSliderArrow.defaultProps = {
  className: null,
  rootClassName: null,
};

IconSliderArrow.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconSliderArrow;
