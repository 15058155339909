import React, { Component } from 'react';
import PropTypes, { shape } from 'prop-types';
import { withRouter } from 'react-router-dom'
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, LocationAutocompleteInput } from '../../components';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import css from './TopbarSearchForm.module.css';
import { compose } from 'redux';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const identity = v => v;

class SearchField extends Component {
  constructor(props) {
    super(props)
  
    this.onChange = this.onChange.bind(this);
    this.clearLocation = this.clearLocation.bind(this);
    this.searchInput = null;
  }
  

  onChange(location) {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  clearLocation() {
    const {form, location, history} = this.props;
    const searchValues = [
      'address',
      'bounds',
      'pub_minLat',
      'pub_maxLat',
      'pub_minLong',
      'pub_maxLong'
    ];

    const searchParams = {
      bounds: config.maps.search.browseBounds,
      // mapSearch: true
    };

    location.search
      .slice(1)
      .split('&')
      .filter(item => item && !searchValues.includes(item.split('=')[0]))
      .map(item => {
        if (typeof window !== 'undefined') {
          const paramWithValue = window.decodeURI(item).split('=');
          searchParams[paramWithValue[0]] = paramWithValue[1].split('%2C').join(',');
        }
      });

    // this.setState({initialValues: {location: null}});
    form.reset({location: null})
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  render(){
    const { rootClassName, className, desktopInputRoot, intl, isMobile } = this.props;
    const classes = classNames(rootClassName, className);
    const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

    // Allow form submit only when the place has changed
    const preventFormSubmit = e => e.preventDefault();

    return (
      <Form className={classes} onSubmit={preventFormSubmit}>
        <Field
          name="location"
          format={identity}
          render={({ input, meta }) => {
            const { onChange, ...restInput } = input;
            // Merge the standard onChange function with custom behaviur. A better solution would
            // be to use the FormSpy component from Final Form and pass this.onChange to the
            // onChange prop but that breaks due to insufficient subscription handling.
            // See: https://github.com/final-form/react-final-form/issues/159
            const searchOnChange = value => {
              onChange(value);
              this.onChange(value);
            };

            const searchInput = { ...restInput, onChange: (v) => searchOnChange(v)};
            return (
              <>
                {/* <button type="button" className={css.clearButton} onClick={this.clearLocation}>
                  <FormattedMessage id={'FilterPlain.clear'} />
                </button> */}
                <LocationAutocompleteInput
                  className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                  // iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                  inputClassName={css.input}
                  predictionsClassName={css.predictions}
                  predictionsItemClassName={css.predictionsItem}
                  // predictionsAttributionClassName={
                  //   isMobile ? css.mobilePredictionsAttribution : null
                  // }
                  placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                  closeOnBlur={!isMobile}
                  inputRef={node => {
                    this.searchInput = node;
                  }}
                  input={searchInput}
                  meta={meta}
                />
              </>
            );
          }}
        />
      </Form>
    );
  }
}


class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: {}
    }
  }

  componentDidMount(){
    this.setState({initialValues: this.props.initialValues || {}})
  }

  render() {
    const { initialValues } = this.state;
    const { onSubmit, history } = this.props;
    return (
      <FinalForm
        {...this.props}
        initialValues={initialValues}
        render={formRenderProps => {
          const { rootClassName, className, desktopInputRoot, intl, isMobile, form, location } = formRenderProps;

          return (
            <SearchField
              rootClassName={rootClassName}
              className={className}
              desktopInputRoot={desktopInputRoot}
              intl={intl}
              isMobile={isMobile}
              form={form}
              location={location}
              onSubmit={onSubmit}
              history={history}
            />
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
};

const TopbarSearchForm = compose(
  withRouter,
  injectIntl
)(TopbarSearchFormComponent);

export default TopbarSearchForm;
