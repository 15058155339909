import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconGlobe.module.css';

const IconGlobe = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes} 
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        cx="12"
        cy="12"
        r="11.1"
        stroke="#2F4E1E"
        strokeWidth="1.5"
      ></circle>
      <path
        stroke="#2F4E1E"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M11.357.9C8.73.9 6.6 5.87 6.6 12s2.13 11.1 4.757 11.1M12.6.9c2.627 0 4.757 4.97 4.757 11.1s-2.13 11.1-4.757 11.1"
      ></path>
      <path
        stroke="#2F4E1E"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M.9 12.643C.9 15.27 5.87 17.4 12 17.4s11.1-2.13 11.1-4.757M.9 11.4C.9 8.773 5.87 6.643 12 6.643s11.1 2.13 11.1 4.757"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconGlobe.defaultProps = {
  className: null,
  rootClassName: null,
};

IconGlobe.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconGlobe;
