import { defaultLocale } from '../intl';

export const LANGUAGE_KEY = 'wacampaLanguage';

function exist() {
  return typeof window !== 'undefined' && window.localStorage;
}

function set(locale) {
  if (!exist) {
    return;
  }
  window.localStorage.setItem(LANGUAGE_KEY, locale);
  reload();
}

function get() {
  return (exist() && window.localStorage.getItem(LANGUAGE_KEY)) || defaultLocale;
}

function reload() {
  if (typeof document === undefined) {
    return;
  }
  document.location.reload(true);
}


function labelsTranslator(labelKey, intl) {
  return intl.formatMessage({ id: `CustomLabel.${labelKey}` });
};

export const languagesHelper = {
  set,
  get,
  exist,
  labelsTranslator
};
