import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconGuests.module.css';

const IconGuests = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#2F4E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.16 10.87c-.1-.01-.22-.01-.33 0a4.42 4.42 0 01-4.27-4.43C4.56 3.99 6.54 2 9 2a4.435 4.435 0 01.16 8.87zM16.41 4c1.94 0 3.5 1.57 3.5 3.5 0 1.89-1.5 3.43-3.37 3.5a1.13 1.13 0 00-.26 0M4.16 14.56c-2.42 1.62-2.42 4.26 0 5.87 2.75 1.84 7.26 1.84 10.01 0 2.42-1.62 2.42-4.26 0-5.87-2.74-1.83-7.25-1.83-10.01 0zM18.34 20c.72-.15 1.4-.44 1.96-.87 1.56-1.17 1.56-3.1 0-4.27-.55-.42-1.22-.7-1.93-.86"
      ></path>
    </svg>
  );
};

IconGuests.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconGuests.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconGuests;
