import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingAdditionalServicesForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import config from '../../config';

import css from './EditListingAdditionalServicesPanel.module.css';

const EditListingAdditionalServicesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingAdditionalServicesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <>
      <FormattedMessage id="EditListingAdditionalServicesPanel.createListingTitle" />
      <FormattedMessage id="EditListingAdditionalServicesPanel.createListingTitleOptionalText" />
    </>
  );

  const [servicesDeleted, setServicesDeleted] = useState(false)

  const checkServicesStatus = () => {
    setServicesDeleted(true)
  }

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingAdditionalServicesForm
        className={css.form}
        initialValues={{
          additionalServices: publicData?.additionalServices || [],
        }}
        onSubmit={values => {
          const { additionalServices } = values;

          const updateValues = {publicData: { additionalServices: !servicesDeleted ? additionalServices : null }};
          
          onSubmit(updateValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        checkServicesStatus={checkServicesStatus}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingAdditionalServicesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingAdditionalServicesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAdditionalServicesPanel;
