import React from 'react';
import { bool } from 'prop-types';
import SelectRangeFilterPlain from './SelectRangeFilterPlain';
import SelectRangeFilterPopup from './SelectRangeFilterPopup';

const SelectRangeFilter = props => {
  const { showAsPopup, ...rest } = props;
  // return showAsPopup ? (
  //   <SelectRangeFilterPopup {...rest} />
  // ) : (
  //   <SelectRangeFilterPlain {...rest} />
  // );

  return (
    <SelectRangeFilterPopup {...rest} />
  )
};

SelectRangeFilter.defaultProps = {
  showAsPopup: false,
};

SelectRangeFilter.propTypes = {
  showAsPopup: bool,
};

export default SelectRangeFilter;
