import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCalendar.module.css';

const IconCalendar = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#2F4E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M8 2v3M16 2v3M3.5 9.09h17M21 8.5V17c0 3-1.5 5-5 5H8c-3.5 0-5-2-5-5V8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5z"
      ></path>
      <path
        stroke="#2F4E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.695 13.7h.009M15.695 16.7h.009M11.995 13.7h.01M11.995 16.7h.01M8.294 13.7h.01M8.294 16.7h.01"
      ></path>
    </svg>
  );
};

IconCalendar.defaultProps = { className: null, rootClassName: null };

const { string } = PropTypes;

IconCalendar.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCalendar;
