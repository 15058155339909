import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './BookingBreakdown.module.css';

const LineItemLongstayDiscountMaybe = ({
  listing,
  quantity,
  intl,
  durationDiscount,
}) => {
  const {
    discountDurationDays,
  } = listing.attributes.publicData?.discountOptions ?? {};
  const { currency } = listing.attributes.price;

  if (quantity && (!discountDurationDays || +quantity < +discountDurationDays)) {
    return null;
  }

  const formattedPrice = formatMoney(intl, new Money(durationDiscount, currency));

  return (
    <div className={css.lineItemTotal}>
      <div className={css.totalLabel}>
        <FormattedMessage id="BookingBreakdown.longstayDiscountLabel" />
      </div>
      <div className={css.totalPrice}>-{formattedPrice}</div>
    </div>
  )
}

LineItemLongstayDiscountMaybe.propTypes = {
  listing: propTypes.listing.isRequired,
};

export default LineItemLongstayDiscountMaybe;
