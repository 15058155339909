import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { FieldTextInput } from '../../components';

import css from './EditListingPricingForm.module.css';

const DiscountFields = ({ discountType, isDay }) => {
  return (
    <div className={css.discountHolder}>
      {discountType === "perPerson" && (
        <div className={css.discountItem}>
          <label className={css.discountItemLabel}><FormattedMessage id="EditListingPricingForm.kidsLabel" /></label>
          <FieldTextInput
            id="discountKids"
            name="discountKids"
            className={css.discountItemField}
            type="number"
            min={0}
            max={100}
          />
          <span className={css.lastText}>
            %
          </span>
        </div>
      )}
      {discountType === "perPerson" && (
        <div className={css.discountItem}>
          <label className={css.discountItemLabel}><FormattedMessage id="EditListingPricingForm.groupLabel" /></label>
          <FieldTextInput
            id="discountGroup"
            name="discountGroup"
            className={css.discountItemField}
            type="number"
            min={0}
            max={100}
          />
          <span className={css.discountFromText}>
            <FormattedMessage id="EditListingPricingForm.from" />
          </span>
          <FieldTextInput
            id="discountGroupPeople"
            name="discountGroupPeople"
            className={css.discountItemField}
            type="number"
            min={0}
            max={100}
          />
          <span className={css.lastText}>
            <FormattedMessage id="EditListingPricingForm.people" />
          </span>
        </div>
      )}
      <div className={css.discountItem}>
        <label className={css.discountItemLabel}><FormattedMessage id="EditListingPricingForm.durationLabel" /></label>
        <FieldTextInput
          id="discountDuration"
          name="discountDuration"
          className={css.discountItemField}
          type="number"
          min={0}
          max={100}
        />
        <span className={css.discountFromText}>
          <FormattedMessage id="EditListingPricingForm.from"/>
        </span>
        <FieldTextInput
          id="discountDurationDays"
          name="discountDurationDays"
          className={css.discountItemField}
          type="number"
          min={0}
          max={100}
        />
        <span className={css.lastText}>
          <FormattedMessage id={`EditListingPricingForm.${isDay ? 'days' : 'nights'}`} />
        </span>
      </div>
      <div className={css.discountItem}>
        <label className={css.discountItemLabel}><FormattedMessage id="EditListingPricingForm.multipleItemsLabel" /></label>
        <FieldTextInput
          id="discountMultiple"
          name="discountMultiple"
          className={css.discountItemField}
          type="number"
          min={0}
          max={100}
        />
        <span className={css.discountFromText}>
          <FormattedMessage id="EditListingPricingForm.from" />
        </span>
        <FieldTextInput
          id="discountMultipleItems"
          name="discountMultipleItems"
          className={css.discountItemField}
          type="number"
          min={0}
          max={100}
        />
        <span className={css.lastText}>
          <FormattedMessage id="EditListingPricingForm.items" />
        </span>
      </div>
    </div>
  );
};

export default DiscountFields;
