/*
 * Renders a set of options with selected and non-selected values.
 *
 * The corresponding component when selecting the values is
 * FieldCheckboxGroup.
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import includes from 'lodash/includes';
import { IconAmenities } from '../../components';
import { languagesHelper as language } from '../../helpers/languages';
import css from './PropertyGroup.module.css';

const checkSelected = (options, selectedOptions) => {
  return options.map(option => ({
    key: option.key,
    label: option.label,
    isSelected: includes(selectedOptions, option.key),
  }));
};

const IconCheck = props => {
  const isVisible = props.isVisible;
  const classes = isVisible ? css.checkIcon : classNames(css.checkIcon, css.hidden);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      fill="none"
      viewBox="0 0 10 8"
    >
      <path
        fill="#65BB47"
        d="M0 4.298L3.5 8l6.336-6.631L8.568 0 3.5 5.25 1.256 2.923 0 4.298z"
      ></path>
    </svg>
  );
};

const Item = props => {
  const { label, isSelected, iconKey, showIcon } = props;
  // const labelClass = isSelected ? css.selectedLabel : css.notSelectedLabel;

  if (!isSelected) {
    return null
  }

  return (
    <li className={css.item}>
      <span className={css.iconWrapper}>
        {showIcon && iconKey ? <IconAmenities iconKey={iconKey}/> : <IconCheck isVisible={isSelected} />}
      </span>
      <div className={css.labelWrapper}>
        <span className={css.selectedLabel}>{label}</span>
      </div>
    </li>
  );
};

const PropertyGroup = props => {
  const {
    rootClassName,
    className,
    id,
    options,
    selectedOptions,
    twoColumns,
    label,
    showMoreBtn,
    showIcon,
    withTranslations,
    intl
  } = props;
  const classes = classNames(className || css.root);
  const listClasses = twoColumns ? classNames(classes, css.twoColumns) : classes;

  const OPTIONS_LIMIT = 3;


  const [showAllOptions, setShowAllOptions] = useState(false)

  const checked = checkSelected(options, selectedOptions);
  let anyOneSelected = checked.filter(c => c.isSelected === true)

  if (!anyOneSelected?.length) {
    return null;
  }

  const moreThenLimit = anyOneSelected?.length > OPTIONS_LIMIT

  if (!showAllOptions) {
    anyOneSelected = anyOneSelected.slice(0, OPTIONS_LIMIT)
  } else {
    anyOneSelected = anyOneSelected
  }

  const showAll = () => {
    setShowAllOptions(true)
  }

  return (
    <div className={rootClassName}>
      <h3 className={css.listLabel}>
        {label}
      </h3>
      <ul className={listClasses}>
        {anyOneSelected.map(option => (
          <Item
            key={`${id}.${option.key}`}
            iconKey={option.key}
            label={withTranslations ? language.labelsTranslator(option.key, intl) : option.label}
            isSelected={option.isSelected}
            showIcon={showIcon}
          />
        ))}
      </ul>

      {showMoreBtn && !showAllOptions && moreThenLimit && (
        <div onClick={() => showAll()} className={css.seeMore}>
          <FormattedMessage id="ContentWithShowMore.seeMore" />
        </div>
      )}
    </div>
  );
};

PropertyGroup.defaultProps = {
  rootClassName: null,
  className: null,
  selectedOptions: [],
  twoColumns: false,
};

const { arrayOf, bool, node, shape, string } = PropTypes;

PropertyGroup.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ),
  selectedOptions: arrayOf(string),
  twoColumns: bool,
};

export default PropertyGroup;
