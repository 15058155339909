import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, array, bool, func, node, oneOf, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button } from '../../components';
import { BookingDatesForm } from '../../forms';
import Discount from './Discounts';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './BookingPanel.module.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = ({
  rootClassName,
  className,
  // titleClassName,
  listing,
  isOwnListing,
  unitType,
  onSubmit,
  // title,
  // subTitle,
  // authorDisplayName,
  onManageDisableScrolling,
  timeSlots,
  fetchTimeSlotsError,
  history,
  location,
  intl,
  onFetchTransactionLineItems,
  lineItems,
  fetchLineItemsInProgress,
  fetchLineItemsError,
  additionServices,
  totalPriceLineItems,
}) => {
  const userAbbreviatedName = listing?.author?.attributes?.profile?.abbreviatedName ?? null;
  const price = listing.attributes.price;
  const { priceWeekendByPerson, priceWeekendPerUnit } = listing.attributes.publicData.optionsPrice;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed;
  // const showClosedListingHelpText = listing.id && isClosed;
  const lowestPrice = priceWeekendByPerson && priceWeekendByPerson < price.amount
    ? priceWeekendByPerson
    : priceWeekendPerUnit && priceWeekendPerUnit < price.amount
    ? priceWeekendPerUnit
    : price.amount;

  const formattedPriceAmount = (lowestPrice/100).toFixed(2);

  const { formattedPrice, priceTitle } = priceData(new Money(lowestPrice, price.currency), intl);
  const isBook = !!parse(location.search).book;

  const from = intl.formatMessage({ id: 'BookingPanel.from' })

  // const subTitleText = !!subTitle
  //   ? subTitle
  //   : showClosedListingHelpText
  //   ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
  //   : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  // const titleClasses = classNames(titleClassName || css.bookingTitle);

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        className={css.bookingWidget}
      >
        <div className={css.bookingHeading}>
          {userAbbreviatedName ? (
            <div className={css.userAbbreviatedNameWrapper}>
              <span className={css.userAbbreviatedName}>{userAbbreviatedName}</span>
            </div>
          ) : null}
          <div>
            <div className={css.priceContainerDesctop}>
              <span className={css.priceValue} title={priceTitle}>
                <span className={css.from}>{from}</span>
                {formattedPriceAmount}{" "}
                {price.currency}
              </span>
              <span className={css.perUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </span>
            </div>
            <Discount intl={intl} listing={listing} />
          </div>
        </div>
        {showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            listing={listing}
            additionServices={additionServices}
            totalPriceLineItems={totalPriceLineItems}
          />
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>

        {showBookingDatesForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  // unitType: config.bookingUnitType,
  unitType: oneOf([propTypes.bookingUnitTypeDay, propTypes.bookingUnitTypeNight]).isRequired,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  // unitType: propTypes.bookingUnitType,
  unitType: oneOf([propTypes.bookingUnitTypeDay, propTypes.bookingUnitTypeNight]).isRequired,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
