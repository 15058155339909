import React from 'react';
import { number, array } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

import css from './BookingBreakdown.module.css';

const LineItemAdditionalServicesMaybe = ({ listing, services, bookingDays, intl }) => {
  const { currency } = listing?.attributes?.price ?? {};

  return services.length ? (
    <div className={css.lineItemServices}>
      <div className={css.totalLabel}>
        <FormattedMessage id="BookingBreakdown.additionalServicesLabel" />
      </div>
      <ul>
        {services.map(({ title, pricePerDay, pricePerItem }, idx) => {
          const amount = pricePerDay ? +pricePerDay * bookingDays : +pricePerItem;
          const formattedPrice = formatMoney(intl, new Money(amount * 100, currency));
          return (
            <li key={title + idx} className={css.additionalServicesCuntainer}>
              <div className={css.totalLabel}>{title}</div>
              <div className={css.totalPrice}>{formattedPrice}</div>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};

LineItemAdditionalServicesMaybe.propTypes = {
  listing: propTypes.listing.isRequired,
  bookingDays: number,
  services: array,
};

export default LineItemAdditionalServicesMaybe;
